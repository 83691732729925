/* @flow */

import * as React from 'react';
import Picto, { type PictoPropType } from './Picto';

/* eslint-disable react/jsx-props-no-spreading, react/no-multi-comp */

// $FlowFixMe: don't know how to annotate this export
const PictoAndroid = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M147.79,36.85a52.68,52.68,0,0,0-20.54-18.54l8.48-15.63c.6-1.19.38-2-.67-2.46s-1.71-.22-2,.67l-8.93,15.64a58.59,58.59,0,0,0-23.9-4.92,61.78,61.78,0,0,0-24.34,4.92L67,.89c-.3-.89-1-1.11-2-.67s-1.27,1.27-.67,2.46l8.48,15.63A54.41,54.41,0,0,0,52,36.85a45.7,45.7,0,0,0-7.81,25.69H155.39A46.6,46.6,0,0,0,147.79,36.85ZM78.11,41.09a4.86,4.86,0,0,1-6.7,0A4.28,4.28,0,0,1,70.07,38a5.08,5.08,0,0,1,1.34-3.35,4.36,4.36,0,0,1,6.7,0A5,5,0,0,1,79.45,38,4.24,4.24,0,0,1,78.11,41.09Zm50.48,0a4.28,4.28,0,0,1-3.13,1.34,5,5,0,0,1-3.35-1.34A4,4,0,0,1,120.55,38a5.42,5.42,0,0,1,4.91-4.92,4,4,0,0,1,3.13,1.57A5.08,5.08,0,0,1,129.93,38,4.28,4.28,0,0,1,128.59,41.09Z' />
    <path d='M36.35,68.34a12.62,12.62,0,0,0-8.94-3.57,13.4,13.4,0,0,0-9.15,3.57,11,11,0,0,0-4,8.49v51.81a11.47,11.47,0,0,0,4,8.94,13.67,13.67,0,0,0,9.38,3.57,12.22,12.22,0,0,0,12.51-12.51V76.83A11.27,11.27,0,0,0,36.35,68.34Z' />
    <path d='M44.61,67v80a12.64,12.64,0,0,0,4,9.6,13.25,13.25,0,0,0,9.38,3.8H67V187.6q0,8,6.25,11a13.28,13.28,0,0,0,12.28-.22q6-3.14,6-10.73V160.36h17V187.6q0,8,6,11a13.75,13.75,0,0,0,12.28,0q6.26-2.91,6.25-11V160.36H142a13.25,13.25,0,0,0,9.38-3.8,12.64,12.64,0,0,0,4-9.6V67Z' />
    <path d='M181.74,68.34a13.67,13.67,0,0,0-9.38-3.57,12.36,12.36,0,0,0-8.71,3.57,11.77,11.77,0,0,0-3.8,8.94v51.36a11.78,11.78,0,0,0,3.8,8.94,12.62,12.62,0,0,0,8.94,3.57,13.4,13.4,0,0,0,9.15-3.57,11.47,11.47,0,0,0,4-8.94V76.83A11,11,0,0,0,181.74,68.34Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoApple = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M137.05,0q-19.19,4.47-29,17.19T97.77,49.11a56.81,56.81,0,0,0,12.5-1.79q7.59-2.67,14.73-10.49A52,52,0,0,0,135.71,19Q139.28,8.93,137.05,0Z' />
    <path d='M165.4,131.7a47.55,47.55,0,0,1-8.7-24.56,42.07,42.07,0,0,1,8-27.68q4-6.24,13.4-13.84-8-9.81-16.52-14.73a42.45,42.45,0,0,0-21.43-5.35q-8.93,0-24.11,4.91c-6.84,2.08-11.61,3.12-14.28,3.12s-7.45-.89-14.29-2.68q-15.63-4.45-24.11-4.46-21.87,0-35.26,19.19Q16.07,83.49,16.07,108.48q0,17.86,8,39.51t19.64,35.94Q57.15,200,69.64,200q5.35,0,15.18-3.57Q96,192,102.68,192t18.08,3.8q11.38,3.8,17.63,3.79,13.85,0,28.13-19.64A114.71,114.71,0,0,0,183.93,146Q173.22,142.86,165.4,131.7Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoArrowDown = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => {
  <Picto {...delegated} ref={ref}>
    <path d='M92.23,158.47l-89-89a11,11,0,0,1,0-15.54L13.6,43.53a11,11,0,0,1,15.53,0L100,114.06l70.87-70.55a11,11,0,0,1,15.53,0l10.38,10.38a11,11,0,0,1,0,15.55l-89,89a11,11,0,0,1-15.54,0Z' />
  </Picto>;
});

// $FlowFixMe: don't know how to annotate this export
const PictoArrowLeft = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M42.53,92.23l89-89a11,11,0,0,1,15.55,0L157.47,13.6a11,11,0,0,1,0,15.53L87,100l70.54,70.87a11,11,0,0,1,0,15.53l-10.38,10.38a11,11,0,0,1-15.55,0l-89-89a11,11,0,0,1,0-15.54Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoArrowLeftWithShaft = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M4.18550142,89.6627044 C-1.39516714,95.243373 -1.39516714,104.306379 4.18550142,109.887047 L75.618059,181.319605 C81.1987276,186.900273 90.2617334,186.900273 95.8424019,181.319605 C101.42307,175.738936 101.42307,166.675931 95.8424019,161.095262 L48.7415592,114.039065 L185.713488,114.039065 C193.615715,114.039065 200,107.65478 200,99.7525532 C200,91.8503265 193.615715,85.4660416 185.713488,85.4660416 L48.7862046,85.4660416 L95.7977566,38.4098443 C101.378425,32.8291757 101.378425,23.76617 95.7977566,18.1855014 C90.217088,12.6048329 81.1540823,12.6048329 75.5734137,18.1855014 L4.14085607,89.618059 L4.18550142,89.6627044 Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoArrowRight = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M157.47,107.77l-89,89a11,11,0,0,1-15.54,0L42.53,186.4a11,11,0,0,1,0-15.53L113.06,100,42.51,29.13a11,11,0,0,1,0-15.53L52.91,3.22a11,11,0,0,1,15.55,0l89,89a11,11,0,0,1,0,15.54Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoArrowUp = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M107.77,43.53l89,89a11,11,0,0,1,0,15.55L186.4,158.47a11,11,0,0,1-15.53,0L100,88,29.13,158.49a11,11,0,0,1-15.53,0L3.22,148.09a11,11,0,0,1,0-15.55l89-89a11,11,0,0,1,15.54,0Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoBack = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M198.74,104.47c-4.55-26.55-29.33-48.29-55.61-48.4C106.92,55.91,85.72,56,49.51,56H40A142,142,0,0,1,53.87,44.64c5.28-4,6.49-9.55,3.12-14.27S47.59,25,42.22,29Q23.49,43,4.81,57c-6.9,5.21-6.88,12.16.05,17.39q18.53,14,37.13,27.86c5.45,4.08,11.27,3.67,14.84-1s2.36-10.41-3.06-14.52c-4.61-3.5-9.23-7-14.69-11.08,2.17-.13,3.29-.26,4.42-.26q14.28,0,28.58,0c27.51,0,40-.13,67.53,0,26.74.17,45.53,23.78,39.44,49.23a39.44,39.44,0,0,1-37.89,30.11c-19.17.2-23.34,0-42.52.1a19.32,19.32,0,0,0-6.3.87,9.47,9.47,0,0,0-5.91,10.31,9.68,9.68,0,0,0,10,8.37c20.49,0,26,0,46.45,0a46.77,46.77,0,0,0,8.49-1C183.64,167.09,204.32,137,198.74,104.47Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoBackToLive = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M48.68,95.75a3.09,3.09,0,0,1,3.12-3.29A3.17,3.17,0,0,1,55,95.86v.23c-.16,3.57-.33,15.35-.33,23.11,0,3.18.06,5.69.11,6.64.22,2.68,1.45,2.68,9,2.68,3.24,0,6.82-.05,7.87-.11H72a2.92,2.92,0,0,1,3,3c0,1.51-1.11,3.07-3.52,3.18-.72.06-4,.11-7.31.11s-6.53,0-7.26-.11c-5.14-.39-8.2-2.23-8.37-7.14,0-1.17,0-3,0-5,0-7.65.22-19.87.22-25.4V95.75Z' />
    <path d='M85.44,131.76a3.38,3.38,0,0,1-6.7,0v-.06c.11-6.69.28-14.18.28-21.37,0-5.31-.11-10.39-.28-14.91-.12-2.34,2.4-3,3.41-3,1.62,0,3.29.89,3.29,2.74v.11c-.11,4.63-.17,9.88-.17,15.29,0,7,.06,14.24.17,21.1Z' />
    <path d='M106.3,134.83c-2.07,0-4.18-1.45-5.25-4.07-2.79-7.1-11.66-32.55-11.66-35.11a3.26,3.26,0,0,1,3.35-3.36A3.33,3.33,0,0,1,96,94.92c1,4,6.81,23.39,9.16,30.31.22.67.44,1.06.72,1.06s.51-.33.78-1.23c1.56-4.69,7.26-23.22,9.72-30.53a3.09,3.09,0,0,1,3.07-2.24,3.42,3.42,0,0,1,3.57,3.18,3.89,3.89,0,0,1-.17,1.07c-3.29,9.71-9.71,27.46-11.83,34.27C110.15,133.55,108.25,134.83,106.3,134.83Z' />
    <path d='M140,98.05c-5.59,0-6.76.11-6.92,2.51-.11,1.34-.06,4.07-.11,7.31,0,1.11.05,1.11,6.92,1.11,2.56,0,5.25,0,6.25,0a2.81,2.81,0,0,1,3.13,3,3,3,0,0,1-3,3.18H146c-1.39-.11-3.52-.17-5.64-.17-6.92,0-7.48.06-7.48,1.23,0,8.32.06,9.6.17,10.55.28,2.57,1.17,2.68,9,2.68,3.3,0,6.87-.06,7.93-.11h.27a2.82,2.82,0,0,1,3,2.85c0,1.5-1.11,3-3.51,3.12-.73.06-4,.11-7.2.11s-6.43-.05-7.15-.11c-5.13-.39-8.32-2.23-8.54-7.14-.11-2.35-.11-6.48-.11-11,0-6.81,0-14.4.16-17.92.12-4.47,3-6.81,7.54-7,1-.06,4.19-.11,7.31-.11s6.25,0,7.09.11a3,3,0,0,1,3.13,3,3.08,3.08,0,0,1-3.3,3S143.78,98.05,140,98.05Z' />
    <path d='M186.64,90.14c-.55-2.37-3-4.15-4.1-4.81-1.24-.76-3.28-1-6.18-.35a9.06,9.06,0,0,0-4.21,3.59,8.94,8.94,0,0,0-.68,6.24c.52,2.26.93,4.42,1.32,7a93.47,93.47,0,0,1-.1,18.83,93.8,93.8,0,0,1-4.86,18.21,93.62,93.62,0,0,1-9.68,16.75c-2,2.41-4.11,4.78-6.38,7a89.22,89.22,0,0,1-7.62,6.81,93,93,0,0,1-16.84,9.5,93.23,93.23,0,0,1-18.23,4.69,92.73,92.73,0,0,1-18.83-.08,93.85,93.85,0,0,1-18.2-4.87,92.62,92.62,0,0,1-16.75-9.66,95.58,95.58,0,0,1-13.9-14,93.9,93.9,0,0,1-9.49-16.84A94.07,94.07,0,0,1,27.22,120a93.44,93.44,0,0,1,.09-18.83A92.62,92.62,0,0,1,32.17,83a93.19,93.19,0,0,1,9.67-16.75c2-2.44,4.14-4.82,6.38-7.07a90.74,90.74,0,0,1,7.62-6.8,92.87,92.87,0,0,1,16.85-9.51,91.46,91.46,0,0,1,11.45-3.42,11.06,11.06,0,0,1,9.17,2.09A10.7,10.7,0,0,1,97.37,50c0,3.57,0,7.13,0,10.7a2.72,2.72,0,0,0,4.27,2.23q14.16-9.72,28.27-19.51l7-4.86c1.42-1,2.82-2,4.24-3l2.26-1.62a2.74,2.74,0,0,0,0-4.48q-4.86-3.33-9.68-6.73L118.48,12.08Q110.1,6.26,101.7.48a2.73,2.73,0,0,0-4.28,2.24l0,9.31A10.83,10.83,0,0,1,88,22.73c-2.16.29-4.35.63-6.49,1.06C66.33,26.8,51.23,34.68,39,46c-.65.62-1.32,1.26-2,1.92a97.07,97.07,0,0,0-12,15,91.06,91.06,0,0,0-9,18.68A72,72,0,0,0,12.1,97.2,88.94,88.94,0,0,0,15,137.52a91.69,91.69,0,0,0,8.42,18.91,92.41,92.41,0,0,0,29.83,30.32A88.57,88.57,0,0,0,92,199.55a70.72,70.72,0,0,0,15.93,0A88.42,88.42,0,0,0,162.91,174l.86-.88a89.59,89.59,0,0,0,22.32-39.24A91.29,91.29,0,0,0,186.64,90.14Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoBigPlay = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M22.21,99c0-25.56-.26-51.13.12-76.69C22.5,10.5,25.76,3.45,37.6,0,44.75-2,50.61,1.16,56,4.85Q100.1,35,143.94,65.5c8.7,6,17.17,12.41,26,18.22,10.6,7,10.59,24.37-.18,31.59-17.11,11.47-33.87,23.46-50.8,35.18-21.21,14.69-42.37,29.46-63.7,44-11.83,8-23.94,6.16-29.94-4.84-2.1-3.86-2.92-8.85-3-13.34-.27-25.75-.13-51.51-.13-77.27Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoBurger = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M16.5,54.45C9.87,54.45.36,48.11.36,38.6c0-8.36,9.51-15.27,16.14-15.27H184.22c7.21,0,16.14,6.91,16.14,15.27,0,9.51-8.93,15.85-16.14,15.85ZM184.22,84.14c7.21,0,16.14,6.91,16.14,15.27,0,9.51-8.93,15.85-16.14,15.85H16.5C9.87,115.26.36,108.92.36,99.41c0-8.36,9.51-15.27,16.14-15.27Zm0,60.8c7.21,0,16.14,6.92,16.14,15.28,0,9.51-8.93,15.85-16.14,15.85H16.5c-6.63,0-16.14-6.34-16.14-15.85,0-8.36,9.51-15.28,16.14-15.28Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoBuy = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M73.62,111.13l2.09,11.11h93.42a7.59,7.59,0,0,1,6.25,3.13,8.37,8.37,0,0,1,1.73,6.94L175,141a19.36,19.36,0,0,1-8.33,36.81,18.43,18.43,0,0,1-13.55-5.56,19.89,19.89,0,0,1-5.9-13.72,17.58,17.58,0,0,1,5.9-14.06H80.22a18.26,18.26,0,0,1,5.91,13.89,18.59,18.59,0,0,1-6.08,14.07,19.07,19.07,0,0,1-14.41,5.38A19.7,19.7,0,0,1,47.23,159.4,18.33,18.33,0,0,1,49.66,149,21.08,21.08,0,0,1,57,141.34L32.64,22.23H8.33a8,8,0,0,1-5.9-2.43A8.06,8.06,0,0,1,0,13.89V8.33a8,8,0,0,1,2.43-5.9A8,8,0,0,1,8.33,0H44.1a7.61,7.61,0,0,1,5,1.91,8.77,8.77,0,0,1,3,4.69l3.13,15.63H191.7a7.92,7.92,0,0,1,6.6,3.12,7.55,7.55,0,0,1,1.39,6.95l-16.32,72.23a8.13,8.13,0,0,1-8,6.6Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoCart = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M73.62,111.13l2.09,11.11h93.42a7.59,7.59,0,0,1,6.25,3.13,8.37,8.37,0,0,1,1.73,6.94L175,141a19.36,19.36,0,0,1-8.33,36.81,18.43,18.43,0,0,1-13.55-5.56,19.89,19.89,0,0,1-5.9-13.72,17.58,17.58,0,0,1,5.9-14.06H80.22a18.26,18.26,0,0,1,5.91,13.89,18.59,18.59,0,0,1-6.08,14.07,19.07,19.07,0,0,1-14.41,5.38A19.7,19.7,0,0,1,47.23,159.4,18.33,18.33,0,0,1,49.66,149,21.08,21.08,0,0,1,57,141.34L32.64,22.23H8.33a8,8,0,0,1-5.9-2.43A8.06,8.06,0,0,1,0,13.89V8.33a8,8,0,0,1,2.43-5.9A8,8,0,0,1,8.33,0H44.1a7.61,7.61,0,0,1,5,1.91,8.77,8.77,0,0,1,3,4.69l3.13,15.63H191.7a7.92,7.92,0,0,1,6.6,3.12,7.55,7.55,0,0,1,1.39,6.95l-16.32,72.23a8.13,8.13,0,0,1-8,6.6Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoCheck = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M67.93,171.64l-65-65a10,10,0,0,1,0-14.14L17.07,78.36a10,10,0,0,1,14.14,0L75,122.14l93.79-93.78a10,10,0,0,1,14.14,0L197.07,42.5a10,10,0,0,1,0,14.14l-115,115a10,10,0,0,1-14.14,0Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoClock = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M102.3,1.26a98.87,98.87,0,0,0-101,98.79c-.25,54.68,44.26,99.1,98.88,99.1,54.11.62,99.14-44.27,99.12-98.56A99,99,0,0,0,102.3,1.26ZM111.78,109a7.56,7.56,0,0,1-7.57,7.56H72.94a7.57,7.57,0,0,1,0-15.13H96.65v-52a7.57,7.57,0,1,1,15.13,0Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoClockEmpty = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M102.07.06A99.87,99.87,0,0,0,0,99.85C-.25,155.08,44.71,200,99.88,200,154.54,200.57,200,155.23,200,100.4A100,100,0,0,0,102.07.06Zm-2,182.33c-45.66,0-82.84-36.9-82.45-82.57s36.7-82.79,84-82.17c44.67.59,81,36.9,80.86,83.18C182.28,145.86,145.47,182.39,100,182.39Z' />
    <path d='M104,116.51H72.42a7.65,7.65,0,0,1,0-15.29H96.36V48.71a7.65,7.65,0,0,1,15.29,0v60.15A7.65,7.65,0,0,1,104,116.51Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoCog = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M197.82,80.32a9.79,9.79,0,0,0-5.94-3.59l-.13,0-19.21-2.94c-.79-2.13-1.71-4.32-2.75-6.54,1.35-1.76,2.94-3.76,4.52-5.74l.05-.07c3-3.92,5.12-6.62,6.4-8.41a10.38,10.38,0,0,0,2-6,9.17,9.17,0,0,0-2.07-6.16C177.38,36.2,170.36,29,159.83,19.29l-.1-.09a9.93,9.93,0,0,0-6.7-2.59,9.48,9.48,0,0,0-6.51,2.4L132,30c-1.7-.77-3.53-1.51-5.49-2.24L123.56,8.57A9.75,9.75,0,0,0,113.66,0H86.34a9.64,9.64,0,0,0-9.75,7.53c-1,3.65-2,9.63-3.3,20.24-1.84.69-3.67,1.45-5.47,2.29L53.55,19A10.41,10.41,0,0,0,47,16.61c-3.66,0-7.58,2.63-15.54,10.43C25.19,33.16,21,37.71,18.73,41l-.09.12a10.56,10.56,0,0,0-2,5.89,10,10,0,0,0,2.54,6.53c4,4.82,7.68,9.48,10.91,13.88a63.67,63.67,0,0,0-2.52,5.9L8,76.22l-.25,0A9.49,9.49,0,0,0,2,80,10.36,10.36,0,0,0,0,86.1v27.31a10.2,10.2,0,0,0,2.18,6.27,9.74,9.74,0,0,0,5.94,3.59l.15,0,19.1,2.82a62.64,62.64,0,0,0,2.8,6.71c-1,1.28-2.11,2.76-3.41,4.43l-1.09,1.42c-3.05,3.81-5.15,6.53-6.44,8.33a10.36,10.36,0,0,0-2,6,10,10,0,0,0,1.89,6l0,0c3.45,4.77,10.32,11.79,21,21.45A9.8,9.8,0,0,0,47,183.39a10,10,0,0,0,6.52-2.31L68,170c1.7.77,3.52,1.51,5.48,2.23l2.92,19.16a9.75,9.75,0,0,0,9.9,8.57h27.32a9.63,9.63,0,0,0,9.75-7.53c1-3.66,2-9.64,3.3-20.24,1.84-.68,3.66-1.45,5.46-2.28l14.26,11.16.34.25a11.34,11.34,0,0,0,6.26,2c3.66,0,7.55-2.6,15.42-10.31,6-5.91,10.18-10.42,12.63-13.77a9.86,9.86,0,0,0-.15-12.83c-4.91-6-8.49-10.59-11-14q1.23-2.66,2.42-5.69l19.56-3,.19,0a9.62,9.62,0,0,0,5.85-3.71A10.27,10.27,0,0,0,200,113.9V86.59A10.14,10.14,0,0,0,197.82,80.32Zm-98,63.61a43.66,43.66,0,1,1,43.66-43.66A43.66,43.66,0,0,1,99.81,143.93Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoCompress = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M194.64,71.43H139.29a10.7,10.7,0,0,1-10.72-10.72V5.36A5.37,5.37,0,0,1,133.93,0h17.86a5.37,5.37,0,0,1,5.35,5.36v37.5h37.5A5.37,5.37,0,0,1,200,48.21V66.07A5.37,5.37,0,0,1,194.64,71.43ZM71.43,60.71V5.36A5.37,5.37,0,0,0,66.07,0H48.21a5.37,5.37,0,0,0-5.35,5.36v37.5H5.36A5.37,5.37,0,0,0,0,48.21V66.07a5.37,5.37,0,0,0,5.36,5.36H60.71A10.7,10.7,0,0,0,71.43,60.71Zm0,133.93V139.29a10.7,10.7,0,0,0-10.72-10.72H5.36A5.37,5.37,0,0,0,0,133.93v17.86a5.37,5.37,0,0,0,5.36,5.35h37.5v37.5A5.37,5.37,0,0,0,48.21,200H66.07A5.37,5.37,0,0,0,71.43,194.64Zm85.71,0v-37.5h37.5a5.37,5.37,0,0,0,5.36-5.35V133.93a5.37,5.37,0,0,0-5.36-5.36H139.29a10.7,10.7,0,0,0-10.72,10.72v55.35a5.37,5.37,0,0,0,5.36,5.36h17.86A5.37,5.37,0,0,0,157.14,194.64Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoDot = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <circle cx='100' cy='100' r='70' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoDownload = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path
      d='M 5.256094 15.999844 C 3.889687 15.029063 3 13.442344 3 11.649375 C 3 9.200156 4.8 6.9375 7.5 6.500156 C 8.347031 4.486406 10.351406 3 12.689531 3 C 15.683906 3 18.131719 5.322656 18.3 8.25 C 19.889531 8.944687 21 10.650469 21 12.496875 C 21 14.058281 20.205938 15.434062 18.999844 16.241719 M 12 21 L 12 11.000156 M 12 21 L 9 18 M 12 21 L 15 18 '
      style={{ fill: 'none', stroke: '#fff', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '4', strokeWidth: '2' }}
      transform='matrix(8.4,0,0,8.4,0,0)'
    />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoExpand = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M0,66.07V10.71A10.69,10.69,0,0,1,10.71,0H66.07a5.37,5.37,0,0,1,5.36,5.36V23.21a5.37,5.37,0,0,1-5.36,5.36H28.57v37.5a5.37,5.37,0,0,1-5.36,5.36H5.36A5.37,5.37,0,0,1,0,66.07ZM128.57,5.36V23.21a5.37,5.37,0,0,0,5.36,5.36h37.5v37.5a5.37,5.37,0,0,0,5.36,5.36h17.85A5.37,5.37,0,0,0,200,66.07V10.71A10.69,10.69,0,0,0,189.29,0H133.93A5.37,5.37,0,0,0,128.57,5.36Zm66.07,123.21H176.79a5.37,5.37,0,0,0-5.36,5.36v37.5h-37.5a5.37,5.37,0,0,0-5.36,5.36v17.85a5.37,5.37,0,0,0,5.36,5.36h55.36A10.69,10.69,0,0,0,200,189.29V133.93A5.37,5.37,0,0,0,194.64,128.57ZM71.43,194.64V176.79a5.37,5.37,0,0,0-5.36-5.36H28.57v-37.5a5.37,5.37,0,0,0-5.36-5.36H5.36A5.37,5.37,0,0,0,0,133.93v55.36A10.69,10.69,0,0,0,10.71,200H66.07A5.37,5.37,0,0,0,71.43,194.64Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoExternal = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M249.23-200.56a83.34,83.34,0,0,0,83.34-83.34,83.33,83.33,0,0,0-83.34-83.33A83.33,83.33,0,0,0,165.9-283.9,83.33,83.33,0,0,0,249.23-200.56Zm0,16.66a100,100,0,0,1-100-100,100,100,0,0,1,100-100,100,100,0,0,1,100,100A100,100,0,0,1,249.23-183.9Z' />
    <path d='M1101.48,93.67c-35.36-1-62.91-14.61-83.59-41.41a3.23,3.23,0,0,1-.48-3.76c14-31.1,38.09-49.65,72.62-54.12,39.68-5.13,78.73,18,93.36,54.47A2.85,2.85,0,0,1,1183,52c-17,22.32-39.37,36-67.59,40.34-4.93.76-10,1-13.9,1.32' />
    <path d='M1142-64.6a41.72,41.72,0,0,1-41.9,41.6,41.77,41.77,0,0,1-41.44-42.07c.11-22.72,19-41.34,41.91-41.26A41.8,41.8,0,0,1,1142-64.6' />
    <path d='M1101.48,93.67c-35.36-1-62.91-14.61-83.59-41.41a3.23,3.23,0,0,1-.48-3.76c14-31.1,38.09-49.65,72.62-54.12,39.68-5.13,78.73,18,93.36,54.47A2.85,2.85,0,0,1,1183,52c-17,22.32-39.37,36-67.59,40.34-4.93.76-10,1-13.9,1.32' />
    <path d='M1142-64.6a41.72,41.72,0,0,1-41.9,41.6,41.77,41.77,0,0,1-41.44-42.07c.11-22.72,19-41.34,41.91-41.26A41.8,41.8,0,0,1,1142-64.6' />
    <path d='M178.84,200H21.16A21.18,21.18,0,0,1,0,178.84V21.15A21.18,21.18,0,0,1,21.16,0h56.1V20.81H21.16l-.35,158,158,.35.35-56.44H200v56.09A21.18,21.18,0,0,1,178.84,200Z' />
    <path d='M70,145.75,53.28,129.08a7.08,7.08,0,0,1,0-10L127,45.42,99,17.5a7.08,7.08,0,0,1,5-12.08h82.49a7.07,7.07,0,0,1,7.07,7.09V95a7.07,7.07,0,0,1-12.08,5L153.62,72.08,79.94,145.75a7.06,7.06,0,0,1-10,0' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoEye = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M99.23,36.37c20.63,0,37.86,6.11,54.08,15.51A175.85,175.85,0,0,1,198.6,90c2.8,3.26,3.06,6.39.35,9.53-21,24.34-45.51,43.66-77.37,51.69-17.91,4.52-35.61,3-52.89-3.45-26.34-9.87-47.36-27.06-65.64-48-3-3.44-3-6.59.52-10.21C11.11,81.87,18.59,74,26.76,67A132,132,0,0,1,72.13,41.15C81.46,38.13,91,36.22,99.23,36.37ZM57.29,95a43.65,43.65,0,1,0,43.77-43.7A43.78,43.78,0,0,0,57.29,95Z' />
    <path d='M101,71.29A23.74,23.74,0,1,1,77.23,94.72,23.85,23.85,0,0,1,101,71.29Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoFacebook = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M78.52,110.55H48.44V75H78.52V46.88q0-22.27,12.5-34.58T124.22,0a193.69,193.69,0,0,1,27.34,1.56V33.2H132.81q-10.55,0-14.45,4.69-3.12,3.9-3.13,12.5V75h33.21l-4.69,35.55H115.23V200H78.52Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoFailure = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M99,13.15.42,186.79a.5.5,0,0,0,.43.75l198.29.84a.51.51,0,0,0,.44-.75L100.76,13.15A1,1,0,0,0,99,13.15Z' />
    <path d='M91.39,141a5.37,5.37,0,0,1,3.15-2.73,22,22,0,0,1,6.59-.72c3,0,5.16.66,6.5,2s2,3.37,2,6.18v1.65a31.58,31.58,0,0,1-.16,3.66,9.23,9.23,0,0,1-1,2.84q-1.65,3-7.88,3t-8.25-2q-2-2-2-7.78A13.79,13.79,0,0,1,91.39,141Zm13.81-12a21.55,21.55,0,0,1-5.25.51,21.7,21.7,0,0,1-5.26-.51A4.18,4.18,0,0,1,92,127.1a7.21,7.21,0,0,1-.93-2.37,24.31,24.31,0,0,1-.15-3.19V88.87A25.93,25.93,0,0,1,91,85.62a6.68,6.68,0,0,1,1-2.42,4.57,4.57,0,0,1,2.79-1.9,19.38,19.38,0,0,1,5.25-.57,19.51,19.51,0,0,1,5.26.57,4.31,4.31,0,0,1,2.73,2,7.76,7.76,0,0,1,.93,2.48,25.64,25.64,0,0,1,.15,3.24v32.67a24.6,24.6,0,0,1-.15,3.2,6.2,6.2,0,0,1-1,2.31A4.43,4.43,0,0,1,105.2,129Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoForward10 = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M187.61,88.87c-.21-1-1.39-2.23-3-3.26a3.69,3.69,0,0,0-1.73-.31,11.5,11.5,0,0,0-2.54.32,1,1,0,0,0-.57.39l0,0a12.6,12.6,0,0,0-1.11,1,5.15,5.15,0,0,0-.86,1.09,6.43,6.43,0,0,0-.4,4.43c.58,2.54,1,4.88,1.41,7.53a102.56,102.56,0,0,1-.06,20.35,102,102,0,0,1-5.24,19.7A102.44,102.44,0,0,1,163,158.2a99.63,99.63,0,0,1-15.13,15,101.34,101.34,0,0,1-18.19,10.31A101.23,101.23,0,0,1,110,188.65a98.56,98.56,0,0,1-20.39-.05,102,102,0,0,1-19.7-5.23,100.36,100.36,0,0,1-18.14-10.43,102,102,0,0,1-15.06-15.12A100.88,100.88,0,0,1,26.38,139.6a101.37,101.37,0,0,1-5.12-19.72,102.74,102.74,0,0,1,0-20.37,101.43,101.43,0,0,1,5.23-19.72A102.17,102.17,0,0,1,37,61.65a97.14,97.14,0,0,1,7-7.78A98.18,98.18,0,0,1,52.1,46.6,101.9,101.9,0,0,1,70.3,36.28a122.9,122.9,0,0,1,12.52-3.75A86.24,86.24,0,0,1,94.67,31V51.47a3.45,3.45,0,0,0,5.89,2.31l24-24a3.36,3.36,0,0,0,0-4.84l-24-24c-1.89-2.21-5.89-.42-5.89,2.42V20.17c-3.43.22-6.82.56-6.82.56-2.34.31-4.55.67-6.58,1.07-15.34,3-30.61,11-43,22.5-.64.6-1.32,1.25-2,1.93A99.05,99.05,0,0,0,24.08,61.41,92.87,92.87,0,0,0,15,80.32,73.38,73.38,0,0,0,11.11,96c-.81,6.16-1.55,13.41-.89,20.51a89.18,89.18,0,0,0,12.3,39.43,93.65,93.65,0,0,0,30.22,30.69,92.34,92.34,0,0,0,19,8.83A93.49,93.49,0,0,0,92,199.55a71.07,71.07,0,0,0,16,0,89.4,89.4,0,0,0,55.62-25.85l.88-.89a90.67,90.67,0,0,0,22.58-39.68A92.43,92.43,0,0,0,187.61,88.87Z' />
    <path d='M73.83,147c4,0,6.6-2.47,6.6-6.13V80.79c0-6.76-1.66-11-9.94-11l-12.79.28A6.45,6.45,0,0,0,58,83l9-.28h.25c0,.15,0,.36,0,.65v57.55C67.33,144.61,69.88,147,73.83,147Z' />
    <path d='M96.93,107.9c0,33,15.59,39.91,28.68,39.91,18.45,0,28.6-14.11,28.6-39.73,0-32.68-15.5-39.54-28.5-39.54C107.14,68.54,96.93,82.51,96.93,107.9Zm28.78-27.1c3.78,0,15.3,0,15.3,27.19,0,27.56-11.58,27.56-15.4,27.56s-15.49,0-15.49-27.56C110.12,90,115.37,80.8,125.71,80.8Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoForward30 = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M96.93,107.9c0,33,15.59,39.91,28.68,39.91,18.45,0,28.6-14.11,28.6-39.73,0-32.68-15.5-39.54-28.5-39.54C107.14,68.54,96.93,82.51,96.93,107.9Zm28.78-27.1c3.78,0,15.3,0,15.3,27.19,0,27.56-11.58,27.56-15.4,27.56s-15.49,0-15.49-27.56C110.12,90,115.37,80.8,125.71,80.8Z' />
    <path d='M-359,859.53c-35.37-1-62.91-14.6-83.59-41.4a3.22,3.22,0,0,1-.48-3.76c14-31.1,38.09-49.66,72.61-54.13,39.68-5.13,78.74,18,93.36,54.47a2.83,2.83,0,0,1-.41,3.16c-17,22.32-39.38,36-67.59,40.34-4.94.76-10,1-13.9,1.32' />
    <path d='M187.61,88.87c-.21-1-1.39-2.23-3-3.26a3.69,3.69,0,0,0-1.73-.31,11.5,11.5,0,0,0-2.54.32,1,1,0,0,0-.57.39l0,0a12.6,12.6,0,0,0-1.11,1,5.15,5.15,0,0,0-.86,1.09,6.43,6.43,0,0,0-.4,4.43c.58,2.54,1,4.88,1.41,7.53a102.56,102.56,0,0,1-.06,20.35,102,102,0,0,1-5.24,19.7A102.44,102.44,0,0,1,163,158.2a99.63,99.63,0,0,1-15.13,15,101.34,101.34,0,0,1-18.19,10.31A101.23,101.23,0,0,1,110,188.65a98.56,98.56,0,0,1-20.39-.05,102,102,0,0,1-19.7-5.23,100.36,100.36,0,0,1-18.14-10.43,102,102,0,0,1-15.06-15.12A100.88,100.88,0,0,1,26.38,139.6a101.37,101.37,0,0,1-5.12-19.72,102.74,102.74,0,0,1,0-20.37,101.43,101.43,0,0,1,5.23-19.72A102.17,102.17,0,0,1,37,61.65a97.14,97.14,0,0,1,7-7.78A98.18,98.18,0,0,1,52.1,46.6,101.9,101.9,0,0,1,70.3,36.28a122.9,122.9,0,0,1,12.52-3.75A86.24,86.24,0,0,1,94.67,31V51.47a3.45,3.45,0,0,0,5.89,2.31l24-24a3.36,3.36,0,0,0,0-4.84l-24-24c-1.89-2.21-5.89-.42-5.89,2.42V20.17c-3.43.22-6.82.56-6.82.56-2.34.31-4.55.67-6.58,1.07-15.34,3-30.61,11-43,22.5-.64.6-1.32,1.25-2,1.93A99.05,99.05,0,0,0,24.08,61.41,92.87,92.87,0,0,0,15,80.32,73.38,73.38,0,0,0,11.11,96c-.81,6.16-1.55,13.41-.89,20.51a89.18,89.18,0,0,0,12.3,39.43,93.65,93.65,0,0,0,30.22,30.69,92.34,92.34,0,0,0,19,8.83A93.49,93.49,0,0,0,92,199.55a71.07,71.07,0,0,0,16,0,89.4,89.4,0,0,0,55.62-25.85l.88-.89a90.67,90.67,0,0,0,22.58-39.68A92.43,92.43,0,0,0,187.61,88.87Z' />
    <path d='M63.45,100.12a12.58,12.58,0,0,0,8.17-2.78,9.71,9.71,0,0,0,3.42-8,9.51,9.51,0,0,0-2.73-6.82A9.68,9.68,0,0,0,65,79.68a13.3,13.3,0,0,0-5.18.87,8.25,8.25,0,0,0-3.21,2.32,17.3,17.3,0,0,0-2.24,3.71c-.71,1.51-1.35,2.93-1.94,4.27a3.61,3.61,0,0,1-1.85,1.7,7.38,7.38,0,0,1-3.09.62,5.37,5.37,0,0,1-3.79-1.67A5.94,5.94,0,0,1,41.93,87a11.79,11.79,0,0,1,1.62-5.64,20.82,20.82,0,0,1,4.74-5.64A24,24,0,0,1,56,71.46a31.28,31.28,0,0,1,10.36-1.62,28.9,28.9,0,0,1,9.12,1.37,21.28,21.28,0,0,1,7.15,3.93,17,17,0,0,1,4.59,6,17.42,17.42,0,0,1,1.53,7.31,16.69,16.69,0,0,1-2.23,8.83,29.78,29.78,0,0,1-6.42,7.18,28.54,28.54,0,0,1,6.78,5,18.48,18.48,0,0,1,5.53,13.44,22.79,22.79,0,0,1-1.87,9.06,23.14,23.14,0,0,1-5.54,7.8,26.51,26.51,0,0,1-8.68,5.36A30.85,30.85,0,0,1,65.26,147a26.54,26.54,0,0,1-11.08-2.22,25.71,25.71,0,0,1-8.06-5.54,25.28,25.28,0,0,1-4.78-6.87,14.74,14.74,0,0,1-1.62-5.87,6.31,6.31,0,0,1,1.93-4.81,6.71,6.71,0,0,1,4.82-1.83,5.08,5.08,0,0,1,2.77.85,4.12,4.12,0,0,1,1.76,2q2.67,7.17,5.73,10.63c2.05,2.33,4.92,3.48,8.63,3.48a12.9,12.9,0,0,0,6.15-1.57,12.75,12.75,0,0,0,4.89-4.66,13.19,13.19,0,0,0,1.93-7.16q0-6-3.29-9.45t-9.17-3.42c-.69,0-1.75.07-3.19.21s-2.37.2-2.78.2a6.26,6.26,0,0,1-4.37-1.41A5.09,5.09,0,0,1,54,105.63a4.89,4.89,0,0,1,1.85-4,8.54,8.54,0,0,1,5.51-1.52Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoForward60 = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M187.61,88.87c-.22-.96-1.4-2.23-3.04-3.25-.17-.09-.68-.32-1.73-.32-.73,0-1.58,.11-2.54,.32-.24,.06-.45,.2-.58,.39h-.01c-.26,.19-.69,.51-1.11,.93-.56,.57-.83,1.02-.87,1.09-.62,1.09-.77,2.8-.39,4.43,.58,2.54,1.02,4.88,1.41,7.53,.66,6.7,.63,13.37-.07,20.35-1.07,6.68-2.78,13.14-5.23,19.7-2.81,6.34-6.24,12.3-10.43,18.16-2.26,2.78-4.55,5.32-6.99,7.75-2.54,2.54-5.14,4.86-8.14,7.28-5.65,4.03-11.61,7.41-18.19,10.31-6.34,2.35-12.82,4.03-19.73,5.11-7.16,.7-13.18,.66-20.39-.05-6.71-1.08-13.17-2.8-19.7-5.23-6.38-2.82-12.33-6.24-18.14-10.43-5.52-4.49-10.46-9.45-15.06-15.13-4.07-5.72-7.44-11.69-10.31-18.21-2.35-6.32-4.02-12.78-5.12-19.72-.66-6.75-.64-13.43,.05-20.37,1.06-6.66,2.78-13.13,5.23-19.71,2.83-6.38,6.26-12.33,10.43-18.15,2.26-2.78,4.55-5.33,7.01-7.77,2.49-2.5,5.08-4.82,8.13-7.27,5.67-4.04,11.64-7.42,18.2-10.32,4.16-1.55,8.28-2.66,12.52-3.76,2.56-.66,7.53-1.16,11.85-1.49v20.43c0,2.95,3.89,4.52,5.89,2.31l23.98-23.98c1.37-1.37,1.37-3.47,0-4.84L100.56,.99c-1.89-2.21-5.89-.42-5.89,2.42V20.17c-3.44,.23-6.83,.56-6.83,.56-2.34,.31-4.55,.67-6.58,1.08-15.34,3.04-30.61,11.02-43,22.5-.64,.6-1.31,1.25-2.01,1.93-4.22,4.24-8.31,9.34-12.17,15.18-3.29,4.99-6.18,11-9.12,18.91-1.82,4.89-3.11,10.16-3.85,15.66-.81,6.16-1.55,13.41-.89,20.51,.76,8.26,1.97,14.71,3.79,20.29,2.37,7.26,5.22,13.7,8.5,19.14,7.38,12.26,17.83,22.88,30.22,30.7,5.21,3.29,11.44,6.18,19.03,8.82,6.87,2.39,14.09,3.42,20.24,4.11,2.67,.3,5.37,.45,7.99,.45s5.31-.15,7.98-.45c6.23-.7,13.53-1.75,20.39-4.17,13.86-4.87,25.71-12.16,35.23-21.68l.88-.89c10.81-11.13,18.62-24.85,22.58-39.68,3.76-14.09,3.94-29.39,.54-44.27Z' />
    <path d='M187.61,88.87c-.22-.96-1.4-2.23-3.04-3.25-.17-.09-.68-.32-1.73-.32-.73,0-1.58,.11-2.54,.32-.24,.06-.45,.2-.58,.39h-.01c-.26,.19-.69,.51-1.11,.93-.56,.57-.83,1.02-.87,1.09-.62,1.09-.77,2.8-.39,4.43,.58,2.54,1.02,4.88,1.41,7.53,.66,6.7,.63,13.37-.07,20.35-1.07,6.68-2.78,13.14-5.23,19.7-2.81,6.34-6.24,12.3-10.43,18.16-2.26,2.78-4.55,5.32-6.99,7.75-2.54,2.54-5.14,4.86-8.14,7.28-5.65,4.03-11.61,7.41-18.19,10.31-6.34,2.35-12.82,4.03-19.73,5.11-7.16,.7-13.18,.66-20.39-.05-6.71-1.08-13.17-2.8-19.7-5.23-6.38-2.82-12.33-6.24-18.14-10.43-5.52-4.49-10.46-9.45-15.06-15.13-4.07-5.72-7.44-11.69-10.31-18.21-2.35-6.32-4.02-12.78-5.12-19.72-.66-6.75-.64-13.43,.05-20.37,1.06-6.66,2.78-13.13,5.23-19.71,2.83-6.38,6.26-12.33,10.43-18.15,2.26-2.78,4.55-5.33,7.01-7.77,2.49-2.5,5.08-4.82,8.13-7.27,5.67-4.04,11.64-7.42,18.2-10.32,4.16-1.55,8.28-2.66,12.52-3.76,2.56-.66,7.53-1.16,11.85-1.49v20.43c0,2.95,3.89,4.52,5.89,2.31l23.98-23.98c1.37-1.37,1.37-3.47,0-4.84L100.56,.99c-1.89-2.21-5.89-.42-5.89,2.42V20.17c-3.44,.23-6.83,.56-6.83,.56-2.34,.31-4.55,.67-6.58,1.08-15.34,3.04-30.61,11.02-43,22.5-.64,.6-1.31,1.25-2.01,1.93-4.22,4.24-8.31,9.34-12.17,15.18-3.29,4.99-6.18,11-9.12,18.91-1.82,4.89-3.11,10.16-3.85,15.66-.81,6.16-1.55,13.41-.89,20.51,.76,8.26,1.97,14.71,3.79,20.29,2.37,7.26,5.22,13.7,8.5,19.14,7.38,12.26,17.83,22.88,30.22,30.7,5.21,3.29,11.44,6.18,19.03,8.82,6.87,2.39,14.09,3.42,20.24,4.11,2.67,.3,5.37,.45,7.99,.45s5.31-.15,7.98-.45c6.23-.7,13.53-1.75,20.39-4.17,13.86-4.87,25.71-12.16,35.23-21.68l.88-.89c10.81-11.13,18.62-24.85,22.58-39.68,3.76-14.09,3.94-29.39,.54-44.27Z' />
    <path d='M101.34,107.9c0,32.99,15.6,39.92,28.69,39.92,18.44,0,28.59-14.11,28.59-39.73,0-32.68-15.5-39.54-28.5-39.54-18.56,0-28.78,13.98-28.78,39.36Zm28.78-27.1c3.78,0,15.31,0,15.31,27.19s-11.59,27.56-15.4,27.56-15.5,0-15.5-27.56c0-18.04,5.25-27.19,15.59-27.19Z' />
    <path d='M71.05,95.55c-7.96,0-14.55,3.2-18.94,9.1,.57-8.06,1.6-11.7,3.44-15.99,2.94-6.89,8.08-10.53,14.82-10.53s10.53,2.49,13,8.35c.86,1.83,2.47,2.83,4.53,2.83,.91,0,1.78-.22,2.59-.45,2.18-.82,3.58-2.65,3.58-4.66,0-.86-.2-1.61-.58-2.29-4.42-9.29-11.86-13.81-22.74-13.81-18.95,0-29.39,14.65-29.39,41.23,0,24.62,10.54,38.74,28.91,38.74,15.73,0,26.3-10.8,26.3-26.89s-10.02-25.63-25.52-25.63Zm14.54,25.63c0,10.18-6.12,16.76-15.6,16.76s-15.98-6.93-15.98-16.47,6.84-16.08,16.28-16.08,15.31,6.2,15.31,15.79Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoFullscreen = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='m68.27,0H13.73C6.2,0,.09,6.11.09,13.64v54.55c0,7.53,6.1,13.64,13.64,13.64s13.64-6.11,13.64-13.64V27.27h40.91c7.53,0,13.64-6.11,13.64-13.64S75.81,0,68.27,0Z' />
    <path d='m199.96,68.18V13.64c0-7.53-6.11-13.64-13.64-13.64h-54.55c-7.53,0-13.64,6.1-13.64,13.64s6.11,13.64,13.64,13.64h40.91v40.91c0,7.53,6.11,13.64,13.64,13.64s13.64-6.1,13.64-13.64Z' />
    <path d='m131.78,200h54.55c7.53,0,13.64-6.11,13.64-13.64v-54.55c0-7.53-6.1-13.64-13.64-13.64s-13.64,6.11-13.64,13.64v40.91h-40.91c-7.53,0-13.64,6.11-13.64,13.64s6.1,13.64,13.64,13.64Z' />
    <path d='m.04,131.82v54.55c0,7.53,6.11,13.64,13.64,13.64h54.55c7.53,0,13.64-6.1,13.64-13.64s-6.11-13.64-13.64-13.64H27.31v-40.91c0-7.53-6.11-13.64-13.64-13.64S.04,124.28.04,131.82Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoGem = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <polygon points='126.42 70.71 73.03 70.56 99.92 -0.23 126.42 70.71' />
    <polygon points='36.21 103.31 67.21 75.38 91.61 193.08 36.21 103.31' />
    <polygon points='132.11 75.71 163.29 103.27 107.66 192.83 132.11 75.71' />
    <polygon points='126.41 76.12 73.01 75.97 99.37 199.77 126.41 76.12' />
    <polygon points='67.8 69.97 34.51 96.83 93.14 3.2 67.8 69.97' />
    <polygon points='164.79 97.18 131.65 70.14 106.7 3.24 164.79 97.18' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoHearingImpaired = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='m148.74955,88.58571c0.01768,-36.15255 -30.94144,-61.43388 -66.34449,-54.15458c-19.9889,4.11162 -36.23386,20.31415 -40.23942,40.0874c-0.74596,3.67677 -1.85959,7.58687 -6.61465,6.98586c-4.80808,-0.60808 -8.06414,-6.12324 -6.89748,-10.74395c10.81466,-42.78841 61.25358,-68.29953 102.81169,-39.9778c19.9288,13.5793 30.30153,32.86467 30.99799,56.57983c0.55506,18.88941 -6.0101,35.90508 -19.65304,49.49852c-10.74394,10.70859 -21.45254,21.35001 -28.28284,35.20154c-2.81415,5.70253 -5.35253,11.75152 -9.2591,16.65859c-9.33688,11.73385 -24.56719,14.76365 -37.25558,8.18789c-1.87374,-0.97223 -3.65556,-2.24495 -5.20404,-3.68031c-2.2591,-2.08939 -4.07273,-4.52172 -1.76415,-7.72122c2.3192,-3.20656 5.17576,-3.77222 8.54142,-1.60858c11.1894,7.19445 24.50002,3.29848 29.9975,-9.67274c7.59394,-17.91718 18.90001,-33.24295 33.26062,-46.58891c11.07981,-10.2985 15.8985,-23.88487 15.90557,-39.05154' />
    <path d='m95.71957,40.08593c35.4384,0.95101 57.9869,39.98841 41.04548,71.09247c-0.85556,1.57323 -1.90202,3.07222 -3.06162,4.43687c-1.98687,2.34394 -4.29192,3.59192 -7.26162,1.43182c-2.96617,-2.1601 -3.22778,-4.83283 -1.40707,-7.7495c4.40151,-7.05657 6.24343,-14.65759 5.85808,-22.99042c-0.66818,-14.45253 -11.61718,-28.24396 -26.1086,-32.41214c-14.54799,-4.18233 -30.17073,1.5096 -38.49296,14.03183c-0.72828,1.08888 -1.34697,2.25555 -2.1106,3.31969c-1.98334,2.77172 -4.40859,4.71263 -7.8697,2.56314c-3.34445,-2.08233 -4.54294,-4.86465 -2.549,-8.7889c7.52324,-14.82021 25.17174,-25.38739 41.95761,-24.93486' />
    <path d='m28.41629,171.67788c-3.53889,0 -7.08132,-1.35051 -9.77879,-4.05152c-5.40203,-5.40202 -5.40203,-14.1591 0,-19.56112l30.31214,-30.31214c5.40202,-5.40202 14.1591,-5.40202 19.56112,0c5.40202,5.40202 5.40202,14.1591 0,19.56112l-30.31567,30.31214c-2.69748,2.70101 -6.23991,4.05152 -9.7788,4.05152' />
    <path d='m164.96234,35.13183c-3.53889,0 -7.08132,-1.3505 -9.7788,-4.05151c-5.40202,-5.40203 -5.40202,-14.1591 0,-19.56113l7.14849,-7.14849c5.40203,-5.39848 14.1591,-5.39848 19.55759,0.00354c5.40202,5.39849 5.40202,14.1591 0,19.55759l-7.14849,7.14849c-2.69748,2.70101 -6.2399,4.05151 -9.77879,4.05151' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoHeartEmpty = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M191.48,46.41c-7.88-15-21.84-28.31-38.86-31.88a43.41,43.41,0,0,0-8.93-.94,40.08,40.08,0,0,0-15.08,2.89C119.38,20.23,112,26.89,106,34.79a80.32,80.32,0,0,0-6.26,9.85C91.07,28.88,77.41,15,58.56,13.69c-.94-.07-1.89-.1-2.83-.1-15.86,0-30.6,9.42-40.25,21.59A72.74,72.74,0,0,0,.28,88.82c2.4,19.67,12.31,37,25.91,51.18C44,158.53,66.85,171.87,89.93,182.69q2.47,1.16,5,2.28c1.56.7,3.35,1.87,5.09,1.87h.14c1.68-.06,3.49-1.22,5-1.9q2.67-1.2,5.31-2.45c6.66-3.13,13.23-6.47,19.65-10.08a211.16,211.16,0,0,0,31.56-21.35c15.6-12.93,29-29.29,34.94-48.95A74.39,74.39,0,0,0,191.48,46.41ZM180,97.12a70.86,70.86,0,0,1-11.15,21.66,110,110,0,0,1-18.23,18.91,192.69,192.69,0,0,1-29,19.58c-5.74,3.22-12,6.42-18.55,9.51L100,168.21,97.3,167c-26.23-12.3-44.85-24.69-58.59-39a85.81,85.81,0,0,1-14.28-19.53,63.56,63.56,0,0,1-6.92-21.72A55.26,55.26,0,0,1,29.09,46,44.14,44.14,0,0,1,41.81,35a28.35,28.35,0,0,1,13.92-4c.54,0,1.07,0,1.6.06A25.6,25.6,0,0,1,72,37.21,54.3,54.3,0,0,1,84.57,53a17.36,17.36,0,0,0,15.2,9h.06A17.36,17.36,0,0,0,115,52.93a63.48,63.48,0,0,1,4.9-7.72c4.63-6.17,9.75-10.42,15.22-12.64A22.62,22.62,0,0,1,143.69,31a26.38,26.38,0,0,1,5.36.56,34.26,34.26,0,0,1,15.15,7.84,51,51,0,0,1,11.89,15.1,55.14,55.14,0,0,1,6.08,20.84A57.77,57.77,0,0,1,180,97.12Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoHeartFull = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M191.48,46.41c-7.88-15-21.84-28.31-38.86-31.88a43.41,43.41,0,0,0-8.93-.94,40.08,40.08,0,0,0-15.08,2.89C119.38,20.23,112,26.89,106,34.79a80.32,80.32,0,0,0-6.26,9.85C91.07,28.88,77.41,15,58.56,13.69c-.94-.07-1.89-.1-2.83-.1-15.86,0-30.6,9.42-40.25,21.59A72.74,72.74,0,0,0,.28,88.82c2.4,19.67,12.31,37,25.91,51.18C44,158.53,66.85,171.87,89.93,182.69q2.47,1.16,5,2.28c1.56.7,3.35,1.87,5.09,1.87h.14c1.68-.06,3.49-1.22,5-1.9q2.67-1.2,5.31-2.45c6.66-3.13,13.23-6.47,19.65-10.08a211.16,211.16,0,0,0,31.56-21.35c15.6-12.93,29-29.29,34.94-48.95A74.39,74.39,0,0,0,191.48,46.41Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoInfo = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M1156.28-278.29c-35.37-1-62.91-14.61-83.59-41.41a3.24,3.24,0,0,1-.48-3.76c14-31.1,38.09-49.66,72.62-54.12,39.67-5.14,78.73,18,93.36,54.47a2.83,2.83,0,0,1-.42,3.15c-17,22.32-39.37,36-67.59,40.35-4.93.75-10,.95-13.9,1.32' />
    <path d='M1196.82-436.56a41.72,41.72,0,0,1-41.9,41.6A41.78,41.78,0,0,1,1113.48-437c.11-22.73,19-41.35,41.91-41.26a41.8,41.8,0,0,1,41.43,41.73' />
    <path d='M1156.28-278.29c-35.37-1-62.91-14.61-83.59-41.41a3.24,3.24,0,0,1-.48-3.76c14-31.1,38.09-49.66,72.62-54.12,39.67-5.14,78.73,18,93.36,54.47a2.83,2.83,0,0,1-.42,3.15c-17,22.32-39.37,36-67.59,40.35-4.93.75-10,.95-13.9,1.32' />
    <path d='M1196.82-436.56a41.72,41.72,0,0,1-41.9,41.6A41.78,41.78,0,0,1,1113.48-437c.11-22.73,19-41.35,41.91-41.26a41.8,41.8,0,0,1,41.43,41.73' />
    <path d='M102.07.06A99.87,99.87,0,0,0,0,99.85C-.25,155.08,44.71,200,99.88,200,154.54,200.57,200,155.23,200,100.4A100,100,0,0,0,102.07.06Zm-2,182.33c-45.66,0-82.84-36.9-82.45-82.57s36.7-82.79,84-82.17c44.67.59,81,36.9,80.86,83.18C182.28,145.86,145.47,182.39,100,182.39Z' />
    <path d='M90.47,62.72a3.45,3.45,0,0,1-1-2.54v-11a3.62,3.62,0,0,1,1-2.69A3.47,3.47,0,0,1,93,45.48h13.8a3.6,3.6,0,0,1,3.76,3.74v11a3.32,3.32,0,0,1-1.14,2.54,3.7,3.7,0,0,1-2.62,1.05H93A3.47,3.47,0,0,1,90.47,62.72Zm.61,90.75a3.42,3.42,0,0,1-1-2.55V80.12a3.36,3.36,0,0,1,1-2.62,3.57,3.57,0,0,1,2.54-1h12.76A3.58,3.58,0,0,1,110,80.12v70.8a3.58,3.58,0,0,1-3.59,3.6H93.62A3.43,3.43,0,0,1,91.08,153.47Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoInstagram = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M199.55,58.93Q200,71,200,100t-.45,41.07q-1.34,27.24-16.29,42.19t-42.19,15.85Q129,200,100,200t-41.07-.89Q31.69,197.76,17,183a43.17,43.17,0,0,1-9.37-12.5A62.15,62.15,0,0,1,2.46,156.7,68.84,68.84,0,0,1,.89,141.07Q0,129,0,100T.89,58.48Q2.24,31.69,17,17,31.71,1.79,58.93.45,71,0,100,0t41.07.45q27.23,1.34,42.19,16.29T199.55,58.93Zm-21.43,100a48.14,48.14,0,0,0,2.24-9.15q.88-5.58,1.34-13.4t.44-12.72V76.34q0-4.91-.44-12.72t-1.34-13.4a48.53,48.53,0,0,0-2.24-9.15,32.22,32.22,0,0,0-19.19-19.19,48.53,48.53,0,0,0-9.15-2.24q-5.59-.88-13.4-1.34t-12.5-.44H76.34q-4.92,0-12.72.44t-13.4,1.34a48.14,48.14,0,0,0-9.15,2.24A32.2,32.2,0,0,0,21.88,41.07a48.53,48.53,0,0,0-2.24,9.15q-.9,5.6-1.34,13.4t-.44,12.5V119c0,2.84.07,6.48.22,10.94s.37,8.26.67,11.38.74,6.33,1.34,9.6a79.82,79.82,0,0,0,1.79,8q5.79,13.84,19.19,19.19a48.14,48.14,0,0,0,9.15,2.24,134.51,134.51,0,0,0,13.4,1.34q7.8.45,12.5.44h47.54q4.91,0,12.72-.44t13.4-1.34a48.53,48.53,0,0,0,9.15-2.24Q172.76,172.33,178.12,158.93ZM63.62,63.62q14.94-15,36.38-15A51.08,51.08,0,0,1,151.34,100q0,21.44-15,36.38t-36.38,15q-21.43,0-36.38-15T48.66,100Q48.66,78.57,63.62,63.62Zm12.72,60a33.41,33.41,0,0,0,47.32,0,33.41,33.41,0,0,0,0-47.32,33.41,33.41,0,0,0-47.32,0,33.41,33.41,0,0,0,0,47.32Zm85.49-85.49a11.24,11.24,0,0,1,3.35,8.26,12,12,0,0,1-3.35,8.48,10.84,10.84,0,0,1-8.26,3.57,12.19,12.19,0,0,1-12-12,10.84,10.84,0,0,1,3.57-8.26,12,12,0,0,1,8.48-3.35A11.24,11.24,0,0,1,161.83,38.17Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoLeaf = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M42.73,102.52c-2.14-1.54-5.16-1.1-6.74,.97L.95,130.31c-1.58,2.07-1.14,5,1,6.54,2.14,1.54,5.16,1.1,6.74-.97l35.04-26.82c1.58-2.08,1.14-5-1-6.54Z' />
    <path d='M198.08,84c-47.22-35.54-67.67-40.26-90.9-36.75-20.52,3.1-38.02,9.36-76.45,62.02-1.56,2.14-1.12,5.16,.99,6.74,47.22,35.54,67.66,40.27,90.9,36.76,20.52-3.1,38.02-9.36,76.45-62.02,1.56-2.14,1.12-5.16-.99-6.75Zm-76.86,59.22c-17.18,2.59-34.1,1.83-80.01-32.1,36.99-49.76,52.42-52.09,67.36-54.34,17.18-2.59,34.1-1.83,80.01,32.1-36.99,49.76-52.42,52.09-67.36,54.34Z' />
    <path d='M139.3,88.88l-98.06,13.59c-2.56,.36-4.33,2.58-3.95,4.97,.19,1.2,.88,2.21,1.85,2.87,.97,.67,2.2,.99,3.49,.81l98.06-13.59c2.56-.35,4.33-2.58,3.95-4.97-.38-2.39-2.77-4.04-5.33-3.68Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoMinus = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M186.53,112.06H13.47a11.55,11.55,0,0,1,0-23.1H186.53a11.55,11.55,0,1,1,0,23.1Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoMoreInfo = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M100.21,0a100,100,0,1,0,100,100A100.12,100.12,0,0,0,100.21,0Zm0,183.19A83.18,83.18,0,1,1,183.38,100,83.27,83.27,0,0,1,100.21,183.18Z' />
    <path d='M134.39,90.52H107.53l3.62-30.17A6.93,6.93,0,0,0,109,53.92a9.44,9.44,0,0,0-6.43-2.31,10.74,10.74,0,0,0-6.77,2.31,9.24,9.24,0,0,0-3.46,6.43L88.74,90.52H61.87a9,9,0,0,0-7.33,3.3,11.37,11.37,0,0,0-2.72,7.41,8.67,8.67,0,0,0,2,5.7,7,7,0,0,0,5.77,2.39H86.26l-3.62,30.16c-.32,2.87.3,5.07,1.9,6.6a8.51,8.51,0,0,0,6.18,2.31,11.31,11.31,0,0,0,7-2.31,9.71,9.71,0,0,0,3.7-6.6l3.62-30.16h27a8.88,8.88,0,0,0,7.33-3.39,11.62,11.62,0,0,0,2.72-7.49,8.37,8.37,0,0,0-2-5.61A7.22,7.22,0,0,0,134.39,90.52Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoNext = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M.31,99.41h0v1h0Q.33,118.7.3,137c0,13.45,0,26.9.17,40.34.05,4.47.86,9.43,3,13.27,6,10.93,18,12.8,29.78,4.81C54.42,181,75.46,166.26,96.55,151.65c14.74-10.21,29.39-20.57,44.22-30.66v56.9a21.44,21.44,0,0,0,21.37,21.37h16.32a21.44,21.44,0,0,0,21.38-21.37V21.66A21.44,21.44,0,0,0,178.46.28H162.14a21.44,21.44,0,0,0-21.37,21.38V80.75c-6.49-4.5-12.89-9.13-19.39-13.63Q77.72,36.89,33.93,6.8C28.56,3.13,22.74,0,15.63,2,3.84,5.4.61,12.42.44,24.17c-.2,13-.22,25.93-.2,38.9C.25,75.18.31,87.3.31,99.41Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoNoContent = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M2189.45-756.94c-35.37-1-62.91-14.61-83.59-41.41a3.24,3.24,0,0,1-.48-3.76c14-31.1,38.09-49.66,72.61-54.12,39.68-5.14,78.74,18,93.37,54.47a2.83,2.83,0,0,1-.42,3.15c-17,22.32-39.38,36-67.59,40.35-4.94.75-10,1-13.9,1.32' />
    <path d='M2230-915.21a41.7,41.7,0,0,1-41.89,41.6,41.78,41.78,0,0,1-41.44-42.07c.11-22.73,19-41.35,41.9-41.26A41.79,41.79,0,0,1,2230-915.21' />
    <path d='M53.41,109.57a8.22,8.22,0,1,1-8.22-8.21A8.22,8.22,0,0,1,53.41,109.57Zm63.14,0a8.21,8.21,0,1,1-8.21-8.21A8.21,8.21,0,0,1,116.55,109.57ZM76.77,39.09a67.86,67.86,0,1,0,67.85,67.85A67.93,67.93,0,0,0,76.77,39.09m0,144.62a76.77,76.77,0,1,1,76.76-76.77,76.85,76.85,0,0,1-76.76,76.77' />
    <path d='M72.44,150.81a2.81,2.81,0,0,1-.59-1.45,1.76,1.76,0,0,1,.61-1.39,2.26,2.26,0,0,1,1.52-.52,2.39,2.39,0,0,1,1.29.37,11.49,11.49,0,0,1,1.25.88h0c.48.34,1,.66,1.45,1a2.72,2.72,0,0,0,1.54.48,3.92,3.92,0,0,0,2-.5,4.46,4.46,0,0,0,1.45-1.32,6.33,6.33,0,0,0,.91-1.84,7.1,7.1,0,0,0,.32-2.11,2.44,2.44,0,0,0-.75-1.82,5.6,5.6,0,0,0-1.81-1.15,10,10,0,0,0-2.25-.62,13.61,13.61,0,0,0-2-.18,2.75,2.75,0,0,1-1.5-.82,1.8,1.8,0,0,1-.45-1.18,1.77,1.77,0,0,1,.5-1.2,2.73,2.73,0,0,1,1.54-.8c.48-.06,1-.15,1.58-.27a10.5,10.5,0,0,0,1.68-.5,5.68,5.68,0,0,0,1.47-.82,3.57,3.57,0,0,0,1-1.18c.25-1.33,0-2.25-.61-2.75a4.24,4.24,0,0,0-2.65-.75,12.61,12.61,0,0,0-2.13.2,5.61,5.61,0,0,0-1.77.55,7,7,0,0,1-.88.55,2.28,2.28,0,0,1-1,.2,2.09,2.09,0,0,1-1.5-.62,2.36,2.36,0,0,1-.63-1.79,2.91,2.91,0,0,1,1.31-1.65,8.15,8.15,0,0,1,2.18-.93,12.8,12.8,0,0,1,2.33-.41c.77-.06,1.37-.09,1.79-.09a12.25,12.25,0,0,1,3.15.39,7.68,7.68,0,0,1,2.57,1.18A5.79,5.79,0,0,1,87,130a6.42,6.42,0,0,1,.63,2.93c0,.24,0,.56,0,1a4.14,4.14,0,0,1-.3,1.31,6,6,0,0,1-.9,1.55,7.92,7.92,0,0,1-1.86,1.63,9.77,9.77,0,0,1,3,2.63,6.27,6.27,0,0,1,1.26,3.86,7.71,7.71,0,0,1-.81,3.43,11.37,11.37,0,0,1-2.11,3,10.66,10.66,0,0,1-2.9,2.12,7.36,7.36,0,0,1-3.24.79,8,8,0,0,1-4-1A19.26,19.26,0,0,1,72.44,150.81Z' />
    <path d='M195.89,32.6C192,25.12,185,18.53,176.58,16.76a20.58,20.58,0,0,0-11.92,1,26.8,26.8,0,0,0-11.21,9.09,39.8,39.8,0,0,0-3.12,4.9C146,23.89,139.23,17,129.87,16.34c-8.41-.6-16.33,4.28-21.4,10.68a36.1,36.1,0,0,0-7.55,26.64c1.19,9.77,6.12,18.39,12.87,25.42,8.84,9.2,20.19,15.83,31.66,21.2l2.46,1.14a6.45,6.45,0,0,0,2.6.92,7.43,7.43,0,0,0,2.48-.94l2.64-1.21c3.31-1.56,6.57-3.22,9.76-5a104.52,104.52,0,0,0,15.68-10.61c7.74-6.42,14.42-14.54,17.35-24.31A37,37,0,0,0,195.89,32.6Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoPause = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M83.53,86.52V21.42A21.62,21.62,0,0,0,62.07-.22H45.69A21.62,21.62,0,0,0,24.23,21.42V178.15a21.61,21.61,0,0,0,21.46,21.63H62.07a21.61,21.61,0,0,0,21.46-21.63V86.52Z' />
    <path d='M175.19,86.52V21.42A21.61,21.61,0,0,0,153.74-.22H137.36A21.61,21.61,0,0,0,115.9,21.42V178.15a21.6,21.6,0,0,0,21.46,21.63h16.38a21.6,21.6,0,0,0,21.45-21.63V86.52Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoPlay = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M22.21,99c0-25.56-.26-51.13.12-76.69C22.5,10.5,25.76,3.45,37.6,0,44.75-2,50.61,1.16,56,4.85Q100.1,35,143.94,65.5c8.7,6,17.17,12.41,26,18.22,10.6,7,10.59,24.37-.18,31.59-17.11,11.47-33.87,23.46-50.8,35.18-21.21,14.69-42.37,29.46-63.7,44-11.83,8-23.94,6.16-29.94-4.84-2.1-3.86-2.92-8.85-3-13.34-.27-25.75-.13-51.51-.13-77.27Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoQuestionMark = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M99.73,0C68.55,0,48.36,12.77,32.5,35.56a9.39,9.39,0,0,0,2,12.84L51.38,61.18a9.38,9.38,0,0,0,13-1.62c9.78-12.26,17-19.32,32.32-19.32,12,0,26.89,7.73,26.89,19.39,0,8.81-7.28,13.33-19.14,20C90.6,87.37,72.29,97,72.29,121.18V125a9.37,9.37,0,0,0,9.37,9.37H110a9.37,9.37,0,0,0,9.38-9.37v-2.26c0-16.74,48.93-17.43,48.93-62.74C168.28,25.88,132.89,0,99.73,0ZM95.82,145.88a27.06,27.06,0,1,0,27.06,27.06A27.09,27.09,0,0,0,95.82,145.88Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoPrevious = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M199.75,100.15h0v-1h0c0-12.17,0-24.34,0-36.51,0-13.45,0-26.89-.16-40.34,0-4.46-.86-9.43-3-13.27-6-10.93-18-12.8-29.77-4.8-21.22,14.41-42.26,29.1-63.35,43.71C88.77,58.12,74.13,68.48,59.3,78.57V21.67A21.44,21.44,0,0,0,37.93.3H21.61A21.44,21.44,0,0,0,.23,21.67V177.91a21.44,21.44,0,0,0,21.38,21.37H37.93A21.44,21.44,0,0,0,59.3,177.91v-59.1c6.49,4.5,12.89,9.14,19.38,13.63,29.12,20.15,58.22,40.34,87.45,60.32,5.38,3.67,11.2,6.84,18.31,4.79,11.78-3.39,15-10.41,15.19-22.16.2-13,.22-25.93.2-38.9C199.82,124.38,199.75,112.26,199.75,100.15Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoReplay = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M50.51,186.6A100.54,100.54,0,0,0,99.31,200h1.37a102.13,102.13,0,0,0,44.41-10.55c7.44-3.57,13.9-8.4,19.25-12.65A80.81,80.81,0,0,0,177.11,164,103.3,103.3,0,0,0,189.68,145a102.37,102.37,0,0,0,8-21.79,103.6,103.6,0,0,0-.39-47.58,98.93,98.93,0,0,0-21.16-40.47c-5.16-6.22-11.35-11.26-16.72-15.37a82.09,82.09,0,0,0-15.57-9.42,100.94,100.94,0,0,0-21.94-7.61A107,107,0,0,0,100.53.41c-1.06,0-2.1,0-3.15.06-18.64.72-36.85,6.44-51.29,16.11-2,1.36-4,2.79-6,4.27a6.66,6.66,0,0,1-8.77-.57c-4-4-8-8-12.08-12a8.13,8.13,0,0,0-2.93-2,3.45,3.45,0,0,0-1-.17c-1.84,0-2.53,2.91-2.7,3.8-1.83,9.6-3.57,19.21-5.31,28.82L5.73,47.55c-.6,3.3-1.18,6.6-1.76,9.91C2.77,64.22,1.58,71,.25,77.73a2.76,2.76,0,0,0,.68,2.15A3,3,0,0,0,3,81.06c3.54-.7,6.81-1.25,10.07-1.79q2.94-.48,5.87-1L26,77q22.47-4.08,44.92-8.26c2.82-.53,3.29-1,3.53-1.43,1.08-2,1.34-2.43-1.4-5.26C68.73,57.6,64.37,53.22,60,48.85a6.69,6.69,0,0,1-1.91-5.47,6.6,6.6,0,0,1,3.09-4.9,95.24,95.24,0,0,1,10.66-5.77,95.3,95.3,0,0,1,18.63-5.15,94.7,94.7,0,0,1,19.72,0,94.61,94.61,0,0,1,18.65,5A94.49,94.49,0,0,1,145.11,42a95.21,95.21,0,0,1,13.35,13.27A95.48,95.48,0,0,1,168,71.51a94.71,94.71,0,0,1,5.15,18.62,95.73,95.73,0,0,1,.06,19.73,94.53,94.53,0,0,1-5.05,18.66,94.67,94.67,0,0,1-9.45,16.29,95.8,95.8,0,0,1-13.28,13.35,95.45,95.45,0,0,1-16.22,9.55,95.09,95.09,0,0,1-18.62,5.15,95.29,95.29,0,0,1-10.09.54c-3.2,0-6.44-.16-9.62-.48a94.43,94.43,0,0,1-18.67-5,95.88,95.88,0,0,1-16.29-9.45,95.75,95.75,0,0,1-13.35-13.27c-1.55-2.11-2.78-3.93-3.95-5.8a14.3,14.3,0,0,0-8.05-6.29,13.46,13.46,0,0,0-3.47-.43,12.61,12.61,0,0,0-6.54,1.62c-3.35,2.16-5.64,5-6.28,7.8-.53,2.33-1.09,6.78,1.16,10.37A102.15,102.15,0,0,0,50.51,186.6Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoLogOut = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M157.14,21.47A98.41,98.41,0,0,1,100.2,200C46,200.12,1.67,156,1.59,101.75A98.41,98.41,0,0,1,42.78,21.51a9.55,9.55,0,0,1,13.89,3l6.27,11.15A9.53,9.53,0,0,1,60.32,48a66.66,66.66,0,1,0,79.32,0,9.48,9.48,0,0,1-2.58-12.27l6.27-11.15a9.51,9.51,0,0,1,13.81-3.09Zm-41.27,83.29V9.52A9.5,9.5,0,0,0,106.35,0H93.65a9.5,9.5,0,0,0-9.52,9.52v95.24a9.5,9.5,0,0,0,9.52,9.53h12.7A9.5,9.5,0,0,0,115.87,104.76Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoMagnifier = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M123,122.67a52.07,52.07,0,0,0,15.84-38.1,51.58,51.58,0,0,0-15.84-38A51.47,51.47,0,0,0,85.1,30.72a51.91,51.91,0,0,0-38,15.87,51.93,51.93,0,0,0-15.72,38,52.43,52.43,0,0,0,15.72,38.1A52.26,52.26,0,0,0,85.1,138.41,51.81,51.81,0,0,0,123,122.67Zm77.29,61.9A15.59,15.59,0,0,1,185,200a14.38,14.38,0,0,1-10.8-4.57L133,154.28a81.64,81.64,0,0,1-47.89,14.9,83.27,83.27,0,0,1-32.89-6.61A84.44,84.44,0,0,1,.6,84.57,83,83,0,0,1,7.2,51.76a82.85,82.85,0,0,1,45-45.08,83.42,83.42,0,0,1,65.65,0,82.76,82.76,0,0,1,45,45.08,83.13,83.13,0,0,1,6.72,32.81,81.93,81.93,0,0,1-14.88,48l41.17,41.23A14.72,14.72,0,0,1,200.32,184.57Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoRecord = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M102.3,1.26a98.87,98.87,0,0,0-101,98.79c-.25,54.68,44.26,99.1,98.88,99.1,54.11.62,99.14-44.28,99.12-98.56A99,99,0,0,0,102.3,1.26Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoRecordingStart = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <circle cx='100' cy='100' r='44' />
    <path d='M100,16a84,84,0,1,1-84,84,84.09,84.09,0,0,1,84-84m0-16A100,100,0,1,0,200,100,100,100,0,0,0,100,0Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoRecordingStop = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M100,56a44,44,0,0,0-36.3,68.87L125.24,64A43.77,43.77,0,0,0,100,56Z' />
    <path d='M136.47,75.37,75,136.2a44,44,0,0,0,61.47-60.83Z' />
    <path d='M100,16a84,84,0,1,1-84,84,84.09,84.09,0,0,1,84-84m0-16A100,100,0,1,0,200,100,100,100,0,0,0,100,0Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoRewind10 = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M12.39,88.87c.21-1,1.39-2.23,3-3.26a3.69,3.69,0,0,1,1.73-.31,11.5,11.5,0,0,1,2.54.32,1,1,0,0,1,.57.39l0,0a12.6,12.6,0,0,1,1.11,1A5.15,5.15,0,0,1,22.26,88a6.43,6.43,0,0,1,.4,4.43c-.58,2.54-1,4.88-1.41,7.53a102.56,102.56,0,0,0,.06,20.35A102,102,0,0,0,26.55,140,102.44,102.44,0,0,0,37,158.2a99.63,99.63,0,0,0,15.13,15A101.34,101.34,0,0,0,70.3,183.54,101.23,101.23,0,0,0,90,188.65a98.56,98.56,0,0,0,20.39-.05,102,102,0,0,0,19.7-5.23,100.36,100.36,0,0,0,18.14-10.43,102,102,0,0,0,15.06-15.12,100.88,100.88,0,0,0,10.31-18.22,101.37,101.37,0,0,0,5.12-19.72,102.74,102.74,0,0,0-.05-20.37,101.43,101.43,0,0,0-5.23-19.72A102.17,102.17,0,0,0,163,61.65a97.14,97.14,0,0,0-7-7.78,98.18,98.18,0,0,0-8.13-7.27,101.9,101.9,0,0,0-18.2-10.32,122.9,122.9,0,0,0-12.52-3.75A86.24,86.24,0,0,0,105.33,31V51.47a3.45,3.45,0,0,1-5.89,2.31l-24-24a3.36,3.36,0,0,1,0-4.84l24-24c1.89-2.21,5.89-.42,5.89,2.42V20.17c3.43.22,6.82.56,6.82.56,2.34.31,4.55.67,6.58,1.07,15.34,3,30.61,11,43,22.5.64.6,1.32,1.25,2,1.93a99.05,99.05,0,0,1,12.18,15.18A92.87,92.87,0,0,1,185,80.32,73.38,73.38,0,0,1,188.89,96c.81,6.16,1.55,13.41.89,20.51a89.18,89.18,0,0,1-12.3,39.43,93.65,93.65,0,0,1-30.22,30.69,92.34,92.34,0,0,1-19,8.83A93.49,93.49,0,0,1,108,199.55a71.07,71.07,0,0,1-16,0A89.4,89.4,0,0,1,36.39,173.7l-.88-.89a90.67,90.67,0,0,1-22.58-39.68A92.43,92.43,0,0,1,12.39,88.87Z' />
    <path d='M73.83,147c4,0,6.6-2.47,6.6-6.13V80.79c0-6.76-1.66-11-9.94-11l-12.79.28A6.45,6.45,0,0,0,58,83l9-.28h.25c0,.15,0,.36,0,.65v57.55C67.33,144.61,69.88,147,73.83,147Z' />
    <path d='M96.93,107.9c0,33,15.59,39.91,28.68,39.91,18.45,0,28.6-14.11,28.6-39.73,0-32.68-15.5-39.54-28.5-39.54C107.14,68.54,96.93,82.51,96.93,107.9Zm28.78-27.1c3.78,0,15.3,0,15.3,27.19,0,27.56-11.58,27.56-15.4,27.56s-15.49,0-15.49-27.56C110.12,90,115.37,80.8,125.71,80.8Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoRewind30 = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M-572,859.53c-35.37-1-62.91-14.6-83.59-41.4a3.22,3.22,0,0,1-.48-3.76c14-31.1,38.09-49.66,72.61-54.13,39.68-5.13,78.74,18,93.36,54.47a2.83,2.83,0,0,1-.41,3.16c-17,22.32-39.38,36-67.59,40.34-4.94.76-10,1-13.9,1.32' />
    <path d='M31.5,701.26a41.72,41.72,0,0,1-41.9,41.61,41.77,41.77,0,0,1-41.43-42.08c.1-22.72,19-41.34,41.9-41.26a41.8,41.8,0,0,1,41.43,41.73' />
    <path d='M96.93,107.9c0,33,15.59,39.91,28.68,39.91,18.45,0,28.6-14.11,28.6-39.73,0-32.68-15.5-39.54-28.5-39.54C107.14,68.54,96.93,82.51,96.93,107.9Zm28.78-27.1c3.78,0,15.3,0,15.3,27.19,0,27.56-11.58,27.56-15.4,27.56s-15.49,0-15.49-27.56C110.12,90,115.37,80.8,125.71,80.8Z' />
    <path d='M63.45,100.12a12.58,12.58,0,0,0,8.17-2.78,9.71,9.71,0,0,0,3.42-8,9.51,9.51,0,0,0-2.73-6.82A9.68,9.68,0,0,0,65,79.68a13.3,13.3,0,0,0-5.18.87,8.25,8.25,0,0,0-3.21,2.32,17.3,17.3,0,0,0-2.24,3.71c-.71,1.51-1.35,2.93-1.94,4.27a3.61,3.61,0,0,1-1.85,1.7,7.38,7.38,0,0,1-3.09.62,5.37,5.37,0,0,1-3.79-1.67A5.94,5.94,0,0,1,41.93,87a11.79,11.79,0,0,1,1.62-5.64,20.82,20.82,0,0,1,4.74-5.64A24,24,0,0,1,56,71.46a31.28,31.28,0,0,1,10.36-1.62,28.9,28.9,0,0,1,9.12,1.37,21.28,21.28,0,0,1,7.15,3.93,17,17,0,0,1,4.59,6,17.42,17.42,0,0,1,1.53,7.31,16.69,16.69,0,0,1-2.23,8.83,29.78,29.78,0,0,1-6.42,7.18,28.54,28.54,0,0,1,6.78,5,18.48,18.48,0,0,1,5.53,13.44,22.79,22.79,0,0,1-1.87,9.06,23.14,23.14,0,0,1-5.54,7.8,26.51,26.51,0,0,1-8.68,5.36A30.85,30.85,0,0,1,65.26,147a26.54,26.54,0,0,1-11.08-2.22,25.71,25.71,0,0,1-8.06-5.54,25.28,25.28,0,0,1-4.78-6.87,14.74,14.74,0,0,1-1.62-5.87,6.31,6.31,0,0,1,1.93-4.81,6.71,6.71,0,0,1,4.82-1.83,5.08,5.08,0,0,1,2.77.85,4.12,4.12,0,0,1,1.76,2q2.67,7.17,5.73,10.63c2.05,2.33,4.92,3.48,8.63,3.48a12.9,12.9,0,0,0,6.15-1.57,12.75,12.75,0,0,0,4.89-4.66,13.19,13.19,0,0,0,1.93-7.16q0-6-3.29-9.45t-9.17-3.42c-.69,0-1.75.07-3.19.21s-2.37.2-2.78.2a6.26,6.26,0,0,1-4.37-1.41A5.09,5.09,0,0,1,54,105.63a4.89,4.89,0,0,1,1.85-4,8.54,8.54,0,0,1,5.51-1.52Z' />
    <path d='M12.39,88.87c.21-1,1.39-2.23,3-3.26a3.69,3.69,0,0,1,1.73-.31,11.5,11.5,0,0,1,2.54.32,1,1,0,0,1,.57.39l0,0a12.6,12.6,0,0,1,1.11,1A5.15,5.15,0,0,1,22.26,88a6.43,6.43,0,0,1,.4,4.43c-.58,2.54-1,4.88-1.41,7.53a102.56,102.56,0,0,0,.06,20.35A102,102,0,0,0,26.55,140,102.44,102.44,0,0,0,37,158.2a99.63,99.63,0,0,0,15.13,15A101.34,101.34,0,0,0,70.3,183.54,101.23,101.23,0,0,0,90,188.65a98.56,98.56,0,0,0,20.39-.05,102,102,0,0,0,19.7-5.23,100.36,100.36,0,0,0,18.14-10.43,102,102,0,0,0,15.06-15.12,100.88,100.88,0,0,0,10.31-18.22,101.37,101.37,0,0,0,5.12-19.72,102.74,102.74,0,0,0-.05-20.37,101.43,101.43,0,0,0-5.23-19.72A102.17,102.17,0,0,0,163,61.65a97.14,97.14,0,0,0-7-7.78,98.18,98.18,0,0,0-8.13-7.27,101.9,101.9,0,0,0-18.2-10.32,122.9,122.9,0,0,0-12.52-3.75A86.24,86.24,0,0,0,105.33,31V51.47a3.45,3.45,0,0,1-5.89,2.31l-24-24a3.36,3.36,0,0,1,0-4.84l24-24c1.89-2.21,5.89-.42,5.89,2.42V20.17c3.43.22,6.82.56,6.82.56,2.34.31,4.55.67,6.58,1.07,15.34,3,30.61,11,43,22.5.64.6,1.32,1.25,2,1.93a99.05,99.05,0,0,1,12.18,15.18A92.87,92.87,0,0,1,185,80.32,73.38,73.38,0,0,1,188.89,96c.81,6.16,1.55,13.41.89,20.51a89.18,89.18,0,0,1-12.3,39.43,93.65,93.65,0,0,1-30.22,30.69,92.34,92.34,0,0,1-19,8.83A93.49,93.49,0,0,1,108,199.55a71.07,71.07,0,0,1-16,0A89.4,89.4,0,0,1,36.39,173.7l-.88-.89a90.67,90.67,0,0,1-22.58-39.68A92.43,92.43,0,0,1,12.39,88.87Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoRewind60 = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M101.34,107.9c0,32.99,15.6,39.92,28.69,39.92,18.44,0,28.59-14.11,28.59-39.73,0-32.68-15.5-39.54-28.5-39.54-18.56,0-28.78,13.98-28.78,39.36Zm28.78-27.1c3.78,0,15.31,0,15.31,27.19s-11.59,27.56-15.4,27.56-15.5,0-15.5-27.56c0-18.04,5.25-27.19,15.59-27.19Z' />
    <path d='M12.39,88.87c.22-.96,1.4-2.23,3.04-3.25,.17-.09,.68-.32,1.73-.32,.73,0,1.58,.11,2.54,.32,.24,.06,.45,.2,.58,.39h.01c.26,.19,.69,.51,1.11,.93,.56,.57,.83,1.02,.87,1.09,.62,1.09,.77,2.8,.39,4.43-.58,2.54-1.02,4.88-1.41,7.53-.66,6.7-.63,13.37,.07,20.35,1.07,6.68,2.78,13.14,5.23,19.7,2.81,6.34,6.24,12.3,10.43,18.16,2.26,2.78,4.55,5.32,6.99,7.75,2.54,2.54,5.14,4.86,8.14,7.28,5.65,4.03,11.61,7.41,18.19,10.31,6.34,2.35,12.82,4.03,19.73,5.11,7.16,.7,13.18,.66,20.39-.05,6.71-1.08,13.17-2.8,19.7-5.23,6.38-2.82,12.33-6.24,18.14-10.43,5.52-4.49,10.46-9.45,15.06-15.13,4.07-5.72,7.44-11.69,10.31-18.21,2.35-6.32,4.02-12.78,5.12-19.72,.66-6.75,.64-13.43-.05-20.37-1.06-6.66-2.78-13.13-5.23-19.71-2.83-6.38-6.26-12.33-10.43-18.15-2.26-2.78-4.55-5.33-7.01-7.77-2.49-2.5-5.08-4.82-8.13-7.27-5.67-4.04-11.64-7.42-18.2-10.32-4.16-1.55-8.28-2.66-12.52-3.76-2.56-.66-7.53-1.16-11.85-1.49v20.43c0,2.95-3.89,4.52-5.89,2.31l-23.98-23.98c-1.37-1.37-1.37-3.47,0-4.84L99.44,.99c1.89-2.21,5.89-.42,5.89,2.42V20.17c3.44,.23,6.83,.56,6.83,.56,2.34,.31,4.55,.67,6.58,1.08,15.34,3.04,30.61,11.02,43,22.5,.64,.6,1.31,1.25,2.01,1.93,4.22,4.24,8.31,9.34,12.17,15.18,3.29,4.99,6.18,11,9.12,18.91,1.82,4.89,3.11,10.16,3.85,15.66,.81,6.16,1.55,13.41,.89,20.51-.76,8.26-1.97,14.71-3.79,20.29-2.37,7.26-5.22,13.7-8.5,19.14-7.38,12.26-17.83,22.88-30.22,30.7-5.21,3.29-11.44,6.18-19.03,8.82-6.87,2.39-14.09,3.42-20.24,4.11-2.67,.3-5.37,.45-7.99,.45-2.62,0-5.31-.15-7.98-.45-6.23-.7-13.53-1.75-20.39-4.17-13.86-4.87-25.71-12.16-35.23-21.68l-.88-.89c-10.81-11.13-18.62-24.85-22.58-39.68-3.76-14.09-3.94-29.39-.54-44.27Z' />
    <path d='M71.05,95.55c-7.96,0-14.55,3.2-18.94,9.1,.57-8.06,1.6-11.7,3.44-15.99,2.94-6.89,8.08-10.53,14.82-10.53s10.53,2.49,13,8.35c.86,1.83,2.47,2.83,4.53,2.83,.91,0,1.78-.22,2.59-.45,2.18-.82,3.58-2.65,3.58-4.66,0-.86-.2-1.61-.58-2.29-4.42-9.29-11.86-13.81-22.74-13.81-18.95,0-29.39,14.65-29.39,41.23,0,24.62,10.54,38.74,28.91,38.74,15.73,0,26.3-10.8,26.3-26.89s-10.02-25.63-25.52-25.63Zm14.54,25.63c0,10.18-6.12,16.76-15.6,16.76s-15.98-6.93-15.98-16.47,6.84-16.08,16.28-16.08,15.31,6.2,15.31,15.79Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoSadFace = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M100,133.499167 C108.641667,133.499167 115.683333,140.5325 115.683333,149.1825 C115.683333,151.465833 113.825,153.324167 111.533333,153.324167 L88.4666667,153.324167 C86.175,153.324167 84.3166667,151.465833 84.3166667,149.1825 C84.3166667,140.5325 91.3583333,133.499167 100,133.499167 Z M69.5716667,103.429167 C69.5716667,109.3375 64.78,114.129167 58.8716667,114.129167 C52.9633333,114.129167 48.1716667,109.3375 48.1716667,103.429167 C48.1716667,97.5125 52.9633333,92.7291667 58.8716667,92.7291667 C64.78,92.7291667 69.5716667,97.5125 69.5716667,103.429167 Z M151.830833,103.429167 C151.830833,109.3375 147.039167,114.129167 141.130833,114.129167 C135.2225,114.129167 130.430833,109.3375 130.430833,103.429167 C130.430833,97.5125 135.2225,92.7291667 141.130833,92.7291667 C147.039167,92.7291667 151.830833,97.5125 151.830833,103.429167 Z M100,11.6083333 C51.2583333,11.6083333 11.6083333,51.2666667 11.6083333,100 C11.6083333,148.741667 51.2583333,188.4 100,188.4 C148.741667,188.4 188.391667,148.741667 188.391667,100 C188.391667,51.2666667 148.741667,11.6083333 100,11.6083333 M100,200 C44.8583333,200 0,155.15 0,100 C0,44.8583333 44.8583333,0 100,0 C155.141667,0 200,44.8583333 200,100 C200,155.15 155.141667,200 100,200' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoSeries = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M2.77,60.26,98.5,96.76a4.16,4.16,0,0,0,3,0l95.73-36.5a4.21,4.21,0,0,0,0-7.87L101.5,15.89a4.16,4.16,0,0,0-3,0L2.77,52.39a4.21,4.21,0,0,0,0,7.87Z' />
    <path d='M197.23,100.51l-23.4-8.93a4.3,4.3,0,0,0-3,0L104,117.05a11.26,11.26,0,0,1-8.08,0L29.17,91.58a4.3,4.3,0,0,0-3,0l-23.4,8.93a4.2,4.2,0,0,0,0,7.86L98.5,144.88a4.16,4.16,0,0,0,3,0l95.73-36.51a4.2,4.2,0,0,0,0-7.86Z' />
    <path d='M197.23,144.74l-23.4-8.92a4.16,4.16,0,0,0-3,0l-66.2,25.25a13.07,13.07,0,0,1-9.26,0l-66.2-25.25a4.16,4.16,0,0,0-3,0l-23.4,8.92a4.21,4.21,0,0,0,0,7.87l95.73,36.5a4.16,4.16,0,0,0,3,0l95.73-36.5a4.21,4.21,0,0,0,0-7.87Z' />
    <path d='M2.77,55.26,98.5,91.76a4.16,4.16,0,0,0,3,0l95.73-36.5a4.21,4.21,0,0,0,0-7.87L101.5,10.89a4.16,4.16,0,0,0-3,0L2.77,47.39a4.21,4.21,0,0,0,0,7.87Z' />
    <path d='M197.23,95.51l-23.4-8.93a4.3,4.3,0,0,0-3,0L104,112.05a11.26,11.26,0,0,1-8.08,0L29.17,86.58a4.3,4.3,0,0,0-3,0L2.77,95.51a4.2,4.2,0,0,0,0,7.86L98.5,139.88a4.16,4.16,0,0,0,3,0l95.73-36.51a4.2,4.2,0,0,0,0-7.86Z' />
    <path d='M197.23,139.74l-23.4-8.92a4.16,4.16,0,0,0-3,0l-66.2,25.25a13.07,13.07,0,0,1-9.26,0l-66.2-25.25a4.16,4.16,0,0,0-3,0l-23.4,8.92a4.21,4.21,0,0,0,0,7.87l95.73,36.5a4.16,4.16,0,0,0,3,0l95.73-36.5a4.21,4.21,0,0,0,0-7.87Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoShare = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M196.25,88.35,127.5,147.72a9.38,9.38,0,0,1-15.5-7.09V106.28c-60.58.88-86.69,15.91-69,72.56,2,6.29-5.63,11.16-11,7.28C15,173.69-.5,149.91-.5,125.91c0-59.46,49.75-72,112.5-72.76V21.88a9.38,9.38,0,0,1,15.5-7.09l68.75,59.36A9.39,9.39,0,0,1,196.25,88.35Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoTrailer = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M87.09,99.92c0-5.4,0-10.8,0-16.19,0-2.5.72-4,3.22-4.71a4.5,4.5,0,0,1,3.89,1c6.21,4.24,12.38,8.53,18.56,12.81,1.84,1.27,3.63,2.61,5.5,3.84a4.14,4.14,0,0,1,0,6.67c-3.61,2.42-7.15,5-10.73,7.43-4.48,3.1-8.94,6.22-13.45,9.28-2.49,1.7-5,1.3-6.32-1a6.29,6.29,0,0,1-.62-2.82c-.06-5.43,0-10.87,0-16.31Z' />
    <path d='M200,41.09V12.48a6.19,6.19,0,0,0-6.19-6.18H6.19A6.19,6.19,0,0,0,0,12.48V41.09H17.23V158.91H0v28.61a6.19,6.19,0,0,0,6.19,6.18H193.81a6.19,6.19,0,0,0,6.19-6.18V158.91H182.77V41.09ZM135.46,17h13.4v13.4h-13.4Zm-28.11,0h13.41v13.4H107.35ZM79.24,17H92.65v13.4H79.24Zm-28.1,0h13.4v13.4H51.14ZM36.43,183H23v-13.4h13.4Zm0-152.62H23V17h13.4ZM64.54,183H51.14v-13.4h13.4Zm28.11,0H79.24v-13.4H92.65Zm28.11,0H107.35v-13.4h13.41Zm28.1,0h-13.4v-13.4h13.4Zm8.91-24.1H42.23V41.09H157.77ZM177,183h-13.4v-13.4H177Zm0-152.62h-13.4V17H177Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoTwitter = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='m116.47,84.69L189.32,0h-17.26l-63.26,73.53L58.27,0H0l76.4,111.19L0,200h17.26l66.8-77.65,53.36,77.65h58.27l-79.24-115.31h0Zm-23.65,27.49l-7.74-11.07L23.49,13h26.52l49.71,71.1,7.74,11.07,64.61,92.42h-26.52l-52.73-75.42h0Z' />{' '}
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoUser = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='m100,86.7c23.9,0 43.3,-19.4 43.3,-43.3c0,-23.9 -19.4,-43.3 -43.3,-43.3c-23.9,0 -43.3,19.4 -43.3,43.3c0,23.9 19.4,43.3 43.3,43.3' />
    <path d='m100,100c-57,0 -90,44.7 -90,100l180,0c0,-55.2 -32.9,-100 -90,-100' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoVisuallyImpaired = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <g transform='translate(-0.006559, 50.493200)'>
      <path d='M67.2962591,66.0884591 L52.5036578,29.5515527 L37.7083694,66.0884591 L67.2962591,66.0884591 Z M74.6939033,84.2854406 L30.3134124,84.2854406 L23.9314999,100.006532 L0,100.006532 L40.6104602,0 L64.5392729,0 L105.149733,100.006532 L81.0731287,100.006532 L74.6939033,84.2854406 Z' />
      <path d='M145.467566,80.4998643 C164.468199,80.4998643 177.954859,67.8345279 177.954859,49.9315246 C177.954859,32.1714649 164.468199,19.5061285 145.467566,19.5061285 L128.353292,19.5061285 L128.353292,80.4998643 L145.467566,80.4998643 Z M107.033674,0.00107881912 L142.713267,0.00107881912 C176.213605,0.00107881912 200,20.6712531 200,49.9315246 C200,79.1890991 176.213605,100.004914 142.713267,100.004914 L107.033674,100.004914 L107.033674,0.00107881912 Z' />
    </g>
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoVolume = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M106.16,29.91V169.23a10,10,0,0,1-17,7l-36.9-36.89H10A10,10,0,0,1,0,129.43V69.71a10,10,0,0,1,10-9.95H52.27l36.9-36.89A10,10,0,0,1,106.16,29.91Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoVolumeDown = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M164,96.68a40.75,40.75,0,0,0-19.63-35A13.67,13.67,0,0,0,130.13,85a13.57,13.57,0,0,1,6.56,11.67,13.72,13.72,0,0,1-5.9,11.25,13.67,13.67,0,1,0,15.58,22.47A41.06,41.06,0,0,0,164,96.68Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoVolumeUp = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M199.05,96.68a79.59,79.59,0,0,0-38.14-68,13.27,13.27,0,1,0-13.84,22.64,53.1,53.1,0,0,1,2.57,89,13.27,13.27,0,0,0,15.12,21.81A79.73,79.73,0,0,0,199.05,96.68Zm-39.81,0a39.56,39.56,0,0,0-19.07-34,13.27,13.27,0,0,0-13.84,22.65A13.27,13.27,0,0,1,127,107.6a13.27,13.27,0,1,0,15.12,21.81A39.84,39.84,0,0,0,159.24,96.68Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoVolumeMute = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M173,94.22l24.08-24.08a8.7,8.7,0,0,0,0-12.26l-1-1a8.7,8.7,0,0,0-12.26,0L159.71,80.94,135.63,56.85a8.7,8.7,0,0,0-12.26,0l-1,1a8.7,8.7,0,0,0,0,12.26l24.09,24.08L122.34,118.3a8.7,8.7,0,0,0,0,12.26l1,1a8.7,8.7,0,0,0,12.26,0l24.08-24.08,24.08,24.08a8.7,8.7,0,0,0,12.26,0l1-1a8.7,8.7,0,0,0,0-12.26Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoX = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M28.89,47.86c-6.14-6.13-5.39-13.24,1-19.61,6.13-6.13,13.47-7.11,19.61-1L101,78.74l49.27-49.26c6.37-6.38,13.48-5.64,19.6.48,6.38,6.38,7.36,13.73,1,20.1L121.54,99.33l50.74,50.74c6.36,6.37,5.14,13.47-1,19.6-6.37,6.37-13.24,7.35-19.6,1L101,119.92,49.72,171.15c-6.37,6.37-13.72,5.4-20.1-1-6.12-6.12-6.86-13.23-.49-19.6L80.36,99.33Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoYouTube = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M196,51.65q2.57,9.89,3.66,30L200,100l-.37,18.32q-1.09,20.51-3.66,30.4A26,26,0,0,1,178,166.3q-9.53,2.56-44.69,3.66l-33.33.37L66.67,170Q31.49,168.86,22,166.3A26,26,0,0,1,4,148.72q-2.57-9.9-3.66-30.4L0,100q0-8.06.37-18.32,1.1-20.15,3.66-30A25.57,25.57,0,0,1,22,33.7Q31.5,31.14,66.67,30L100,29.67l33.33.37Q168.51,31.14,178,33.7A25.57,25.57,0,0,1,196,51.65ZM79.49,130l52.38-30L79.49,70.33Z' />
  </Picto>
));

// $FlowFixMe: don't know how to annotate this export
const PictoWarning = React.forwardRef(({ ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => (
  <Picto {...delegated} ref={ref}>
    <path d='M99,13.15.84,186.05a1,1,0,0,0,.87,1.49l196.57.83a1,1,0,0,0,.87-1.49L100.76,13.15A1,1,0,0,0,99,13.15Z' />
    <path d='M91.39,141a5.37,5.37,0,0,1,3.15-2.73,22,22,0,0,1,6.59-.72c3,0,5.16.66,6.5,2s2,3.37,2,6.18v1.65a31.58,31.58,0,0,1-.16,3.66,9.23,9.23,0,0,1-1,2.84q-1.65,3-7.88,3t-8.25-2q-2-2-2-7.78A13.79,13.79,0,0,1,91.39,141Zm13.81-12a21.55,21.55,0,0,1-5.25.51,21.7,21.7,0,0,1-5.26-.51A4.18,4.18,0,0,1,92,127.1a7.21,7.21,0,0,1-.93-2.37,24.31,24.31,0,0,1-.15-3.19V88.87A25.93,25.93,0,0,1,91,85.62a6.68,6.68,0,0,1,1-2.42,4.57,4.57,0,0,1,2.79-1.9,19.38,19.38,0,0,1,5.25-.57,19.51,19.51,0,0,1,5.26.57,4.31,4.31,0,0,1,2.73,2,7.76,7.76,0,0,1,.93,2.48,25.64,25.64,0,0,1,.15,3.24v32.67a24.6,24.6,0,0,1-.15,3.2,6.2,6.2,0,0,1-1,2.31A4.43,4.43,0,0,1,105.2,129Z' />
  </Picto>
));

/* eslint-enable react/jsx-props-no-spreading */

PictoAndroid.displayName = 'PictoAndroid';
PictoApple.displayName = 'PictoApple';
PictoArrowDown.displayName = 'PictoArrowDown';
PictoArrowLeft.displayName = 'PictoArrowLeft';
PictoArrowLeftWithShaft.displayName = 'PictoArrowLeftWithShaft';
PictoArrowRight.displayName = 'PictoArrowRight';
PictoArrowUp.displayName = 'PictoArrowUp';
PictoBack.displayName = 'PictoBack';
PictoBackToLive.displayName = 'PictoBackToLive';
PictoBigPlay.displayName = 'PictoBigPlay';
PictoBurger.displayName = 'PictoBurger';
PictoBuy.displayName = 'PictoBuy';
PictoCart.displayName = 'PictoCart';
PictoCheck.displayName = 'PictoCheck';
PictoClock.displayName = 'PictoClock';
PictoClockEmpty.displayName = 'PictoClockEmpty';
PictoCog.displayName = 'PictoCog';
PictoCompress.displayName = 'PictoCompress';
PictoDot.displayName = 'PictoDot';
PictoDownload.displayName = 'PictoDownload';
PictoExpand.displayName = 'PictoExpand';
PictoExternal.displayName = 'PictoExternal';
PictoEye.displayName = 'PictoEye';
PictoFacebook.displayName = 'PictoFacebook';
PictoFailure.displayName = 'PictoFailure';
PictoForward10.displayName = 'PictoForward10';
PictoForward30.displayName = 'PictoForward30';
PictoForward60.displayName = 'PictoForward60';
PictoFullscreen.displayName = 'PictoFullscreen';
PictoGem.displayName = 'PictoGem';
PictoHearingImpaired.displayName = 'PictoHearingImpaired';
PictoHeartEmpty.displayName = 'PictoHeartEmpty';
PictoHeartFull.displayName = 'PictoHeartFull';
PictoInfo.displayName = 'PictoInfo';
PictoInstagram.displayName = 'PictoInstagram';
PictoLeaf.displayName = 'PictoLeaf';
PictoLogOut.displayName = 'PictoLogOut';
PictoMagnifier.displayName = 'PictoMagnifier';
PictoMinus.displayName = 'PictoMinus';
PictoMoreInfo.displayName = 'PictoMoreInfo';
PictoNext.displayName = 'PictoNext';
PictoNoContent.displayName = 'PictoNoContent';
PictoPause.displayName = 'PictoPause';
PictoPlay.displayName = 'PictoPlay';
PictoPrevious.displayName = 'PictoPrevious';
PictoQuestionMark.displayName = 'PictoQuestionMark';
PictoReplay.displayName = 'PictoReplay';
PictoRecord.displayName = 'PictoRecord';
PictoRecordingStart.displayName = 'PictoRecordingStart';
PictoRecordingStop.displayName = 'PictoRecordingStop';
PictoRewind10.displayName = 'PictoRewind10';
PictoRewind30.displayName = 'PictoRewind30';
PictoRewind60.displayName = 'PictoRewind60';
PictoSadFace.displayName = 'PictoSadFace';
PictoSeries.displayName = 'PictoSeries';
PictoShare.displayName = 'PictoShare';
PictoTrailer.displayName = 'PictoTrailer';
PictoTwitter.displayName = 'PictoTwitter';
PictoUser.displayName = 'PictoUser';
PictoVisuallyImpaired.displayName = 'PictoVisuallyImpaired';
PictoVolume.displayName = 'PictoVolume';
PictoVolumeDown.displayName = 'PictoVolumeDown';
PictoVolumeMute.displayName = 'PictoVolumeMute';
PictoVolumeUp.displayName = 'PictoVolumeUp';
PictoX.displayName = 'PictoX';
PictoYouTube.displayName = 'PictoYouTube';
PictoWarning.displayName = 'PictoWarning';

export {
  PictoAndroid,
  PictoApple,
  PictoArrowDown,
  PictoArrowLeft,
  PictoArrowLeftWithShaft,
  PictoArrowRight,
  PictoArrowUp,
  PictoBack,
  PictoBackToLive,
  PictoBigPlay,
  PictoBurger,
  PictoBuy,
  PictoCart,
  PictoCheck,
  PictoClock,
  PictoClockEmpty,
  PictoCog,
  PictoCompress,
  PictoDot,
  PictoDownload,
  PictoExpand,
  PictoExternal,
  PictoEye,
  PictoFacebook,
  PictoFailure,
  PictoForward10,
  PictoForward30,
  PictoForward60,
  PictoFullscreen,
  PictoGem,
  PictoHearingImpaired,
  PictoHeartEmpty,
  PictoHeartFull,
  PictoInfo,
  PictoInstagram,
  PictoLeaf,
  PictoLogOut,
  PictoMagnifier,
  PictoMinus,
  PictoMoreInfo,
  PictoNext,
  PictoNoContent,
  PictoPause,
  PictoPlay,
  PictoPrevious,
  PictoQuestionMark,
  PictoReplay,
  PictoRecord,
  PictoRecordingStart,
  PictoRecordingStop,
  PictoRewind10,
  PictoRewind30,
  PictoRewind60,
  PictoSadFace,
  PictoSeries,
  PictoShare,
  PictoTrailer,
  PictoTwitter,
  PictoUser,
  PictoVisuallyImpaired,
  PictoVolume,
  PictoVolumeDown,
  PictoVolumeMute,
  PictoVolumeUp,
  PictoX,
  PictoYouTube,
  PictoWarning,
};
