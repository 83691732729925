/* @flow */

import './MultipleChoices.css';
import * as React from 'react';
import ResizeDetectorWrapper, { type ResizeType } from '../resizeDetectorWrapper/ResizeDetectorWrapper';
import { Setting } from '../../helpers/settings/types';
import clsx from 'clsx';

export enum LabelWidth {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

type ChoicesType = $ReadOnlyArray<{| text: string, value: any |}>;

type DefaultProps = {|
  +isDisabled?: boolean,
  +setting?: Setting,
  +requestedLabelWidth?: LabelWidth,
|};

type MultipleChoicesPropType = {|
  ...DefaultProps,
  +choices: ChoicesType,
  +onSelectionChanged: (value: any, setting?: Setting, index: number) => void,
  +selectedIndex: number,
|};

class MultipleChoices extends React.PureComponent<MultipleChoicesPropType> {
  labels: HTMLElement | null;

  labelWidth: number;

  static defaultProps: DefaultProps = {
    isDisabled: false,
    requestedLabelWidth: LabelWidth.Large,
    setting: undefined,
  };

  constructor(...args: Array<any>) {
    super(...args);

    this.labels = null;
    this.labelWidth = 0;
  }

  componentDidMount() {
    const {
      choices: { length },
    } = this.props;
    const { labels } = this;

    if (!labels) {
      return;
    }

    labels.style.setProperty('--choice-count', length.toString());
    this.labelWidth = labels.offsetWidth / length;
    const height = labels.offsetHeight;
    labels.style.setProperty('--label-width', `${this.labelWidth}px`);
    labels.style.setProperty('--label-height', `${height}px`);

    this.updateSelectionBackground();
  }

  componentDidUpdate(prevProps: MultipleChoicesPropType) {
    const { selectedIndex } = this.props;
    const { selectedIndex: prevSelectedIndex } = prevProps;

    if (selectedIndex !== prevSelectedIndex) {
      this.updateSelectionBackground();
    }
  }

  handleLabelsOnResize = (data: ResizeType): void => {
    const {
      choices: { length },
    } = this.props;
    const { labels } = this;
    const { height, width } = data;

    if (!labels || width === null || height === null) {
      // Component not mounted or no label
      return;
    }

    this.labelWidth = width / length;

    labels.style.setProperty('--label-width', `${this.labelWidth}px`);
    labels.style.setProperty('--label-height', `${height}px`);

    this.updateSelectionBackground();
  };

  updateSelectionBackground = (): void => {
    const { selectedIndex } = this.props;
    const { labels, labelWidth } = this;

    if (!labels) {
      return;
    }

    labels.style.setProperty('--offset-x', `${selectedIndex * labelWidth}px`);
  };

  handleOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    const {
      currentTarget: {
        dataset: { index: indexStr },
      },
    } = event;
    const index = parseInt(indexStr, 10);
    const {
      choices: {
        [index]: { value },
      },
      isDisabled,
      onSelectionChanged,
      setting,
    } = this.props;

    if (isDisabled) {
      return;
    }

    onSelectionChanged(value, setting, index);
  };

  render(): React.Node {
    const { choices, isDisabled, requestedLabelWidth = LabelWidth.Large, selectedIndex } = this.props;

    return (
      <div className={clsx('multipleChoices', isDisabled && 'disabled')}>
        <div
          className='labels'
          ref={(instance) => {
            this.labels = instance;
          }}
        >
          {choices.map((choice, index) => (
            <div className={clsx('label', (requestedLabelWidth: string), index === selectedIndex && 'selected')} data-index={index} key={choice.text} onClick={this.handleOnClick}>
              {choice.text}
            </div>
          ))}
        </div>
        <div className='disabledLayer' />
        <ResizeDetectorWrapper handleHeight handleWidth onResize={this.handleLabelsOnResize} />
      </div>
    );
  }
}

export default (MultipleChoices: React.ComponentType<MultipleChoicesPropType>);
