/* @flow */

import { type AllSettledPromises, SettledPromiseRejected } from '../../../../helpers/jsHelpers/promise';
import { MILLISECONDS_PER_WEEK, MIN_DATE } from '../../../../helpers/dateTime/Format';
import type { NETGEM_API_V8_FEED, NETGEM_API_V8_ITEM_LOCATION_TYPE } from '../../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_VIEWINGHISTORY, NETGEM_API_VIEWINGHISTORY_ITEM, NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS, VIEWING_HISTORY_TYPE } from '../../../../libs/netgemLibrary/v8/types/ViewingHistory';
import { arePlayedItemsDifferent, areViewingHistoriesDifferent } from '../../../../helpers/ui/section/comparisons';
import { deleteViewingHistoryItem, deleteViewingHistoryItemETag, resetViewingHistory, setViewingHistoryError, updateViewingHistoryItem, updateWholeViewingHistory } from '../../../ui/actions';
import { logInfo, logWarning } from '../../../../helpers/debug/debug';
import AccurateTimestamp from '../../../../helpers/dateTime/AccurateTimestamp';
import type { CombinedReducers } from '../../../reducers';
import { CustomNetworkError } from '../../../../libs/netgemLibrary/helpers/CustomNetworkError';
import type { Dispatch } from '../../../types/types';
import { HttpStatus } from '../../../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import LocalStorageManager from '../../../../helpers/localStorage/localStorageManager';
import { Localizer } from '@ntg/utils/dist/localization';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { PersonalDataKey } from '../../../../libs/netgemLibrary/personalData/constants/keys';
import type { RequestResponseMethodDefinitionType } from '../emitter';
import { StorageKeys } from '../../../../helpers/localStorage/keys';
import { getAllLocationIds } from '../../../../libs/netgemLibrary/v8/helpers/Feed';
import { getLocationType } from '../../../../libs/netgemLibrary/v8/helpers/Item';
import { isUndefinedOrNull } from '@ntg/utils/dist/types';
import { sendPersonalDataListDeleteRequest } from './delete';
import { sendPersonalDataListGetRequest } from './get';
import { sendPersonalDataListPostRequest } from './post';

// Clean up viewing history once a week (in ms)
const VIEWING_HISTORY_CLEAN_UP_INTERVAL = MILLISECONDS_PER_WEEK;
const ONE_HUNDRED = 100;

const getViewingHistory: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      ui: {
        viewingHistoryETagCache: { viewingHistory: initialETag },
      },
    } = getState();

    return dispatch(sendPersonalDataListGetRequest(PersonalDataKey.ViewingHistory, undefined, initialETag, signal))
      .then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { eTag: viewingHistoryETag } = data;
        const result = JSON.parse((data.result: string));

        // Filter out malformed items
        const viewingHistory: NETGEM_API_VIEWINGHISTORY = validateViewingHistory((result: NETGEM_API_VIEWINGHISTORY));

        dispatch(updateWholeViewingHistory(viewingHistory, viewingHistoryETag));
        return Promise.resolve();
      })
      .catch((error: CustomNetworkError) => {
        const status = error.getStatus();
        if (status === HttpStatus.NotFound) {
          // Not found means viewing history does not exist yet
          dispatch(resetViewingHistory());
          return Promise.resolve();
        } else if (status === HttpStatus.NotModified) {
          return Promise.resolve();
        }

        dispatch(setViewingHistoryError());
        return Promise.reject(error);
      });
  };

const validateViewingHistory: (viewingHistory: NETGEM_API_VIEWINGHISTORY) => NETGEM_API_VIEWINGHISTORY = (viewingHistory) => {
  if (isUndefinedOrNull(viewingHistory)) {
    return {};
  }

  const validatedViewingHistory: NETGEM_API_VIEWINGHISTORY = {};

  Object.entries(viewingHistory).forEach(([key, value]) => {
    if (typeof key === 'string' && key !== '') {
      const viewingHistoryItem = ((value: any): NETGEM_API_VIEWINGHISTORY_ITEM);
      const { date, id, watchingstatus } = viewingHistoryItem;

      if (typeof date === 'string' && typeof id === 'string') {
        if (typeof watchingstatus !== 'number' || watchingstatus < 0 || watchingstatus > ONE_HUNDRED) {
          viewingHistoryItem.watchingstatus = 0;
        }
        validatedViewingHistory[key] = viewingHistoryItem;
      }
    }
  });

  return validatedViewingHistory;
};

const updateViewingHistory: (item: NETGEM_API_VIEWINGHISTORY_ITEM, signal?: AbortSignal, isSecondAttempt?: boolean) => RequestResponseMethodDefinitionType =
  (item, signal, isSecondAttempt) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      ui: { viewingHistoryETagCache, viewingHistory },
    } = getState();
    const { id: itemId } = item;
    const { [itemId]: existingItem } = viewingHistory || {};
    const { [itemId]: existingItemETag } = viewingHistoryETagCache;

    if (isSecondAttempt || (existingItem && !existingItemETag)) {
      // Item is already in viewing history but ETag is missing (i.e. item was saved during a previous session): a GET is required to retrieve the associated ETag
      return dispatch(sendPersonalDataListGetRequest(PersonalDataKey.ViewingHistory, itemId, existingItemETag, signal)).then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { eTag } = data;
        return dispatch(updateViewingHistoryWithETag(item, eTag, signal, isSecondAttempt));
      });
    }

    // Item and associated ETag exist (i.e. item saved during current session) or none of them exists (i.e. item never saved)
    return dispatch(updateViewingHistoryWithETag(item, existingItemETag, signal, isSecondAttempt));
  };

const updateViewingHistoryWithETag: (item: NETGEM_API_VIEWINGHISTORY_ITEM, eTag: ?string, signal?: AbortSignal, isSecondAttempt?: boolean) => RequestResponseMethodDefinitionType =
  (item, eTag, signal, isSecondAttempt) =>
  (dispatch: Dispatch): Promise<any> => {
    const { id: itemId } = item;

    return dispatch(sendPersonalDataListPostRequest(PersonalDataKey.ViewingHistory, itemId, item, eTag, signal))
      .then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { eTag: itemETag } = data;

        if (itemETag) {
          dispatch(updateViewingHistoryItem(item, itemETag));
        }

        return Promise.resolve();
      })
      .catch((error: CustomNetworkError) => {
        const status = error.getStatus();
        if (!isSecondAttempt && (status === HttpStatus.Conflict || status === HttpStatus.PreconditionFailed)) {
          /*
           * Most probable cause: another device paired with the same account already updated the viewing history
           * So we send a GET request to retrieve the latest ETag, but we only try once to avoid infinite loop between multiple devices
           */
          dispatch(deleteViewingHistoryItemETag(itemId));
          return dispatch(updateViewingHistory(item, signal, true));
        }

        return Promise.reject(error);
      });
  };

const deleteWholeViewingHistory: (signal?: AbortSignal) => RequestResponseMethodDefinitionType = (signal) => deleteViewingHistory(null, signal);

const deleteViewingHistory: (itemId: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (itemId, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      ui: {
        viewingHistoryETagCache,
        viewingHistoryETagCache: { viewingHistory: initialListETag },
      },
    } = getState();
    const initialItemETag = itemId ? viewingHistoryETagCache[itemId] : null;
    const initialETag = itemId ? initialItemETag : initialListETag;

    // Get ETag
    return dispatch(sendPersonalDataListGetRequest(PersonalDataKey.ViewingHistory, itemId, initialETag, signal))
      .then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        // Delete
        const { eTag } = data;
        if (eTag) {
          return dispatch(deleteViewingHistoryWithETag(itemId, eTag, signal));
        }
        return Promise.resolve();
      })
      .catch((eTagError: CustomNetworkError) => {
        const status = eTagError.getStatus();
        if (status === HttpStatus.NotFound) {
          // Viewing history not found for this user: nothing to delete
          return Promise.resolve();
        } else if (status === HttpStatus.NotModified) {
          // Viewing history has not recently changed: current ETag should be used
          return dispatch(deleteViewingHistoryWithETag(itemId, initialETag, signal));
        }

        return Promise.reject(eTagError);
      });
  };

const deleteViewingHistoryWithETag: (itemId: ?string, eTag: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (itemId, eTag, signal) =>
  (dispatch: Dispatch): Promise<any> =>
    dispatch(sendPersonalDataListDeleteRequest(PersonalDataKey.ViewingHistory, itemId, eTag, signal)).then(() => {
      if (itemId) {
        // Delete item from list
        dispatch(deleteViewingHistoryItem(itemId));
        return Promise.resolve();
      }

      // Clear whole list
      dispatch(resetViewingHistory());
      return Promise.resolve();
    });

const checkPlayedItems: (item: NETGEM_API_VIEWINGHISTORY_ITEM, allLocationIds: Set<string>, allowedTypes: Set<NETGEM_API_V8_ITEM_LOCATION_TYPE>) => NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS | null = (
  item,
  allLocationIds,
  allowedTypes,
) => {
  const { playeditems } = item;

  if (!playeditems) {
    return null;
  }

  const updatedPlayedItems: NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS = {};
  let hasBeenUpdated = false;
  Object.keys(playeditems).forEach((locationId) => {
    const value = playeditems[locationId];
    const locationType = getLocationType(locationId);

    if (allLocationIds.has(locationId) || !locationType || !allowedTypes.has(locationType)) {
      updatedPlayedItems[locationId] = { ...value };
    } else {
      hasBeenUpdated = true;
    }
  });

  return hasBeenUpdated ? updatedPlayedItems : playeditems;
};

const cleanUpViewingHistory: (viewingHistory: VIEWING_HISTORY_TYPE, feed: NETGEM_API_V8_FEED, locationTypes: Set<NETGEM_API_V8_ITEM_LOCATION_TYPE>) => RequestResponseMethodDefinitionType =
  (viewingHistory, feed, locationTypes) =>
  (dispatch: Dispatch): Promise<any> => {
    const now = AccurateTimestamp.now();
    const lastViewingHistoryCleanUpTimestamp = LocalStorageManager.loadIsoDate(StorageKeys.LastViewingHistoryCleanUp, MIN_DATE).getTime();

    if (now < lastViewingHistoryCleanUpTimestamp + VIEWING_HISTORY_CLEAN_UP_INTERVAL) {
      // Last clean up is too recent
      return Promise.resolve();
    }

    LocalStorageManager.save(StorageKeys.LastViewingHistoryCleanUp, AccurateTimestamp.nowAsDate());

    const promises = [];

    const allLocationIds = getAllLocationIds(feed);
    const updatedViewingHistory: VIEWING_HISTORY_TYPE = [];
    const obsoleteItemIds = new Set<string>();

    // Check items
    viewingHistory.forEach((item) => {
      const { episodes, id: itemId, playeditems } = item;

      if (episodes) {
        // Series
        const updatedEpisodes: VIEWING_HISTORY_TYPE = [];
        episodes.forEach((episode) => {
          const updatedPlayedItems = checkPlayedItems(episode, allLocationIds, locationTypes);
          if (updatedPlayedItems && Object.keys(updatedPlayedItems).length > 0) {
            updatedEpisodes.push({
              ...episode,
              playeditems: updatedPlayedItems,
            });
          }
        });

        if (updatedEpisodes.length === 0) {
          obsoleteItemIds.add(itemId);
        } else if (areViewingHistoriesDifferent(updatedEpisodes, episodes)) {
          updatedViewingHistory.push({
            ...item,
            episodes: updatedEpisodes,
          });
        }
      } else {
        // Program
        const updatedPlayedItems = checkPlayedItems(item, allLocationIds, locationTypes);
        if (updatedPlayedItems === null || Object.keys(updatedPlayedItems).length === 0) {
          obsoleteItemIds.add(itemId);
        } else if (arePlayedItemsDifferent(updatedPlayedItems, playeditems)) {
          updatedViewingHistory.push({
            ...item,
            playeditems: updatedPlayedItems,
          });
        }
      }
    });

    obsoleteItemIds.forEach((id) => promises.push(dispatch(deleteViewingHistory(id))));
    updatedViewingHistory.forEach((item: NETGEM_API_VIEWINGHISTORY_ITEM) => promises.push(dispatch(updateViewingHistory(item))));

    if (promises.length === 0) {
      logInfo(Localizer.localize('viewing_history.cleanup.nothing_to_do'));
      return Promise.resolve();
    }

    logInfo(Localizer.localize('viewing_history.cleanup.issues', { deleteCount: obsoleteItemIds.size, updateCount: updatedViewingHistory.length }));

    return Promise.allSettled(promises).then((results: AllSettledPromises) => {
      const count = results.filter(({ status }) => status === SettledPromiseRejected).length;

      if (count > 0) {
        logWarning(Localizer.localize('viewing_history.cleanup.failure', { count }));
      } else {
        logInfo(Localizer.localize('viewing_history.cleanup.success', { count: obsoleteItemIds.size }));
      }
    });
  };

export { cleanUpViewingHistory, deleteViewingHistory, deleteWholeViewingHistory, getViewingHistory, updateViewingHistory };
