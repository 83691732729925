/* @flow */

import {
  type NETGEM_API_V8_RECORDINGS_FUTURE,
  type NETGEM_API_V8_RECORDINGS_STATUS,
  type NETGEM_API_V8_SCHEDULED_RECORDING,
  type NETGEM_FUTURE_RECORDINGS_STATUS,
  type NETGEM_RECORDINGS_MAP,
  type NETGEM_RECORDINGS_STATUS_ITEM,
  type NETGEM_SCHEDULED_RECORDINGS_MAP,
  type NETGEM_SCHEDULED_RECORDINGS_MAP_AND_ETAGS,
} from '../types/Npvr';
import type { ETAG_MAP } from '../types/ETag';
import type { NETGEM_API_V8_RAW_FEED } from '../types/FeedResult';
import { getIso8601DurationInSeconds } from '../../../../helpers/dateTime/Format';

const getRecordingsMap: (feed: NETGEM_API_V8_RAW_FEED) => NETGEM_RECORDINGS_MAP = (feed) => {
  const m: NETGEM_RECORDINGS_MAP = {};
  const q: NETGEM_API_V8_RAW_FEED = [...feed];

  while (q.length > 0) {
    const item = q.pop();

    // Since flowjs v0.239.0, "item" is said to be potentially undefined (which is impossible, here)
    if (!item) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { elements } = item;

    if (elements) {
      elements.forEach((elt) => q.unshift(elt));
    } else {
      const { id, locations } = item;
      if (locations && locations.length > 0) {
        m[id] = locations;
      }
    }
  }

  return m;
};

const getScheduledRecordingsMap: (scheduledRecordingList: Array<{| eTag: string, scheduledRecording: NETGEM_API_V8_SCHEDULED_RECORDING |}>) => NETGEM_SCHEDULED_RECORDINGS_MAP_AND_ETAGS = (
  scheduledRecordingList,
) => {
  const eTags: ETAG_MAP = {};
  const scheduledRecordings: NETGEM_SCHEDULED_RECORDINGS_MAP = {};

  scheduledRecordingList.forEach((obj) => {
    const { eTag, scheduledRecording } = obj;
    const { description } = scheduledRecording;
    const { requestedScheduledEventId, seriesId } = (description: any);

    const targetAssetId = seriesId || requestedScheduledEventId;
    if (targetAssetId) {
      const { id: scheduledRecordingId } = scheduledRecording;
      eTags[scheduledRecordingId] = eTag;
      scheduledRecordings[targetAssetId] = scheduledRecording;
    }
  });

  return {
    eTags,
    scheduledRecordings,
  };
};

const getScheduledRecordingsMapV2: (scheduledRecordingListFull: Array<NETGEM_API_V8_SCHEDULED_RECORDING>) => NETGEM_SCHEDULED_RECORDINGS_MAP = (scheduledRecordingListFull) => {
  const scheduledRecordings: NETGEM_SCHEDULED_RECORDINGS_MAP = {};

  scheduledRecordingListFull.forEach((scheduledRecording) => {
    const {
      description: { requestedScheduledEventId, seriesId },
    } = scheduledRecording;
    const targetAssetId = seriesId ?? requestedScheduledEventId;

    if (targetAssetId) {
      scheduledRecordings[targetAssetId] = scheduledRecording;
    }
  });

  return scheduledRecordings;
};

const getCountAndDuration: (statusItem: NETGEM_API_V8_RECORDINGS_STATUS) => NETGEM_RECORDINGS_STATUS_ITEM = (statusItem) => {
  const { count, duration } = statusItem;

  return {
    count,
    duration: getIso8601DurationInSeconds(duration),
  };
};

const getRecordingsStatus: (future: NETGEM_API_V8_RECORDINGS_FUTURE) => NETGEM_FUTURE_RECORDINGS_STATUS = (future) => {
  const { current, expectedError, expectedSuccess, maxQuota } = future;

  return {
    current: getCountAndDuration(current),
    expectedError: getCountAndDuration(expectedError),
    expectedSuccess: getCountAndDuration(expectedSuccess),
    maxQuota: getCountAndDuration(maxQuota),
  };
};

export { getRecordingsMap, getRecordingsStatus, getScheduledRecordingsMap, getScheduledRecordingsMapV2 };
