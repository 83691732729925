/* @flow */

import type { InternalActionDefinitionType, NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import {
  REDUX_MSG_CNC_PROGRAM_GET_URL,
  REDUX_MSG_CNC_SERIES_GET_URL,
  REDUX_MSG_DATA_COLLECTION_COLD_POST_URL,
  REDUX_MSG_DATA_COLLECTION_HOT_POST_URL,
  REDUX_MSG_DEEPLINK_PROGRAM_GET_URL,
  REDUX_MSG_DEEPLINK_SERIES_GET_URL,
  REDUX_MSG_DEFAULT_HUB_URL,
  REDUX_MSG_GAMESTREAM_TOKEN_GET_URL,
  REDUX_MSG_HUB_URL,
  REDUX_MSG_LIST_ALIAS_MULTI_URL,
  REDUX_MSG_LIST_ALIAS_URL,
  REDUX_MSG_LOCATION_CATCHUP_FOR_ASSET_URL,
  REDUX_MSG_LOCATION_EPG_FOR_ASSET_URL,
  REDUX_MSG_LOCATION_EPG_URL,
  REDUX_MSG_LOCATION_VOD_FOR_ASSET_URL,
  REDUX_MSG_METADATA_CHANNELS_URL,
  REDUX_MSG_METADATA_IMAGE_WITH_VARIANT_URL,
  REDUX_MSG_METADATA_PROGRAM_URL,
  REDUX_MSG_METADATA_SCHEDULE_URL,
  REDUX_MSG_NPVR_RECORDINGS_DELETE_URL,
  REDUX_MSG_NPVR_RECORDINGS_FUTURE_URL,
  REDUX_MSG_NPVR_RECORDINGS_LIST_URL,
  REDUX_MSG_NPVR_RECORDINGS_METADATA_URL,
  REDUX_MSG_NPVR_RECORDINGS_QUOTA_URL,
  REDUX_MSG_NPVR_RECORDINGS_RETRY_URL,
  REDUX_MSG_NPVR_RECORDINGS_STOP_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_CREATE_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_DELETE_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_FULL_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_METADATA_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_UPDATE_URL,
  REDUX_MSG_PERSONAL_DATA_DELETE_URL,
  REDUX_MSG_PERSONAL_DATA_GET_URL,
  REDUX_MSG_PERSONAL_DATA_LIST_DELETE_URL,
  REDUX_MSG_PERSONAL_DATA_LIST_GET_URL,
  REDUX_MSG_PERSONAL_DATA_LIST_POST_URL,
  REDUX_MSG_PERSONAL_DATA_POST_URL,
  REDUX_MSG_PURCHASE_INFO_PER_ASSET_URL,
  REDUX_MSG_REQUEST_ENTITLEMENT_GET_URL,
  REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE_URL,
  REDUX_MSG_REQUEST_GENERIC,
  REDUX_MSG_RIGHT_COMMERCIAL_OFFERS_URL,
  REDUX_MSG_RIGHT_DEFAULT_RIGHTS_URL,
  REDUX_MSG_RIGHT_USER_RIGHTS_URL,
} from '../../constants/';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { HttpMethod } from '../../../../libs/netgemLibrary/v8/types/HttpMethod';
import { type NETGEM_API_V8_DISCOVERY_RESULT } from '../../../../libs/netgemLibrary/v8/types/DiscoveryResult';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { type NETGEM_API_V8_URL_DEFINITION } from '../../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { buildMissingParametersError } from '../../../../helpers/debug/debug';
import { enablePackPurchaseApi } from '../../../appConf/actions';
import { generateApiUrl } from '../helpers/api';
import { getSettingValueByName } from '../helpers/settings';
import { setNtgUrlDefinition } from '../index';

const parseDiscovery: (discoveryResult: NETGEM_API_V8_DISCOVERY_RESULT) => InternalActionDefinitionType = (discoveryResult) => (dispatch: Dispatch) => {
  const { dataCollection, game, personalData, rights, video } = discoveryResult;

  // Hub
  const { hub } = video;
  dispatch(setNtgUrlDefinition(REDUX_MSG_HUB_URL, hub));

  // Default onItemClick
  const { default: defaultHubUrl } = video;
  dispatch(setNtgUrlDefinition(REDUX_MSG_DEFAULT_HUB_URL, defaultHubUrl));

  // List alias
  const {
    listAlias: { multi: listAliasMulti, post: listAliasPost },
  } = video;
  dispatch(setNtgUrlDefinition(REDUX_MSG_LIST_ALIAS_MULTI_URL, listAliasMulti));
  dispatch(setNtgUrlDefinition(REDUX_MSG_LIST_ALIAS_URL, listAliasPost));

  // Program metadata
  const {
    metadata: { program: metadataProgram },
  } = video;
  dispatch(setNtgUrlDefinition(REDUX_MSG_METADATA_PROGRAM_URL, metadataProgram));

  // Image metadata
  const {
    metadata: { imageWithVariant: metadataImageWithVariant },
  } = video;
  dispatch(setNtgUrlDefinition(REDUX_MSG_METADATA_IMAGE_WITH_VARIANT_URL, metadataImageWithVariant));

  // Schedule metadata
  const {
    metadata: { schedule: metadataSchedule },
  } = video;
  dispatch(setNtgUrlDefinition(REDUX_MSG_METADATA_SCHEDULE_URL, metadataSchedule));

  // Channels metadata
  const {
    metadata: { channels: metadataChannels },
  } = video;
  dispatch(setNtgUrlDefinition(REDUX_MSG_METADATA_CHANNELS_URL, metadataChannels));

  // Locations
  const {
    locations: { catchupForAsset, epg, epgForAsset, vodForAsset },
  } = video;
  dispatch(setNtgUrlDefinition(REDUX_MSG_LOCATION_CATCHUP_FOR_ASSET_URL, catchupForAsset));
  dispatch(setNtgUrlDefinition(REDUX_MSG_LOCATION_EPG_URL, epg));
  dispatch(setNtgUrlDefinition(REDUX_MSG_LOCATION_EPG_FOR_ASSET_URL, epgForAsset));
  dispatch(setNtgUrlDefinition(REDUX_MSG_LOCATION_VOD_FOR_ASSET_URL, vodForAsset));

  // Entitlement get/release
  const {
    entitlement: { get: entitlementGet, release: entitlementRelease },
  } = video;
  dispatch(setNtgUrlDefinition(REDUX_MSG_REQUEST_ENTITLEMENT_GET_URL, entitlementGet));
  dispatch(setNtgUrlDefinition(REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE_URL, entitlementRelease));

  // NPVR
  const {
    npvr: {
      recordings: { delete: recordingsDelete, future: recordingsFuture, list: recordingsList, metadata: recordingsMetadata, quota: recordingsQuota, retry: recordingsRetry, stop: recordingsStop },
      scheduledRecordings: {
        create: schRecordingsCreate,
        delete: schRecordingsDelete,
        list: schRecordingsList,
        listFull: schRecordingsListFull,
        metadata: schRecordingsMetadata,
        update: schRecordingsUpdate,
      },
    },
  } = video;
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_RECORDINGS_DELETE_URL, recordingsDelete));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_RECORDINGS_FUTURE_URL, recordingsFuture));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_RECORDINGS_LIST_URL, recordingsList));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_RECORDINGS_METADATA_URL, recordingsMetadata));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_RECORDINGS_QUOTA_URL, recordingsQuota));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_RECORDINGS_RETRY_URL, recordingsRetry));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_RECORDINGS_STOP_URL, recordingsStop));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_CREATE_URL, schRecordingsCreate));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_DELETE_URL, schRecordingsDelete));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_URL, schRecordingsList));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_METADATA_URL, schRecordingsMetadata));
  dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_UPDATE_URL, schRecordingsUpdate));

  if (schRecordingsListFull) {
    dispatch(setNtgUrlDefinition(REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_FULL_URL, schRecordingsListFull));
  }

  // CNC deeplink
  const { cnc, deeplink } = video;
  if (cnc) {
    const { program, series } = cnc;
    dispatch(setNtgUrlDefinition(REDUX_MSG_CNC_PROGRAM_GET_URL, program));
    dispatch(setNtgUrlDefinition(REDUX_MSG_CNC_SERIES_GET_URL, series));
  }
  if (deeplink) {
    const { program, series } = deeplink;
    dispatch(setNtgUrlDefinition(REDUX_MSG_DEEPLINK_PROGRAM_GET_URL, program));
    dispatch(setNtgUrlDefinition(REDUX_MSG_DEEPLINK_SERIES_GET_URL, series));
  }

  // Personal data (GET/POST/DELETE)
  const {
    single: {
      perUser: { delete: personalDataDelete, get: personalDataGet, send: personalDataPost },
    },
  } = personalData;
  dispatch(setNtgUrlDefinition(REDUX_MSG_PERSONAL_DATA_POST_URL, personalDataPost));
  dispatch(setNtgUrlDefinition(REDUX_MSG_PERSONAL_DATA_GET_URL, personalDataGet));
  dispatch(setNtgUrlDefinition(REDUX_MSG_PERSONAL_DATA_DELETE_URL, personalDataDelete));

  // Personal data list (GET/POST/DELETE)
  const {
    list: {
      perUser: { delete: personalDataListDelete, get: personalDataListGet, send: personalDataListPost },
    },
  } = personalData;
  dispatch(setNtgUrlDefinition(REDUX_MSG_PERSONAL_DATA_LIST_POST_URL, personalDataListPost));
  dispatch(setNtgUrlDefinition(REDUX_MSG_PERSONAL_DATA_LIST_GET_URL, personalDataListGet));
  dispatch(setNtgUrlDefinition(REDUX_MSG_PERSONAL_DATA_LIST_DELETE_URL, personalDataListDelete));

  // Data collection
  const { coldStack, hotStack } = dataCollection;
  dispatch(setNtgUrlDefinition(REDUX_MSG_DATA_COLLECTION_COLD_POST_URL, coldStack));
  dispatch(setNtgUrlDefinition(REDUX_MSG_DATA_COLLECTION_HOT_POST_URL, hotStack));

  // Right management
  if (rights) {
    const { commercialOffers, default: defaultRights, user: userRights } = rights;
    dispatch(setNtgUrlDefinition(REDUX_MSG_RIGHT_COMMERCIAL_OFFERS_URL, commercialOffers));
    dispatch(setNtgUrlDefinition(REDUX_MSG_RIGHT_DEFAULT_RIGHTS_URL, defaultRights));
    dispatch(setNtgUrlDefinition(REDUX_MSG_RIGHT_USER_RIGHTS_URL, userRights));
  }

  // Purchase info
  if (video.purchase) {
    const {
      purchase: { perAsset },
    } = video;
    dispatch(setNtgUrlDefinition(REDUX_MSG_PURCHASE_INFO_PER_ASSET_URL, perAsset));
    dispatch(enablePackPurchaseApi());
  }

  // Gaming
  if (game?.getGamestreamToken) {
    dispatch(setNtgUrlDefinition(REDUX_MSG_GAMESTREAM_TOKEN_GET_URL, game.getGamestreamToken));
  }
};

const buildUrlDefinition: (url: string, apiKeyId: string, timeRound: string) => NETGEM_API_V8_URL_DEFINITION = (url, apiKeyId, timeRound) => {
  return {
    authent: false,
    params: [
      {
        name: 'realm',
        value: {
          args: [],
          op: 'realm',
        },
      },
      {
        // For compatibility purpose:
        name: 'oem',
        value: {
          args: [],
          op: 'realm',
        },
      },
      {
        name: 'date',
        value: {
          args: [Number(timeRound)],
          op: 'now',
        },
      },
      {
        name: 'apiKeyId',
        value: {
          args: [],
          op: 'apiKeyId',
        },
      },
    ],
    sakName: apiKeyId || '',
    uri: url || '',
  };
};

const sendV8DiscoveryRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const { appConfiguration } = state;
    const url: ?string = getSettingValueByName('platform.discovery', 'url', appConfiguration);
    const apiKeyId: ?string = getSettingValueByName('platform.discovery', 'apikeyid', appConfiguration);
    const timeRound: ?string = getSettingValueByName('platform.discovery', 'time_round', appConfiguration);

    if (!apiKeyId || !timeRound || !url) {
      return Promise.reject(
        buildMissingParametersError(
          {
            apiKeyId,
            timeRound,
            url,
          },
          'PTF discovery',
        ),
      );
    }

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent: false,
      method: HttpMethod.GET,
      signal,
      uri: generateApiUrl(buildUrlDefinition(url, apiKeyId, timeRound), { apiKeyId }, state),
    }).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => dispatch(parseDiscovery(response.result)));
  };

export default sendV8DiscoveryRequest;
