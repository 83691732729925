/* @flow */

import { DmsNetworkCode, getMessageFromErrorCode } from './dms/constants/NetworkCodesAndMessages';
import type { KeyValuePair, Undefined } from '@ntg/utils/dist/types';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { logDebug, logError, logInfo, logWarning } from '../../helpers/debug/debug';
import { sendV8DataCollectionCollectorIdRequest, sendV8DataCollectionRequest } from './dataCollection/requests';
import { sendV8ScheduledRecordingCreateRequest, sendV8ScheduledRecordingUpdateRequest } from './v8/requests/scheduledRecordings';
import AccurateTimestamp from '../../helpers/dateTime/AccurateTimestamp';
import type { ApplicationInfo } from '../../redux/netgemApi/actions/emitter';
import { type BO_API_AUTHENTICATION_SETTINGS } from './videofutur/types/AuthenticationSettings';
import type { BO_API_PARAMETERS_TYPE } from '../../helpers/videofutur/queryParameters';
import type { BO_RESPONSE_WITH_ETAG } from '../../redux/netgemApi/actions/videofutur/types/common';
import type { ChannelMap } from './v8/types/Channel';
import type { CombinedReducers } from '../../redux/reducers';
import { CustomNetworkError } from './helpers/CustomNetworkError';
import type { DmsSettingMap } from './dms/types/DeviceInfoSettings';
import { HttpMethod } from './v8/types/HttpMethod';
import LocalStorageManager from '../../helpers/localStorage/localStorageManager';
import { type NETGEM_API_DMS_ASSOCIATED_DEVICE } from './dms/types/AssociatedDevice';
import { type NETGEM_API_DMS_CHANNEL_LIST } from './dms/types/ChannelList';
import { type NETGEM_API_DMS_QUOTA_PVR } from './dms/types/QuotaPvr';
import { type NETGEM_API_DMS_REQUEST_RESPONSE_ASSOCIATED_DEVICE } from './dms/types/RequestResponseAssociatedDevice';
import { type NETGEM_API_DMS_REQUEST_RESPONSE_CHALLENGE } from './dms/types/RequestResponseChallenge';
import { type NETGEM_API_DMS_REQUEST_RESPONSE_QUOTA_PVR } from './dms/types/RequestResponseQuotaPvr';
import type { NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD } from './v8/types/DataCollection';
import { type NETGEM_API_V8_REQUEST_HEADERS } from './v8/types/Headers';
import { type NETGEM_API_V8_SECTION } from './v8/types/Section';
import type { ScheduledRecordingsKindType } from './v8/types/Npvr';
import { StorageKeys } from '../../helpers/localStorage/keys';
import { XhrResponseType } from '../../helpers/jsHelpers/xhr';
import { areSettingsEqual } from '../../helpers/dms/helper';
import boAuthenticationRequest from './videofutur/requests/authentication';
import boDiscoveryRequest from './videofutur/requests/discovery';
import { createCustomNetworkError } from './helpers/CreateCustomNetworkError';
import { deleteOptimisticData } from '../../helpers/localStorage/optimisticData';
import dmsAssociatedDeviceRequest from './dms/requests/associatedDevices';
import dmsDeviceChannelsRequest from './dms/requests/deviceChannels';
import dmsDeviceSettingsRequest from './dms/requests/deviceSettings';
import dmsLoginRequest from './dms/requests/login';
import dmsLogoutRequest from './dms/requests/logout';
import dmsQuotaPvrRequest from './dms/requests/quotaPvr';
import { getVfAuthenticationSettings } from '../../helpers/videofutur/authenticationSettings';
import { sendBORequest } from './videofutur/requests/bo';
import sendNtgEntitlementRequest from './ntgEntitlement/requests';
import sendPersonalDataGetRequest from './personalData/requests/get';
import sendPersonalDataPostRequest from './personalData/requests/post';
import { sendV8GenericRequest } from './v8/requests/generic';
import sendV8ListAliasPostRequest from './v8/requests/listAlias';
import { sendV8SectionFeedRequest } from './v8/requests/feed';

// Every 5 seconds, next renewal date is checked against current date (setTimeout is not used because it does not work properly when computer goes to sleep)
const TOKEN_RENEWAL_CHECK_INTERVAL = 5_000;

/*
 * In case of error, token renewal is retried multiple times, with increasing waiting time, up to a maximum of retries
 *  backoff time is initialized at 5 s
 *  then on each error, new backoff time is incremented by TOKEN_RENEWAL_CHECK_INTERVAL * retry number
 *
 * Example: 5s 10s 20s 35s ...
 */

// Maximum number of retries
const TOKEN_RENEWAL_MAX_RETRIES = 6;

// Token is renewed 5 minutes before its expiration date (in ms)
const TOKEN_RENEWAL_EXPIRATION_MARGIN = 300_000;

// Minimum number of DMS settings for a response to be considered valid
const DMS_SETTINGS_MIN_SIZE = 15;

// Delay before actually doing DMS settings query when a previous one has been found in the local storage (in ms)
const OPTIMISTIC_DMS_SETTINGS_REFRESH_DELAY = 10;

type AuthenticationTokenDataType = {|
  applicationId: string,
  authDeviceUrl: string,
  deviceKey: string,
  oem: ?string,
  subscriberId: string,
  version: ?string,
|};

type ConnectedCallbackType = (authenticationToken: string | null) => void;
type DisconnectedCallbackType = () => void;
type DeviceSettingsCallbackType = (deviceSettings: DmsSettingMap) => void;
type DeviceChannelsCallbackType = (deviceChannels: ChannelMap) => void;
type AssociatedDevicesCallbackType = (associatedDevices: Array<NETGEM_API_DMS_ASSOCIATED_DEVICE>) => void;
type QuotaPvrCallbackType = (quotaPvr: ?NETGEM_API_DMS_QUOTA_PVR) => void;
type BOAuthenticationCallbackType = (settings?: KeyValuePair<string>) => void;

class NetgemApi {
  associatedDevicesCallback: AssociatedDevicesCallbackType;

  authenticationToken: string | null;

  authenticationTokenData: ?AuthenticationTokenDataType;

  baseUpgradeDeviceUrl: string;

  boAuthenticationCallback: ?BOAuthenticationCallbackType;

  boTokenRenewalTimer: ?IntervalID;

  connectedCallback: ConnectedCallbackType;

  deviceChannelsCallback: DeviceChannelsCallbackType;

  deviceSettingsCallback: DeviceSettingsCallbackType;

  disconnectedCallback: DisconnectedCallbackType;

  isAuthenticationTokenRenewing: boolean;

  nextBOTokenRenewalDate: number;

  nextTokenRenewalDate: number;

  quotaPvrCallback: ?QuotaPvrCallbackType;

  retryCount: number;

  tokenRenewalTimer: ?IntervalID;

  constructor(
    connectedCallback: ConnectedCallbackType,
    disconnectedCallback: DisconnectedCallbackType,
    deviceSettingsCallback: DeviceSettingsCallbackType,
    deviceChannelsCallback: DeviceChannelsCallbackType,
    associatedDevicesCallback: AssociatedDevicesCallbackType,
    quotaPvrCallback: ?QuotaPvrCallbackType,
    boAuthenticationCallback: ?BOAuthenticationCallbackType,
  ) {
    this.associatedDevicesCallback = associatedDevicesCallback;
    this.authenticationToken = null;
    this.authenticationTokenData = null;
    this.baseUpgradeDeviceUrl = '';
    this.boTokenRenewalTimer = null;
    this.boAuthenticationCallback = boAuthenticationCallback;
    this.connectedCallback = connectedCallback;
    this.deviceChannelsCallback = deviceChannelsCallback;
    this.deviceSettingsCallback = deviceSettingsCallback;
    this.disconnectedCallback = disconnectedCallback;
    this.isAuthenticationTokenRenewing = false;
    this.nextBOTokenRenewalDate = 0;
    this.nextTokenRenewalDate = 0;
    this.quotaPvrCallback = quotaPvrCallback;
    this.retryCount = 0;
    this.tokenRenewalTimer = null;

    Messenger.on(MessengerEvents.REFRESH_AUTHENTICATION_TOKEN, this.refreshToken);
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'NetgemApi';
  }

  reset: () => void = () => {
    this.resetTokenRenewalTimer();
    this.resetBOTokenRenewalTimer();
    this.authenticationToken = null;
    this.disconnectedCallback();
  };

  checkErrorMessage: (error: CustomNetworkError) => void = (error) => {
    try {
      const customStatus = error.getCustomStatus();
      // Missing or invalid authentication token
      if (
        customStatus === (DmsNetworkCode.MissingOrInvalidAuthenticationToken: number) ||
        // Unknown device
        customStatus === (DmsNetworkCode.UnknownDevice: number) ||
        // Invalid device
        customStatus === (DmsNetworkCode.InvalidDevice: number) ||
        // DMS did not provide correct error code
        customStatus === (DmsNetworkCode.UnknownError: number)
      ) {
        // The reasons above trigger a reset
        this.reset();
      }
      // For all other statuses, execution proceeds
    } catch {
      // Not being able to get status also triggers a reset
      this.reset();
    }
  };

  refreshToken: () => void = () => {
    if (!this.authenticationTokenData) {
      logError('Cannot refresh authentication token because of missing data');
      return;
    }

    const {
      authenticationTokenData: { applicationId, authDeviceUrl, deviceKey, oem, subscriberId, version },
      isAuthenticationTokenRenewing,
    } = this;

    if (isAuthenticationTokenRenewing) {
      return;
    }

    this.resetTokenRenewalTimer();
    this.sendLoginRequest(authDeviceUrl, null, applicationId, subscriberId, deviceKey, oem, version).catch((error) => {
      logError(`Error refreshing authentication token (forced): ${error.message}`);
      logError(error instanceof CustomNetworkError ? error.networkError : error);
    });
  };

  startTokenRenewalTimer: (authDeviceUrl: string, applicationId: string, subscriberId: string, deviceKey: string, oem: ?string, version: ?string) => void = (
    authDeviceUrl,
    applicationId,
    subscriberId,
    deviceKey,
    oem,
    version,
  ) => {
    const { tokenRenewalTimer } = this;

    if (tokenRenewalTimer) {
      clearInterval(tokenRenewalTimer);
    }

    this.tokenRenewalTimer = setInterval(() => {
      const { nextTokenRenewalDate } = this;

      if (nextTokenRenewalDate > 0 && AccurateTimestamp.now() >= nextTokenRenewalDate) {
        this.sendLoginRequest(authDeviceUrl, null, applicationId, subscriberId, deviceKey, oem, version).catch((error) => {
          logError(`Error refreshing authentication token (timer): ${error.message}`);
          logError(error instanceof CustomNetworkError ? error.networkError : error);

          if (this.retryCount >= TOKEN_RENEWAL_MAX_RETRIES) {
            // Max number of retries has been reached: reset app
            logWarning('Max retries reached. Giving up.');
            this.reset();
            return;
          }

          this.nextTokenRenewalDate = AccurateTimestamp.now() + TOKEN_RENEWAL_CHECK_INTERVAL * this.retryCount;
          const retriesLeft = TOKEN_RENEWAL_MAX_RETRIES - this.retryCount;
          logInfo(`Will retry ${retriesLeft} time${retriesLeft > 1 ? 's' : ''}...`);
          this.retryCount += 1;
        });
      }
    }, TOKEN_RENEWAL_CHECK_INTERVAL);
  };

  startBOTokenRenewalTimer: (authenticationUrl: string, applicationId: string, deviceKey: string, appKey: string) => void = (authenticationUrl, applicationId, deviceKey, appKey) => {
    const { boTokenRenewalTimer } = this;

    if (boTokenRenewalTimer) {
      // Do not start timer more than once
      return;
    }

    this.boTokenRenewalTimer = setInterval(() => {
      const { nextBOTokenRenewalDate } = this;

      if (nextBOTokenRenewalDate > 0 && AccurateTimestamp.now() >= nextBOTokenRenewalDate) {
        this.sendBOAuthenticationRequest(authenticationUrl, applicationId, deviceKey, appKey).catch((error) => logError(error.message));
      }
    }, TOKEN_RENEWAL_CHECK_INTERVAL);
  };

  resetTokenRenewalTimer: () => void = () => {
    if (this.tokenRenewalTimer) {
      clearInterval(this.tokenRenewalTimer);
      this.tokenRenewalTimer = null;
    }
    this.nextTokenRenewalDate = 0;
  };

  resetBOTokenRenewalTimer: () => void = () => {
    if (this.boTokenRenewalTimer) {
      clearInterval(this.boTokenRenewalTimer);
      this.boTokenRenewalTimer = null;
    }
    this.nextBOTokenRenewalDate = 0;
  };

  dmsSettingsSuccessCallback: (settings: DmsSettingMap, isChecking?: boolean, optimisticDmsSettings: ?DmsSettingMap) => Promise<void> = (settings, isChecking, optimisticDmsSettings) => {
    LocalStorageManager.save(StorageKeys.OptimisticDmsSettingsUsed, false);

    const optimisticDmsSettingsFound = optimisticDmsSettings !== null;

    if (isChecking && optimisticDmsSettingsFound && areSettingsEqual(settings, optimisticDmsSettings)) {
      // Stored settings are still up-to-date
      return Promise.resolve();
    }

    const settingsCount = Object.keys(settings).length;

    if (settingsCount === 0) {
      // DMS settings empty
      deleteOptimisticData();
      if (isChecking) {
        // When checking DMS in background, promise would remain uncaught
        return Promise.resolve();
      }
      return Promise.reject(
        new CustomNetworkError({
          message: 'Empty DMS settings',
          status: -1,
        }),
      );
    }

    if (settingsCount <= DMS_SETTINGS_MIN_SIZE) {
      // DMS settings seem incomplete
      deleteOptimisticData();
      if (isChecking) {
        // When checking DMS in background, promise would remain uncaught
        return Promise.resolve();
      }
      return Promise.reject(
        new CustomNetworkError({
          message: 'Incomplete DMS settings',
          status: -1,
        }),
      );
    }

    // Store settings in Redux store
    this.deviceSettingsCallback(settings);

    // Store settings in local storage for future optimistic use
    LocalStorageManager.save(StorageKeys.OptimisticDmsSettings, settings);

    if (!isChecking) {
      // Settings found, used and stored, and no optimistic settings were found
      return Promise.resolve();
    }

    // Since settings have changed, reload app
    Messenger.emit(MessengerEvents.RESTART_APP);

    // When checking DMS in background, promise would remain uncaught
    return Promise.resolve();
  };

  getDeviceSettings: (appInfo: ApplicationInfo, signal?: AbortSignal, isChecking?: boolean) => Promise<void> = (appInfo, signal, isChecking) => {
    const optimisticDmsSettings = LocalStorageManager.loadObject(StorageKeys.OptimisticDmsSettings);

    if (!isChecking && optimisticDmsSettings && Object.keys(optimisticDmsSettings).length > DMS_SETTINGS_MIN_SIZE && !LocalStorageManager.loadBoolean(StorageKeys.OptimisticDmsSettingsUsed, true)) {
      // Startup optimization: get last DMS settings from local storage and do the actual query in background after a short delay
      this.deviceSettingsCallback(optimisticDmsSettings);
      LocalStorageManager.save(StorageKeys.OptimisticDmsSettingsUsed, true);
      // No cancel token because checking DMS settings should not be cancelled
      setTimeout(this.getDeviceSettings, OPTIMISTIC_DMS_SETTINGS_REFRESH_DELAY, appInfo, undefined, true);
      return Promise.resolve();
    }

    return dmsDeviceSettingsRequest(this.baseUpgradeDeviceUrl, this.authenticationToken, appInfo, signal)
      .then((settings) => {
        signal?.throwIfAborted();

        return this.dmsSettingsSuccessCallback(settings, isChecking, optimisticDmsSettings);
      })
      .catch((error: CustomNetworkError) => {
        this.checkErrorMessage(error);
        if (isChecking) {
          // Settings have been retrieved in background and promise rejection would not be caught
          return Promise.resolve();
        }

        return Promise.reject(error);
      });
  };

  getDeviceChannelList: (signal?: AbortSignal) => Promise<NETGEM_API_DMS_CHANNEL_LIST> = (signal) =>
    dmsDeviceChannelsRequest(this.baseUpgradeDeviceUrl, this.authenticationToken, signal)
      .then((param: { deviceChannelNetworkResponse: NETGEM_API_DMS_CHANNEL_LIST, deviceChannels: ChannelMap }) => {
        signal?.throwIfAborted();

        this.deviceChannelsCallback(param.deviceChannels);
        return Promise.resolve(param.deviceChannelNetworkResponse);
      })
      .catch((error: CustomNetworkError) => {
        this.checkErrorMessage(error);
        return Promise.reject(error);
      });

  getAssociatedDeviceList: (signal?: AbortSignal) => Promise<NETGEM_API_DMS_REQUEST_RESPONSE_ASSOCIATED_DEVICE> = (signal) => {
    this.associatedDevicesCallback([]);

    return dmsAssociatedDeviceRequest(this.baseUpgradeDeviceUrl, this.authenticationToken, signal)
      .then((associatedDeviceNetworkResponse: NETGEM_API_DMS_REQUEST_RESPONSE_ASSOCIATED_DEVICE) => {
        signal?.throwIfAborted();

        this.associatedDevicesCallback(associatedDeviceNetworkResponse.list);
        return Promise.resolve(associatedDeviceNetworkResponse);
      })
      .catch((error: CustomNetworkError) => {
        this.checkErrorMessage(error);
        return Promise.reject(error);
      });
  };

  getQuotaPvr: () => Promise<NETGEM_API_DMS_REQUEST_RESPONSE_QUOTA_PVR> = () => {
    if (this.quotaPvrCallback) {
      this.quotaPvrCallback(null);
    }

    return dmsQuotaPvrRequest(this.baseUpgradeDeviceUrl, this.authenticationToken)
      .then((quotaPvrNetworkResponse: NETGEM_API_DMS_REQUEST_RESPONSE_QUOTA_PVR) => {
        if (this.quotaPvrCallback) {
          const { quotaPVR, quotaPVRUsed } = quotaPvrNetworkResponse;
          this.quotaPvrCallback({
            quotaPVR,
            quotaPVRUsed,
          });
        }
        return Promise.resolve(quotaPvrNetworkResponse);
      })
      .catch((error: CustomNetworkError) => {
        this.checkErrorMessage(error);
        return Promise.reject(error);
      });
  };

  sendLogoutRequest: (logoutUrl?: string) => Promise<void> = (logoutUrl) =>
    dmsLogoutRequest(logoutUrl)
      .then(() => {
        this.reset();
        return Promise.resolve();
      })
      .catch((error: CustomNetworkError) => {
        this.checkErrorMessage(error);
        return Promise.reject(error);
      });

  sendLoginRequest: (
    authDeviceUrl: string,
    upgradeDeviceUrl: ?string,
    applicationId: string,
    subscriberId: string,
    deviceKey: string,
    oem: ?string,
    version: ?string,
    isFirstToken: ?boolean,
    signal?: AbortSignal,
  ) => Promise<NETGEM_API_DMS_REQUEST_RESPONSE_CHALLENGE> = (authDeviceUrl, upgradeDeviceUrl, applicationId, subscriberId, deviceKey, oem, version, isFirstToken, signal) => {
    if (typeof upgradeDeviceUrl === 'string') {
      this.baseUpgradeDeviceUrl = upgradeDeviceUrl;
    }

    if (typeof applicationId !== 'string' || typeof subscriberId !== 'string' || typeof deviceKey !== 'string' || typeof authDeviceUrl !== 'string') {
      return Promise.reject(createCustomNetworkError((DmsNetworkCode.MissingOrInvalidRequiredParameters: number), getMessageFromErrorCode(DmsNetworkCode.MissingOrInvalidRequiredParameters)));
    }

    this.isAuthenticationTokenRenewing = true;

    logDebug(`${typeof upgradeDeviceUrl === 'string' ? 'Requesting' : 'Renewing'} authentication token...`);

    return dmsLoginRequest(authDeviceUrl, applicationId, subscriberId, deviceKey, oem, version, signal)
      .then((jsonChallengeResponse: NETGEM_API_DMS_REQUEST_RESPONSE_CHALLENGE) => {
        const { expirationDate, token } = jsonChallengeResponse;

        this.isAuthenticationTokenRenewing = false;

        logDebug(`Authentication token ${typeof upgradeDeviceUrl === 'string' ? 'received' : 'renewed'}`);

        // Reset retry count since the call succeeded
        this.retryCount = 0;

        // Store token locally
        this.authenticationToken = token;

        // Store required data for future refresh
        this.authenticationTokenData = {
          applicationId,
          authDeviceUrl,
          deviceKey,
          oem,
          subscriberId,
          version,
        };

        // Store token in Redux store
        this.connectedCallback(token);

        this.nextTokenRenewalDate = expirationDate - TOKEN_RENEWAL_EXPIRATION_MARGIN;

        // These 2 dates only exists for debug purpose and should be removed in a near future
        LocalStorageManager.save(StorageKeys.LastTokenRenewal, AccurateTimestamp.nowAsDate());
        LocalStorageManager.save(StorageKeys.NextTokenRenewal, new Date(this.nextTokenRenewalDate));

        if (!this.tokenRenewalTimer || isFirstToken) {
          // Do not start timer more than once but reset it if going from guest to authenticated (or the opposite)
          this.startTokenRenewalTimer(authDeviceUrl, applicationId, subscriberId, deviceKey, oem, version);
        }

        return Promise.resolve(jsonChallengeResponse);
      })
      .catch((error: CustomNetworkError) => {
        this.isAuthenticationTokenRenewing = false;
        this.checkErrorMessage(error);
        return Promise.reject(error);
      });
  };

  sendBOAuthenticationRequest: (authenticationUrl: string, applicationId: string, deviceKey: string, appKey: string, signal?: AbortSignal) => Promise<KeyValuePair<string>> = (
    authenticationUrl,
    applicationId,
    deviceKey,
    appKey,
    signal,
  ) =>
    boAuthenticationRequest(authenticationUrl, applicationId, deviceKey, appKey, signal).then((authenticationSettings: BO_API_AUTHENTICATION_SETTINGS) => {
      const { authentication } = authenticationSettings;

      if (authentication) {
        const { identityExpiration } = authentication;

        if (identityExpiration) {
          this.nextBOTokenRenewalDate = identityExpiration - TOKEN_RENEWAL_EXPIRATION_MARGIN;

          // These 2 dates only exists for debug purpose and should be removed in a near future
          LocalStorageManager.save(StorageKeys.LastBOTokenRenewal, AccurateTimestamp.nowAsDate());
          LocalStorageManager.save(StorageKeys.NextBOTokenRenewal, new Date(this.nextBOTokenRenewalDate));

          this.startBOTokenRenewalTimer(authenticationUrl, applicationId, deviceKey, appKey);
        }
      }

      const { error, settings } = getVfAuthenticationSettings(authenticationSettings);

      if (this.boAuthenticationCallback) {
        this.boAuthenticationCallback(settings);
      }

      if (settings && !error) {
        return Promise.resolve(settings);
      }

      return Promise.reject(error);
    });

  sendNtgEntitlementRequest: (
    url: string,
    applicationId: string,
    channelName: string,
    entId: string,
    entService: string,
    entUrl: string,
    method: HttpMethod,
    authenticationToken: string | null,
    signal?: AbortSignal,
  ) => Promise<any> = (url, applicationId, channelName, entId, entService, entUrl, method, authenticationToken, signal) =>
    sendNtgEntitlementRequest(url, applicationId, channelName, entId, entService, entUrl, method, authenticationToken, signal);

  sendV8ListAliasPostRequest: (url: string, bodyParam: string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    bodyParam,
    method,
    authenticationToken,
    signal,
  ) => sendV8ListAliasPostRequest(url, bodyParam, method, authenticationToken, signal);

  sendV8GenericRequest: (url: string, method: HttpMethod, authenticationToken: string | null, responseType?: string, state?: CombinedReducers, signal?: AbortSignal) => Promise<any> = (
    url,
    method,
    authenticationToken,
    responseType,
    state,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, state, signal, responseType);

  sendV8RecordingsDeleteRequest: (url: string, eTag: string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, null, signal, '', eTag);

  sendV8RecordingsFutureRequest: (url: string, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, null, signal, undefined, eTag);

  sendV8RecordingsListRequest: (url: string, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, null, signal, undefined, eTag);

  sendV8RecordingsMetadataRequest: (url: string, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, null, signal, undefined, eTag);

  sendV8RecordingsQuotaRequest: (url: string, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, null, signal, undefined, eTag);

  sendV8RecordingsRetryRequest: (url: string, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, null, signal, '', eTag);

  sendV8ScheduledRecordingCreateRequest: (
    url: string,
    scheduledRecordKind: ScheduledRecordingsKindType,
    target: string,
    fromBeginning: boolean,
    startMargin: number,
    endMargin: number,
    channelId: ?string,
    fromUtc: ?string,
    toUtc: ?string,
    increasingEpisodes: ?boolean,
    recordsToKeep: ?number,
    method: HttpMethod,
    authenticationToken: string | null,
    signal?: AbortSignal,
  ) => Promise<any> = (url, scheduledRecordKind, target, fromBeginning, startMargin, endMargin, channelId, fromUtc, toUtc, increasingEpisodes, recordsToKeep, method, authenticationToken, signal) =>
    sendV8ScheduledRecordingCreateRequest(
      url,
      scheduledRecordKind,
      target,
      fromBeginning,
      startMargin,
      endMargin,
      channelId,
      fromUtc,
      toUtc,
      increasingEpisodes,
      recordsToKeep,
      method,
      authenticationToken,
      signal,
    );

  sendV8ScheduledRecordingDeleteRequest: (url: string, eTag: string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, null, signal, '', eTag);

  sendV8ScheduledRecordingListRequest: (url: string, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, null, signal, undefined, eTag);

  sendV8ScheduledRecordingMetadataRequest: (url: string, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, null, signal, undefined, eTag);

  sendV8ScheduledRecordingUpdateRequest: (
    url: string,
    scheduledRecordId: string,
    fromUtc: ?string,
    toUtc: ?string,
    eTag: string,
    increasingEpisodes: ?boolean,
    recordsToKeep: ?number,
    method: HttpMethod,
    authenticationToken: string | null,
    signal?: AbortSignal,
  ) => Promise<any> = (url, scheduledRecordId, fromUtc, toUtc, eTag, increasingEpisodes, recordsToKeep, method, authenticationToken, signal) =>
    sendV8ScheduledRecordingUpdateRequest(url, scheduledRecordId, fromUtc, toUtc, increasingEpisodes, recordsToKeep, method, eTag, authenticationToken, signal);

  sendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, searchString: ?string, state: CombinedReducers, signal?: AbortSignal) => Promise<any> = (section, searchString, state, signal) =>
    sendV8SectionFeedRequest(section, searchString, state, signal);

  sendPersonalDataDeleteRequest: (url: string, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendV8GenericRequest(url, method, authenticationToken, null, signal, '', eTag);

  sendPersonalDataGetRequest: (url: string, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendPersonalDataGetRequest(url, eTag, method, authenticationToken, signal);

  sendPersonalDataPostRequest: (url: string, data: any, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (
    url,
    data,
    eTag,
    method,
    authenticationToken,
    signal,
  ) => sendPersonalDataPostRequest(url, data, eTag, method, authenticationToken, signal);

  sendV8DataCollectionCollectorIdRequest: (url: string, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (url, authenticationToken, signal) =>
    sendV8DataCollectionCollectorIdRequest(url, authenticationToken, signal);

  sendV8DataCollectionRequest: (url: string, content: NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD, method: HttpMethod, headers: NETGEM_API_V8_REQUEST_HEADERS, signal?: AbortSignal) => Promise<any> = (
    url,
    content,
    method,
    headers,
    signal,
  ) => sendV8DataCollectionRequest(url, content, method, headers, signal);

  sendV8CustomStrategyRequest: (url: string, signal?: AbortSignal) => Promise<any> = (url, signal) => sendV8GenericRequest(url, HttpMethod.GET, null, null, signal);

  sendVideofuturDiscoveryRequest: (discoveryUrl: string, device: string, distributor: string, identity: string, signal?: AbortSignal, useBOV2Api?: boolean) => Promise<any> = (
    discoveryUrl,
    device,
    distributor,
    identity,
    signal,
    useBOV2Api,
  ) => boDiscoveryRequest(discoveryUrl, device, distributor, identity, signal, useBOV2Api);

  sendVideofuturRequest: (
    baseUrl: string,
    method: HttpMethod,
    authenticationToken: string | null,
    responseType: XhrResponseType,
    parameters: Undefined<BO_API_PARAMETERS_TYPE | null>,
    body: ?KeyValuePair<?(string | number | boolean)>,
    headers: ?NETGEM_API_V8_REQUEST_HEADERS,
    signal?: AbortSignal,
    keepAlive?: boolean,
    eTag?: string,
  ) => Promise<BO_RESPONSE_WITH_ETAG> = (baseUrl, method, authenticationToken, responseType, parameters, body, headers, signal, keepAlive, eTag) =>
    sendBORequest(baseUrl, method, authenticationToken, responseType, parameters, body, headers, signal, keepAlive, eTag);
}

export default NetgemApi;
