/* @flow */

// $FlowFixMe: CSS file only exists in dist folder
import './Picto.css';
import * as React from 'react';
// $FlowFixMe: Flow cannot resolve clsx
import clsx from 'clsx';

/* eslint-disable react/require-default-props */
export type PictoPropType = {|
  +children: React.Node,
  +className?: string,
  +data?: string,
  +draggable?: boolean,
  +forceHoverEffect?: boolean,
  +hasBackground?: boolean,
  +isDisabled?: boolean,
  +key?: string,
  +onClick?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseDown?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseEnter?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseLeave?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseMove?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseUp?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
|};
/* eslint-enable react/require-default-props */

const Picto = ({ children, ...delegated }: PictoPropType, ref: React.ElementRef<any>): React.Node => {
  const { className = '', data, draggable, forceHoverEffect, hasBackground, key, isDisabled, onClick, onMouseDown, onMouseEnter, onMouseLeave, onMouseMove, onMouseUp } = delegated;

  const content = (
    <>
      {hasBackground ? <div className='background' /> : null}
      <svg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'>
        {children}
      </svg>
    </>
  );

  if (onClick !== undefined) {
    // Clickable
    return (
      <button
        className={clsx('pictoElement', 'hoverEffect', className)}
        data={data}
        disabled={isDisabled}
        key={key}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        ref={ref}
        type='button'
      >
        {content}
      </button>
    );
  }

  // Not clickable
  return (
    <div
      className={clsx('pictoElement', className, forceHoverEffect && 'hoverEffect')}
      data={data}
      disabled={isDisabled}
      draggable={draggable}
      key={key}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      ref={ref}
    >
      {content}
    </div>
  );
};

// $FlowFixMe: don't know how to annotate this export
export default React.forwardRef(Picto);
