/* @flow */

import { RecordingOutcome, type ScheduledRecordingsKindType } from '../../libs/netgemLibrary/v8/types/Npvr';

// All times below are in seconds
export type NPVR_QUOTA = {|
  formattedRecordingTime: string,
  formattedRemainingTime: string,
  formattedScheduledRecordingTime: string,
  formattedTotalQuota: string,
  recordingTime: number,
  remainingTime: number,
  scheduledRecordingTime: number,
  totalQuota: number,
|};

export enum RecordingKind {
  Series,
  SeriesWithSingle,
  Single,
  SingleKeepFromReplay,
}

export type ITEM_RECORD_STATUS = {|
  failedRecordingId?: string,
  hasRecording: boolean,
  hasScheduledRecording: boolean,
  hasSeriesScheduledRecording: boolean,
  recordOutcome?: RecordingOutcome,
  warningScheduledEventId?: string,
  warningScheduledRecordingId?: string,
|};

export type SCHEDULED_RECORDING_CORE_SETTINGS = {|
  fromUtc?: string | null,
  increasingEpisodes?: boolean,
  recordsToKeep?: number,
  toUtc?: string | null,
|};

export type SCHEDULED_RECORDING_CREATION_SETTINGS = {|
  ...SCHEDULED_RECORDING_CORE_SETTINGS,
  channelId?: string | null,
  scheduledRecordKind: ScheduledRecordingsKindType,
  target: string,
|};

export type SCHEDULED_RECORDING_UPDATE_SETTINGS = {|
  ...SCHEDULED_RECORDING_CORE_SETTINGS,
  assetId: string,
|};

export enum RecordButtonActionKind {
  Delete,
  None,
  Record,
}
