/* @flow */

import type { BasicFunction, KeyValuePair } from '@ntg/utils/dist/types';
import { ItemContent, type NETGEM_API_V8_FEED_ITEM, type NETGEM_API_V8_ITEM_LOCATION } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { MetadataKind, NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import type { NETGEM_RECORDINGS_MAP, NETGEM_SCHEDULED_RECORDINGS_MAP, ScheduledRecordingsKindType } from '../../../libs/netgemLibrary/v8/types/Npvr';
import type { PENDING_OPERATION_DATA_TYPE, VOD_PURCHASE_DATA_TYPE } from '../../../helpers/rights/pendingOperations';
import type { STREAM_PRIORITIES_TYPE, VOD_STATUS_TYPE } from '../../../helpers/ui/metadata/Types';
import type { BO_FAVORITE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/favorite';
import type { BO_PURCHASE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/purchase';
import type { BroadcastStatus } from '../../../helpers/ui/location/Format';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import type { ModalState } from '../../../redux/modal/reducers';
import type { NETGEM_API_V8_AUTHENT_REALM } from '../../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_PURCHASE_INFO } from '../../../libs/netgemLibrary/v8/types/PurchaseInfo';
import type { NETGEM_API_V8_RIGHTS } from '../../../libs/netgemLibrary/v8/types/Rights';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import type { NETGEM_API_VIEWINGHISTORY } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import type { ThemeType } from '@ntg/ui/dist/theme';
import { TileConfigTileType } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';

// Time display and broadcast status are refreshed every second
export const REFRESH_TIMEOUT = 1_000;

export enum ImageDisplayType {
  Landscape,
  Portrait,
  Unset,
}

export type CARD_DATA_MODAL_TYPE = {|
  displayedSectionCount?: number | null,
  distributorId?: string | null,
  isOpenFromPlayer?: boolean,
  isTimeshifting?: boolean,
  item: NETGEM_API_V8_FEED_ITEM,
  pendingOperation?: PENDING_OPERATION_DATA_TYPE | null,
  previousCard?: CARD_DATA_MODAL_TYPE | null,
  programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  recordKind?: ScheduledRecordingsKindType | null,
  seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
  tileType: TileConfigTileType,
  urlDefinition?: NETGEM_API_V8_URL_DEFINITION | null,
  viewingHistoryId?: string | null,
  vtiId?: number | null,
|};

export type ReduxCardModalDispatchToPropsType = {|
  +localAddToFavoriteList: (titId: string, signal?: AbortSignal) => Promise<any>,
  +localAddToWishlist: (assetId: string, channelId: string, signal?: AbortSignal) => Promise<any>,
  +localGetImageSourceUrl: (assetId: string, width: number, height: number) => Promise<?string>,
  +localGetImageUrl: (assetId: string, width: number, height: number, theme?: ThemeType, signal?: AbortSignal) => Promise<any>,
  +localGetPurchaseInfoPerAsset: (id: string, channelId: string, signal?: AbortSignal) => Promise<any>,
  +localGetVodLocations: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, signal?: AbortSignal) => Promise<any>,
  +localHideModal: BasicFunction,
  +localRemoveFromFavoriteList: (titId: string, signal?: AbortSignal) => Promise<any>,
  +localRemoveFromWishlist: (assetId: string, channelId: string, signal?: AbortSignal) => Promise<any>,
  +localResetSectionPageIndices: (prefix?: string) => void,
  +localSendV8LocationCatchupForAssetRequest: (assetId: string, startDate: number, range: number, signal?: AbortSignal) => Promise<any>,
  +localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal) => Promise<any>,
  +localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal) => Promise<any>,
  +localSendV8RecordingsMetadataRequest: (recordId: string, signal?: AbortSignal) => Promise<any>,
  +showVodPurchaseDialog: (vodPurchaseData: VOD_PURCHASE_DATA_TYPE) => void,
|};

export type ReduxCardModalReducerStateType = {|
  +applicationName: string,
  +channels: ChannelMap,
  +commercialOffers: KeyValuePair<Array<string>> | null,
  +deeplink: string | null,
  +defaultRights: NETGEM_API_V8_RIGHTS | null,
  +favoriteList: BO_FAVORITE_LIST_TYPE,
  +isDebugModeEnabled: boolean,
  +isNpvrEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +isSubscriptionFeatureEnabled: boolean,
  +npvrRecordingsFuture: NETGEM_RECORDINGS_MAP,
  +npvrRecordingsList: NETGEM_RECORDINGS_MAP,
  +npvrScheduledRecordingsList: NETGEM_SCHEDULED_RECORDINGS_MAP,
  +purchaseList: BO_PURCHASE_LIST_TYPE | null,
  +streamPriorities: STREAM_PRIORITIES_TYPE | null,
  +usePackPurchaseApi: boolean,
  +userRights: Array<string> | null,
  +viewingHistory: NETGEM_API_VIEWINGHISTORY,
  +viewingHistoryStatus: LoadableStatus,
  +wishlist: Set<string>,
  +wishlistStatus: LoadableStatus,
|};

export type CompleteCardModalPropType = {|
  ...ModalState,
  ...ReduxCardModalDispatchToPropsType,
  ...ReduxCardModalReducerStateType,
|};

export type CardModalStateType = {|
  authority?: NETGEM_API_V8_AUTHENT_REALM,
  backgroundImageUrl: string | null,
  broadcastStatus: BroadcastStatus,
  canBePlayed: boolean,
  channelImageUrl: string | null,
  contentType: ItemContent,
  followedPictoClassName: string,
  imageDisplayType: ImageDisplayType,
  isDeletable: boolean,
  isFollowed: ?boolean,
  isLiveRecording: boolean,
  isSeries: boolean,
  isWaitingForDeleteConfirmation: boolean,
  isWaitingForRecordConfirmation: boolean,
  portraitImageUrl: ?string,
  previewCatchupScheduledEventId: ?string,
  programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  purchaseInfo: NETGEM_API_V8_PURCHASE_INFO | null,
  seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
  startoverItem: NETGEM_API_V8_FEED_ITEM | null,
  tvLocationMetadata: NETGEM_API_V8_METADATA_SCHEDULE | null,
  vodLocationsMetadata: Array<NETGEM_API_V8_METADATA_SCHEDULE> | null,
  vodStatus: VOD_STATUS_TYPE | null,
|};
