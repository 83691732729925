/* @flow */

import type { NETGEM_FUTURE_RECORDINGS_STATUS, NETGEM_RECORDINGS_MAP, NETGEM_SCHEDULED_RECORDINGS_MAP } from '../../../libs/netgemLibrary/v8/types/Npvr';
import {
  REDUX_MSG_NPVR_RECORDINGS_FUTURE_UPDATE,
  REDUX_MSG_NPVR_RECORDINGS_LIST_UPDATE,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_FULL_UPDATE,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_UPDATE,
  REDUX_MSG_NPVR_STATE_UPDATE,
  REDUX_MSG_QUOTA_PVR_UPDATE,
} from '../constants';
import type { ETAG_MAP } from '../../../libs/netgemLibrary/v8/types/ETag';
import type { NETGEM_API_DMS_QUOTA_PVR } from '../../../libs/netgemLibrary/dms/types/QuotaPvr';
import type { NETGEM_API_V8_RAW_FEED } from '../../../libs/netgemLibrary/v8/types/FeedResult';

export type NpvrAction =
  // Default action
  | {| type: null |}
  // Update list of existing recordings
  | {|
      npvrRecordingsList: NETGEM_RECORDINGS_MAP,
      npvrRecordingsListETag: ?string,
      npvrRecordingsListFeed: NETGEM_API_V8_RAW_FEED,
      type: typeof REDUX_MSG_NPVR_RECORDINGS_LIST_UPDATE,
    |}
  // Update list of future recordings
  | {|
      npvrRecordingsFuture: NETGEM_RECORDINGS_MAP,
      npvrRecordingsFutureETag: ?string,
      npvrRecordingsFutureFeed: NETGEM_API_V8_RAW_FEED,
      npvrRecordingsFutureStatus: NETGEM_FUTURE_RECORDINGS_STATUS,
      type: typeof REDUX_MSG_NPVR_RECORDINGS_FUTURE_UPDATE,
    |}
  // Update nPVR state
  | {|
      endMargin: number,
      isEnabled: boolean,
      keepFromReplay: boolean,
      recFromBeginning: boolean,
      startMargin: number,
      type: typeof REDUX_MSG_NPVR_STATE_UPDATE,
    |}
  // Update quota
  | {|
      quotaPvr: ?NETGEM_API_DMS_QUOTA_PVR,
      type: typeof REDUX_MSG_QUOTA_PVR_UPDATE,
    |}
  // Update list of scheduled recordings (obtained through v1)
  | {|
      scheduledRecordingETagList: ETAG_MAP,
      scheduledRecordingList: NETGEM_SCHEDULED_RECORDINGS_MAP,
      scheduledRecordingListETag: ?string,
      type: typeof REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_UPDATE,
    |}
  // Update list of scheduled recordings (obtained through v2, "list full" with metadata)
  | {|
      scheduledRecordingList: NETGEM_SCHEDULED_RECORDINGS_MAP,
      scheduledRecordingListFullETag: ?string,
      type: typeof REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_FULL_UPDATE,
    |};

const updateExistingRecordings: (npvrRecordingsList: NETGEM_RECORDINGS_MAP, npvrRecordingsListETag: ?string, npvrRecordingsListFeed: NETGEM_API_V8_RAW_FEED) => NpvrAction = (
  npvrRecordingsList,
  npvrRecordingsListETag,
  npvrRecordingsListFeed,
) => {
  return {
    npvrRecordingsList,
    npvrRecordingsListETag,
    npvrRecordingsListFeed,
    type: REDUX_MSG_NPVR_RECORDINGS_LIST_UPDATE,
  };
};

const updateFutureRecordings: (
  npvrRecordingsFuture: NETGEM_RECORDINGS_MAP,
  npvrRecordingsFutureETag: ?string,
  npvrRecordingsFutureFeed: NETGEM_API_V8_RAW_FEED,
  npvrRecordingsFutureStatus: NETGEM_FUTURE_RECORDINGS_STATUS,
) => NpvrAction = (npvrRecordingsFuture, npvrRecordingsFutureETag, npvrRecordingsFutureFeed, npvrRecordingsFutureStatus) => {
  return {
    npvrRecordingsFuture,
    npvrRecordingsFutureETag,
    npvrRecordingsFutureFeed,
    npvrRecordingsFutureStatus,
    type: REDUX_MSG_NPVR_RECORDINGS_FUTURE_UPDATE,
  };
};

const updateNpvrState: (isEnabled: boolean, recFromBeginning: boolean, keepFromReplay: boolean, startMargin: number, endMargin: number) => NpvrAction = (
  isEnabled,
  recFromBeginning,
  keepFromReplay,
  startMargin,
  endMargin,
) => {
  return {
    endMargin,
    isEnabled,
    keepFromReplay,
    recFromBeginning,
    startMargin,
    type: REDUX_MSG_NPVR_STATE_UPDATE,
  };
};

const updateQuotaPvr: (quotaPvr: ?NETGEM_API_DMS_QUOTA_PVR) => NpvrAction = (quotaPvr) => {
  return {
    quotaPvr,
    type: REDUX_MSG_QUOTA_PVR_UPDATE,
  };
};

const updateScheduledRecordings: (scheduledRecordingETagList: ETAG_MAP, scheduledRecordingList: NETGEM_SCHEDULED_RECORDINGS_MAP, scheduledRecordingListETag: ?string) => NpvrAction = (
  scheduledRecordingETagList,
  scheduledRecordingList,
  scheduledRecordingListETag,
) => {
  return {
    scheduledRecordingETagList,
    scheduledRecordingList,
    scheduledRecordingListETag,
    type: REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_UPDATE,
  };
};

const updateScheduledRecordingsFull: (scheduledRecordingList: NETGEM_SCHEDULED_RECORDINGS_MAP, scheduledRecordingListFullETag: ?string) => NpvrAction = (
  scheduledRecordingList,
  scheduledRecordingListFullETag,
) => {
  return {
    scheduledRecordingList,
    scheduledRecordingListFullETag,
    type: REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_FULL_UPDATE,
  };
};

export { updateExistingRecordings, updateFutureRecordings, updateNpvrState, updateQuotaPvr, updateScheduledRecordings, updateScheduledRecordingsFull };
