/* @flow */

import {
  REDUX_MSG_CNC_PROGRAM_GET_URL,
  REDUX_MSG_CNC_SERIES_GET_URL,
  REDUX_MSG_DATA_COLLECTION_COLD_POST_URL,
  REDUX_MSG_DATA_COLLECTION_HOT_POST_URL,
  REDUX_MSG_DEEPLINK_PROGRAM_GET_URL,
  REDUX_MSG_DEEPLINK_SERIES_GET_URL,
  REDUX_MSG_DEFAULT_HUB_URL,
  REDUX_MSG_DISTRIBUTOR_ADD,
  REDUX_MSG_GAMESTREAM_TOKEN_GET_URL,
  REDUX_MSG_HUB_URL,
  REDUX_MSG_LIST_ALIAS_MULTI_URL,
  REDUX_MSG_LIST_ALIAS_URL,
  REDUX_MSG_LOCATION_CATCHUP_FOR_ASSET_URL,
  REDUX_MSG_LOCATION_EPG_FOR_ASSET_URL,
  REDUX_MSG_LOCATION_EPG_URL,
  REDUX_MSG_LOCATION_VOD_FOR_ASSET_URL,
  REDUX_MSG_METADATA_CHANNELS_URL,
  REDUX_MSG_METADATA_IMAGE_WITH_VARIANT_URL,
  REDUX_MSG_METADATA_PROGRAM_URL,
  REDUX_MSG_METADATA_SCHEDULE_URL,
  REDUX_MSG_NPVR_RECORDINGS_DELETE_URL,
  REDUX_MSG_NPVR_RECORDINGS_FUTURE_URL,
  REDUX_MSG_NPVR_RECORDINGS_LIST_URL,
  REDUX_MSG_NPVR_RECORDINGS_METADATA_URL,
  REDUX_MSG_NPVR_RECORDINGS_QUOTA_URL,
  REDUX_MSG_NPVR_RECORDINGS_RETRY_URL,
  REDUX_MSG_NPVR_RECORDINGS_STOP_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_CREATE_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_DELETE_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_FULL_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_METADATA_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_UPDATE_URL,
  REDUX_MSG_PERSONAL_DATA_DELETE_URL,
  REDUX_MSG_PERSONAL_DATA_GET_URL,
  REDUX_MSG_PERSONAL_DATA_LIST_DELETE_URL,
  REDUX_MSG_PERSONAL_DATA_LIST_GET_URL,
  REDUX_MSG_PERSONAL_DATA_LIST_POST_URL,
  REDUX_MSG_PERSONAL_DATA_POST_URL,
  REDUX_MSG_PURCHASE_INFO_PER_ASSET_URL,
  REDUX_MSG_REQUEST_ENTITLEMENT_GET_URL,
  REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE_URL,
  REDUX_MSG_RIGHT_COMMERCIAL_OFFERS_URL,
  REDUX_MSG_RIGHT_DEFAULT_RIGHTS_URL,
  REDUX_MSG_RIGHT_USER_RIGHTS_URL,
} from '../constants';
import type { Distributor } from './videofutur/types/common';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';

type REDUX_MSG_NTG_URL_TYPES =
  | typeof REDUX_MSG_CNC_PROGRAM_GET_URL
  | typeof REDUX_MSG_CNC_SERIES_GET_URL
  | typeof REDUX_MSG_DATA_COLLECTION_COLD_POST_URL
  | typeof REDUX_MSG_DATA_COLLECTION_HOT_POST_URL
  | typeof REDUX_MSG_DEEPLINK_PROGRAM_GET_URL
  | typeof REDUX_MSG_DEEPLINK_SERIES_GET_URL
  | typeof REDUX_MSG_DEFAULT_HUB_URL
  | typeof REDUX_MSG_GAMESTREAM_TOKEN_GET_URL
  | typeof REDUX_MSG_HUB_URL
  | typeof REDUX_MSG_LIST_ALIAS_MULTI_URL
  | typeof REDUX_MSG_LIST_ALIAS_URL
  | typeof REDUX_MSG_LOCATION_CATCHUP_FOR_ASSET_URL
  | typeof REDUX_MSG_LOCATION_EPG_FOR_ASSET_URL
  | typeof REDUX_MSG_LOCATION_EPG_URL
  | typeof REDUX_MSG_LOCATION_VOD_FOR_ASSET_URL
  | typeof REDUX_MSG_METADATA_CHANNELS_URL
  | typeof REDUX_MSG_METADATA_IMAGE_WITH_VARIANT_URL
  | typeof REDUX_MSG_METADATA_PROGRAM_URL
  | typeof REDUX_MSG_METADATA_SCHEDULE_URL
  | typeof REDUX_MSG_NPVR_RECORDINGS_DELETE_URL
  | typeof REDUX_MSG_NPVR_RECORDINGS_FUTURE_URL
  | typeof REDUX_MSG_NPVR_RECORDINGS_LIST_URL
  | typeof REDUX_MSG_NPVR_RECORDINGS_METADATA_URL
  | typeof REDUX_MSG_NPVR_RECORDINGS_QUOTA_URL
  | typeof REDUX_MSG_NPVR_RECORDINGS_RETRY_URL
  | typeof REDUX_MSG_NPVR_RECORDINGS_STOP_URL
  | typeof REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_CREATE_URL
  | typeof REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_DELETE_URL
  | typeof REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_FULL_URL
  | typeof REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_URL
  | typeof REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_METADATA_URL
  | typeof REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_UPDATE_URL
  | typeof REDUX_MSG_PERSONAL_DATA_DELETE_URL
  | typeof REDUX_MSG_PERSONAL_DATA_GET_URL
  | typeof REDUX_MSG_PERSONAL_DATA_LIST_DELETE_URL
  | typeof REDUX_MSG_PERSONAL_DATA_LIST_GET_URL
  | typeof REDUX_MSG_PERSONAL_DATA_LIST_POST_URL
  | typeof REDUX_MSG_PERSONAL_DATA_POST_URL
  | typeof REDUX_MSG_PURCHASE_INFO_PER_ASSET_URL
  | typeof REDUX_MSG_REQUEST_ENTITLEMENT_GET_URL
  | typeof REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE_URL
  | typeof REDUX_MSG_RIGHT_COMMERCIAL_OFFERS_URL
  | typeof REDUX_MSG_RIGHT_DEFAULT_RIGHTS_URL
  | typeof REDUX_MSG_RIGHT_USER_RIGHTS_URL;

export type NetgemApiAction =
  // Default action
  | {| type: null |}
  // Set URL definition for given type (Netgem)
  | {|
      type: REDUX_MSG_NTG_URL_TYPES,
      urlDefinition: ?NETGEM_API_V8_URL_DEFINITION,
    |}
  // Add a new distributor definition (customer Id, product Id and set of URLs)
  | {|
      distributorId: string,
      distributorDefinition: Distributor,
      type: typeof REDUX_MSG_DISTRIBUTOR_ADD,
    |};

const setNtgUrlDefinition: (type: REDUX_MSG_NTG_URL_TYPES, urlDefinition: ?NETGEM_API_V8_URL_DEFINITION) => NetgemApiAction = (type, urlDefinition) => {
  return {
    type: (type: REDUX_MSG_NTG_URL_TYPES),
    urlDefinition,
  };
};

const addDistributor: (distributorId: string, distributorDefinition: Distributor) => NetgemApiAction = (distributorId, distributorDefinition) => {
  return {
    distributorDefinition,
    distributorId,
    type: REDUX_MSG_DISTRIBUTOR_ADD,
  };
};

export { setNtgUrlDefinition, addDistributor };
