/* @flow */

import * as React from 'react';
import { BroadcastStatus, getBroadcastStatusFromTimes } from '../../../../helpers/ui/location/Format';
import { HeightKind, WidthKind } from '../../../../components/buttons/types';
import { ItemType, type NETGEM_API_V8_FEED_ITEM, type NETGEM_API_V8_ITEM_LOCATION } from '../../../../libs/netgemLibrary/v8/types/FeedItem';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../../../libs/netgemLibrary/v8/types/MetadataProgram';
import {
  type NETGEM_API_V8_SCHEDULED_RECORDING,
  type NETGEM_RECORDINGS_MAP,
  type NETGEM_SCHEDULED_RECORDINGS_MAP,
  RecordingOutcome,
  ScheduledRecordingsKind,
  type ScheduledRecordingsKindType,
} from '../../../../libs/netgemLibrary/v8/types/Npvr';
import { type NPVR_MODAL_RESULT, NpvrModalDialogResult } from '../../../../components/modal/npvrModal/NpvrModal';
import { RecordingKind, type SCHEDULED_RECORDING_CORE_SETTINGS, type SCHEDULED_RECORDING_CREATION_SETTINGS } from '../../../../helpers/npvr/Types';
import { SCHEDULED_RECORDING_CHECK_TIMEOUT, SCHEDULED_RECORDING_MAX_CHECK_COUNT } from '../../../../helpers/npvr/Constants';
import { deleteRecording, getExistingRecordings, getFutureRecordings, sendV8RecordingsMetadataRequest } from './recordings';
import { deleteScheduledRecording, getRecordId, sendV8ScheduledRecordingCreateRequest, startSeriesScheduledRecording, stopSeriesScheduledRecording } from './scheduledRecordings';
import { getEpisodeAndSeriesRecordStatus, searchRecordingInExisting, searchScheduledRecordingInFuture } from '../../../../helpers/npvr/recording';
import { getEpisodeIndexAndTitle, getTitle } from '../../../../helpers/ui/metadata/Format';
import { getIso8601DateInSeconds, getIso8601DurationInSeconds } from '../../../../helpers/dateTime/Format';
import { showConfirmationModal, showNpvrModal } from '../../../modal/actions';
import ButtonFX from '../../../../components/buttons/ButtonFX';
import type { CombinedReducers } from '../../../reducers';
import { ConfirmationModalResult } from '../../../../components/modal/confirmationModal/ConfirmationModal';
import { CustomNetworkError } from '../../../../libs/netgemLibrary/helpers/CustomNetworkError';
import type { Dispatch } from '../../../types/types';
import { HttpStatus } from '../../../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import type { InternalActionDefinitionType } from '../emitter';
import { Localizer } from '@ntg/utils/dist/localization';
import { ModalIcon } from '../../../../components/modal/modalTypes';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { getRoundedNowToISOString } from '../../../../libs/netgemLibrary/v8/helpers/Date';
import { isSeriesScheduledRecordingRunning } from '../../../../helpers/npvr/scheduledRecording';

export type RECORD_ITEM_TYPE = {|
  broadcastStatus: BroadcastStatus,
  catchupId?: string,
  finishedOperationEventName: string,
  item: NETGEM_API_V8_FEED_ITEM,
  kind: ?ScheduledRecordingsKindType,
  previewCatchupScheduledEventId: ?string,
  programMetadata: NETGEM_API_V8_METADATA_PROGRAM,
  seriesMetadata: ?NETGEM_API_V8_METADATA_SERIES,
  settings?: SCHEDULED_RECORDING_CORE_SETTINGS | null,
|};

type OUTCOMES_TYPE = {|
  futureOutcome: ?RecordingOutcome,
  keepFromReplayOutcome: ?RecordingOutcome,
  liveOutcome: ?RecordingOutcome,
  recordId: ?string,
  scheduledRecordingId: ?string,
  seriesOutcome: ?RecordingOutcome,
|};

const startRecord: (recordItem: RECORD_ITEM_TYPE, isDebugModeEnabled: boolean) => InternalActionDefinitionType = (recordItem, isDebugModeEnabled) => (dispatch: Dispatch) => {
  const {
    finishedOperationEventName,
    item: { type },
    kind,
    seriesMetadata,
  } = recordItem;

  if (!type) {
    Messenger.emit(finishedOperationEventName);
    return;
  }

  if (kind === ScheduledRecordingsKind.Single || kind === ScheduledRecordingsKind.KeepFromReplay || (type === ItemType.Program && !seriesMetadata)) {
    // Program card
    dispatch(recordSingle(recordItem));
  } else if (kind === ScheduledRecordingsKind.Series && !isDebugModeEnabled) {
    // Series card
    dispatch(recordSeries(recordItem));
  } else {
    // All other cases
    dispatch(promptUser(recordItem));
  }
};

const stopRecord: (recordItem: RECORD_ITEM_TYPE) => InternalActionDefinitionType = (recordItem) => (dispatch: Dispatch) => {
  const {
    finishedOperationEventName,
    item: { type },
    kind,
    seriesMetadata,
  } = recordItem;

  if (!type) {
    Messenger.emit(finishedOperationEventName);
    return;
  }

  if (kind === ScheduledRecordingsKind.Single || kind === ScheduledRecordingsKind.KeepFromReplay || (type === ItemType.Program && !seriesMetadata)) {
    // Program card
    dispatch(deleteSingle(recordItem));
  } else if (kind === ScheduledRecordingsKind.Series) {
    // Series card
    dispatch(stopSeries(recordItem));
  } else {
    // All other cases
    dispatch(promptUser(recordItem));
  }
};

const promptUser: (recordItem: RECORD_ITEM_TYPE) => InternalActionDefinitionType = (recordItem) => (dispatch: Dispatch, getState: () => CombinedReducers) => {
  const {
    npvr: { npvrRecordingsFuture, npvrRecordingsList, npvrScheduledRecordingsList },
  } = getState();
  const {
    broadcastStatus,
    finishedOperationEventName,
    item,
    item: { selectedProgramId: programId },
    kind,
    previewCatchupScheduledEventId,
    programMetadata,
    seriesMetadata,
  } = recordItem;

  if (!programMetadata || !seriesMetadata || !programId) {
    Messenger.emit(finishedOperationEventName);
    return;
  }

  const { hasRecording, hasScheduledRecording, hasSeriesScheduledRecording } = getEpisodeAndSeriesRecordStatus(
    programId,
    seriesMetadata.id,
    item,
    npvrRecordingsList,
    npvrRecordingsFuture,
    npvrScheduledRecordingsList,
    previewCatchupScheduledEventId,
  );

  const seriesTitle = getTitle(seriesMetadata, Localizer.language);
  const { episodeIndex, episodeTitle } = getEpisodeIndexAndTitle(programMetadata, seriesTitle, Localizer.language);

  const npvrData = {
    episodeIndex,
    episodeTitle,
    isEpisodeDeletable: hasRecording || hasScheduledRecording,
    isEpisodeRecordable:
      !hasRecording &&
      !hasScheduledRecording &&
      !hasSeriesScheduledRecording &&
      (broadcastStatus === BroadcastStatus.Live || broadcastStatus === BroadcastStatus.Future || broadcastStatus === BroadcastStatus.Preview),
    isSeriesRecordable: !hasSeriesScheduledRecording,
    kind,
    seriesTitle,
  };

  const confirmationClosedCallback = (result?: NPVR_MODAL_RESULT) => {
    const { dialogResult, settings } = result || {
      dialogResult: NpvrModalDialogResult.Cancel,
      settings: undefined,
    };

    dispatch(
      npvrConfirmationClosedCallback(
        {
          ...recordItem,
          settings,
        },
        dialogResult,
      ),
    );
  };

  Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, confirmationClosedCallback);
  dispatch(showNpvrModal(npvrData));
};

const npvrConfirmationClosedCallback: (recordItem: RECORD_ITEM_TYPE, result: NpvrModalDialogResult) => InternalActionDefinitionType = (recordItem, result) => (dispatch: Dispatch) => {
  const { finishedOperationEventName } = recordItem;

  switch (result) {
    case NpvrModalDialogResult.DeleteEpisode:
      dispatch(deleteSingle(recordItem));
      break;

    case NpvrModalDialogResult.StopSeries:
      dispatch(stopSeries(recordItem));
      break;

    case NpvrModalDialogResult.RecordEpisode:
      dispatch(recordSingle(recordItem));
      break;

    case NpvrModalDialogResult.RecordSeries:
      dispatch(recordSeries(recordItem));
      break;

    case NpvrModalDialogResult.Cancel:
      Messenger.emit(finishedOperationEventName);
      break;

    // No default
  }
};

const recordSingle: (recordItem: RECORD_ITEM_TYPE) => InternalActionDefinitionType = (recordItem) => (dispatch: Dispatch) => {
  const {
    catchupId,
    finishedOperationEventName,
    item: {
      selectedLocation: { id: scheduledEventId = '' },
    },
    kind,
    previewCatchupScheduledEventId,
    settings,
  } = recordItem;

  let target = scheduledEventId;
  if (catchupId) {
    // Keep from replay
    target = catchupId;
  } else if (previewCatchupScheduledEventId) {
    // Preview catchup
    target = previewCatchupScheduledEventId;
  }

  const creationSettings: SCHEDULED_RECORDING_CREATION_SETTINGS = {
    ...settings,
    // KeepFromReplay or Single (default)
    scheduledRecordKind: kind ?? ScheduledRecordingsKind.Single,
    target,
  };

  dispatch(sendV8ScheduledRecordingCreateRequest(creationSettings))
    .then((result: NETGEM_API_V8_SCHEDULED_RECORDING) => {
      const recordId = getRecordId(result);
      const { id } = result;

      const outcomes: OUTCOMES_TYPE = {
        futureOutcome: null,
        keepFromReplayOutcome: null,
        liveOutcome: null,
        recordId,
        scheduledRecordingId: id,
        seriesOutcome: null,
      };

      if (recordId) {
        if (kind === ScheduledRecordingsKind.KeepFromReplay) {
          // Program is past (keep from replay): let's check if it's in the existing recordings
          outcomes.keepFromReplayOutcome = RecordingOutcome.Unknown;
          setTimeout(() => dispatch(checkKeepFromReplay(recordItem, outcomes)), SCHEDULED_RECORDING_CHECK_TIMEOUT);
        } else {
          // Program is live: let's check that it's actually being recorded
          outcomes.liveOutcome = RecordingOutcome.Unknown;
          setTimeout(() => dispatch(checkLiveRecording(recordItem, RecordingKind.Single, outcomes)), SCHEDULED_RECORDING_CHECK_TIMEOUT);
        }
      } else {
        // Program is a future: scheduled recording has been created
        outcomes.futureOutcome = RecordingOutcome.Unknown;
        setTimeout(() => dispatch(checkFutureRecording(recordItem, RecordingKind.Single, outcomes)), SCHEDULED_RECORDING_CHECK_TIMEOUT);
      }
    })
    .catch((error: CustomNetworkError) => {
      const {
        message,
        networkError: { message: networkMsg },
      } = error;
      Messenger.emit(finishedOperationEventName);
      Messenger.emit(
        MessengerEvents.NOTIFY_ERROR,
        <>
          <div>{Localizer.localize('recording.single.create.error')}</div>
          <div>
            <b>{networkMsg ?? message}</b>
          </div>
        </>,
      );
    });
};

const recordSeries: (recordItem: RECORD_ITEM_TYPE) => InternalActionDefinitionType = (recordItem) => (dispatch: Dispatch, getState: () => CombinedReducers) => {
  const {
    npvr: { npvrScheduledRecordingsList },
  } = getState();
  const {
    finishedOperationEventName,
    item: {
      selectedLocation: { channelId },
      seriesId,
    },
  } = recordItem;

  if (!channelId || !seriesId) {
    Messenger.emit(finishedOperationEventName);
    return;
  }

  // Check first if the series already has a matching scheduled recording that has been previously stopped
  const { [seriesId]: seriesScheduledRecording } = npvrScheduledRecordingsList;
  if (!seriesScheduledRecording) {
    // Create a new scheduled recording
    dispatch(createSeriesScheduledRecording(recordItem));
  } else if (!isSeriesScheduledRecordingRunning(seriesScheduledRecording)) {
    // Restart the scheduled recording only if it has indeed been stopped (if it's not the case, there's a bug out there...)
    dispatch(restartSeriesScheduledRecording(recordItem, seriesScheduledRecording.id));
  }
};

const createSeriesScheduledRecording: (recordItem: RECORD_ITEM_TYPE) => InternalActionDefinitionType = (recordItem) => (dispatch: Dispatch) => {
  const {
    finishedOperationEventName,
    item: {
      selectedLocation: { channelId },
      seriesId,
    },
    settings,
  } = recordItem;

  if (!seriesId) {
    return;
  }

  const creationSettings: SCHEDULED_RECORDING_CREATION_SETTINGS = {
    ...settings,
    channelId,
    fromUtc: getRoundedNowToISOString(),
    scheduledRecordKind: ScheduledRecordingsKind.Series,
    target: seriesId,
  };

  dispatch(sendV8ScheduledRecordingCreateRequest(creationSettings))
    .then((result: NETGEM_API_V8_SCHEDULED_RECORDING) => {
      const recordId = getRecordId(result);
      const { id } = result;

      const outcomes: OUTCOMES_TYPE = {
        futureOutcome: null,
        keepFromReplayOutcome: null,
        liveOutcome: null,
        recordId,
        scheduledRecordingId: id,
        seriesOutcome: RecordingOutcome.WillBeRecorded,
      };

      let recordingType: RecordingKind = RecordingKind.Series;

      if (recordId) {
        // Series recording triggered a live recording: let's check that it's actually being recorded
        recordingType = RecordingKind.SeriesWithSingle;
        outcomes.liveOutcome = RecordingOutcome.Unknown;
        setTimeout(() => dispatch(checkLiveRecording(recordItem, RecordingKind.SeriesWithSingle, outcomes)), SCHEDULED_RECORDING_CHECK_TIMEOUT);
      } else {
        // No episode of this series is currently live
        outcomes.futureOutcome = RecordingOutcome.Unknown;
        setTimeout(() => dispatch(checkFutureRecording(recordItem, recordingType, outcomes)), SCHEDULED_RECORDING_CHECK_TIMEOUT);
      }
    })
    .catch((error: CustomNetworkError) => {
      const {
        message,
        networkError: { message: networkMsg },
      } = error;
      Messenger.emit(finishedOperationEventName);
      Messenger.emit(
        MessengerEvents.NOTIFY_ERROR,
        <>
          <div>{Localizer.localize('recording.series.create.error')}</div>
          <div>
            <b>{networkMsg ?? message}</b>
          </div>
        </>,
      );
    });
};

const restartSeriesScheduledRecording: (recordItem: RECORD_ITEM_TYPE, seriesScheduledRecordingId: string) => InternalActionDefinitionType =
  (recordItem, seriesScheduledRecordingId) => (dispatch: Dispatch) => {
    const { finishedOperationEventName, settings } = recordItem;

    const updateSettings = {
      ...settings,
      assetId: seriesScheduledRecordingId,
    };

    dispatch(startSeriesScheduledRecording(updateSettings))
      .then(() => {
        Messenger.emit(finishedOperationEventName);
        Messenger.emit(MessengerEvents.REFRESH_NPVR);
      })
      .catch((error: CustomNetworkError) => {
        const status = error.getStatus();
        Messenger.emit(finishedOperationEventName);
        if (status === HttpStatus.NotFound) {
          // Series scheduled recording seems to have been deleted somehow: let's try to create it instead of restarting it
          dispatch(createSeriesScheduledRecording(recordItem));
        } else {
          Messenger.emit(
            MessengerEvents.NOTIFY_ERROR,
            <>
              <div>{Localizer.localize('recording.series.create.error')}</div>
              <div>
                <b>{status}</b>
              </div>
            </>,
          );
        }
      });
  };

const checkLiveRecording =
  (recordItem: RECORD_ITEM_TYPE, recordingType: RecordingKind, outcomes: OUTCOMES_TYPE, checkCount: number = 0): InternalActionDefinitionType =>
  (dispatch: Dispatch) => {
    const { finishedOperationEventName } = recordItem;
    const { recordId } = outcomes;

    if (!recordId) {
      Messenger.emit(finishedOperationEventName);
      return;
    }

    if (checkCount === SCHEDULED_RECORDING_MAX_CHECK_COUNT) {
      // Max attempts reached
      outcomes.liveOutcome = RecordingOutcome.ServerError;

      if (recordingType === RecordingKind.Single) {
        // Single recording
        Messenger.emit(finishedOperationEventName);
        showSingleRecordingError(outcomes);
      } else {
        // Live recording failed, but it's a series: check future recording(s)
        dispatch(checkFutureRecording(recordItem, recordingType, outcomes));
      }
      return;
    }

    dispatch(sendV8RecordingsMetadataRequest(recordId))
      .then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const result = (response.result: NETGEM_API_V8_METADATA_SCHEDULE);
        const {
          location: { recordOutcome },
        } = result;

        outcomes.liveOutcome = recordOutcome;

        if (recordOutcome === RecordingOutcome.Recorded) {
          // Live recording is OK

          if (recordingType === RecordingKind.Single) {
            // Single recording
            Messenger.emit(finishedOperationEventName);
            Messenger.emit(MessengerEvents.REFRESH_NPVR);
            Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, <div>{Localizer.localize('recording.single.create.success_live')}</div>);
          } else {
            // Series recording + live recording
            outcomes.liveOutcome = RecordingOutcome.Recorded;
            outcomes.seriesOutcome = RecordingOutcome.Recorded;
            outcomes.futureOutcome = RecordingOutcome.Unknown;
            dispatch(checkFutureRecording(recordItem, recordingType, outcomes));
          }
        } else if (recordOutcome === RecordingOutcome.Unknown) {
          // Live recording state is still unknown: wait a little bit more
          setTimeout(() => dispatch(checkLiveRecording(recordItem, recordingType, outcomes, checkCount + 1)), SCHEDULED_RECORDING_CHECK_TIMEOUT);
        } else if (recordingType === RecordingKind.Single) {
          // Live recording failed
          Messenger.emit(finishedOperationEventName);
          showSingleRecordingError(outcomes);
        } else {
          // Live recording failed but series recording might be OK
          outcomes.futureOutcome = RecordingOutcome.Unknown;
          dispatch(checkFutureRecording(recordItem, recordingType, outcomes));
        }
      })
      .catch(() => Messenger.emit(finishedOperationEventName));
  };

const checkKeepFromReplay =
  (recordItem: RECORD_ITEM_TYPE, outcomes: OUTCOMES_TYPE, checkCount: number = 0): InternalActionDefinitionType =>
  (dispatch: Dispatch) => {
    const { finishedOperationEventName } = recordItem;
    const { recordId } = outcomes;

    if (!recordId) {
      Messenger.emit(finishedOperationEventName);
      return;
    }

    if (checkCount === SCHEDULED_RECORDING_MAX_CHECK_COUNT) {
      // Max attempts reached
      outcomes.keepFromReplayOutcome = RecordingOutcome.ServerError;

      Messenger.emit(finishedOperationEventName);
      showKeepFromReplayError(outcomes);
      return;
    }

    dispatch(getExistingRecordings())
      .then((existingRecordings: NETGEM_RECORDINGS_MAP | null) => {
        // Search for scheduledRecordingId
        const { isRecordingPresent, recordingOutcome } = searchRecordingInExisting(recordId, existingRecordings);

        if (!isRecordingPresent && recordingOutcome !== RecordingOutcome.Recorded) {
          setTimeout(() => dispatch(checkKeepFromReplay(recordItem, outcomes, checkCount + 1)), SCHEDULED_RECORDING_CHECK_TIMEOUT);
          return;
        }

        outcomes.keepFromReplayOutcome = recordingOutcome;

        Messenger.emit(finishedOperationEventName);
        Messenger.emit(MessengerEvents.REFRESH_NPVR);
        Messenger.emit(MessengerEvents.REFRESH_RECORDINGS_SECTION);
        if (recordingOutcome === RecordingOutcome.Recorded) {
          Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, <div>{Localizer.localize('tv.keep_from_replay.saved')}</div>);
        } else {
          showKeepFromReplayError(outcomes);
        }
      })
      .catch(() => {
        Messenger.emit(finishedOperationEventName);
        return Promise.resolve();
      });
  };

const checkFutureRecording =
  (recordItem: RECORD_ITEM_TYPE, recordingType: RecordingKind, outcomes: OUTCOMES_TYPE, checkCount: number = 0): InternalActionDefinitionType =>
  (dispatch: Dispatch) => {
    const { finishedOperationEventName } = recordItem;
    const { scheduledRecordingId } = outcomes;

    if (!scheduledRecordingId) {
      Messenger.emit(finishedOperationEventName);
      return;
    }

    if (checkCount === SCHEDULED_RECORDING_MAX_CHECK_COUNT) {
      // Max attempts reached
      outcomes.futureOutcome = RecordingOutcome.ServerError;

      if (recordingType === RecordingKind.Single) {
        // Single recording
        Messenger.emit(finishedOperationEventName);
        showSingleRecordingError(outcomes, scheduledRecordingId);
      } else {
        // Series recording: check if scheduled recording exists for the series
        outcomes.seriesOutcome = RecordingOutcome.ServerError;
        dispatch(checkSeriesRecordingOutcome(recordItem, recordingType, outcomes));
      }
      return;
    }

    dispatch(getFutureRecordings())
      .then((futureRecordings: NETGEM_RECORDINGS_MAP | null) => {
        // Search for scheduledRecordingId
        const { isScheduledRecordingFuturePresent, scheduledRecordingOutcome } = searchScheduledRecordingInFuture(scheduledRecordingId, futureRecordings);

        if (!isScheduledRecordingFuturePresent && scheduledRecordingOutcome !== RecordingOutcome.WillBeRecorded) {
          setTimeout(() => dispatch(checkFutureRecording(recordItem, recordingType, outcomes, checkCount + 1)), SCHEDULED_RECORDING_CHECK_TIMEOUT);
          return;
        }

        outcomes.futureOutcome = scheduledRecordingOutcome;

        if (recordingType === RecordingKind.Single) {
          // Single recording
          Messenger.emit(finishedOperationEventName);
          Messenger.emit(MessengerEvents.REFRESH_NPVR);
          if (scheduledRecordingOutcome === RecordingOutcome.WillBeRecorded) {
            Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, <div>{Localizer.localize('recording.single.create.success_future')}</div>);
          } else {
            showSingleRecordingError(outcomes, scheduledRecordingId);
          }
        } else {
          // Series recording (no future episodes available right now but scheduled recording has been created)
          outcomes.seriesOutcome = scheduledRecordingOutcome;
          dispatch(checkSeriesRecordingOutcome(recordItem, recordingType, outcomes));
        }
      })
      .catch(() => {
        if (recordingType === RecordingKind.Single) {
          // Single recording
          Messenger.emit(finishedOperationEventName);
          return Promise.resolve();
        }

        // Series recording
        return dispatch(checkSeriesRecordingOutcome(recordItem, recordingType, outcomes));
      });
  };

const checkSeriesRecordingOutcome: (recordItem: RECORD_ITEM_TYPE, recordingType: RecordingKind, outcomes: OUTCOMES_TYPE) => InternalActionDefinitionType =
  (recordItem, recordingType, outcomes) => () => {
    const { finishedOperationEventName } = recordItem;
    const { liveOutcome, scheduledRecordingId, seriesOutcome } = outcomes;

    if (!seriesOutcome || (recordingType === RecordingKind.SeriesWithSingle && !liveOutcome)) {
      return;
    }

    Messenger.emit(finishedOperationEventName);
    Messenger.emit(MessengerEvents.REFRESH_NPVR);

    if (
      seriesOutcome !== RecordingOutcome.WillBeRecorded ||
      (recordingType === RecordingKind.SeriesWithSingle && liveOutcome !== RecordingOutcome.Recorded && liveOutcome !== RecordingOutcome.Rebroadcast)
    ) {
      showSeriesRecordingError(outcomes, scheduledRecordingId);
    } else {
      Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, <div>{Localizer.localize('recording.series.create.success')}</div>);
    }
  };

const deleteSingle: (recordItem: RECORD_ITEM_TYPE) => InternalActionDefinitionType = (recordItem) => (dispatch: Dispatch, getState: () => CombinedReducers) => {
  const {
    item: { selectedProgramId: programId },
  } = recordItem;
  const {
    npvr: { npvrRecordingsList },
  } = getState();
  const { [programId]: recording } = npvrRecordingsList;

  if (recording) {
    // Live or finished recordings: ask confirmation
    const data = {
      button1Title: Localizer.localize('common.actions.cancel'),
      button2Title: Localizer.localize('common.actions.delete'),
      header: Localizer.localize('common.titles.delete_confirmation'),
      icon: ModalIcon.Record,
      question: Localizer.localize('recording.single.delete.confirmation_question'),
    };

    Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, (result: ConfirmationModalResult) => dispatch(deleteSingleConfirmationClosedCallback(recordItem, result)));
    dispatch(showConfirmationModal(data));
  } else {
    // Future recordings (i.e. scheduled recordings): no confirmation needed
    dispatch(deleteFutureRecordings(recordItem));
  }
};

const isMatching: (scheduledEventId: string, scheduledRecordingId: string, npvrScheduledRecordingsList: NETGEM_SCHEDULED_RECORDINGS_MAP) => boolean = (
  scheduledEventId,
  scheduledRecordingId,
  npvrScheduledRecordingsList,
) => {
  const {
    [scheduledEventId]: { id },
  } = npvrScheduledRecordingsList;

  return id === scheduledRecordingId;
};

const deleteSingleConfirmationClosedCallback: (recordItem: RECORD_ITEM_TYPE, result: ConfirmationModalResult) => InternalActionDefinitionType =
  (recordItem, result) => (dispatch: Dispatch, getState: () => CombinedReducers) => {
    const { finishedOperationEventName } = recordItem;

    if (result !== ConfirmationModalResult.Button2) {
      // Delete cancelled
      Messenger.emit(finishedOperationEventName);
      return;
    }

    const {
      item: { selectedProgramId: programId },
    } = recordItem;
    const {
      npvr: { npvrRecordingsList },
    } = getState();
    const { [programId]: recording } = npvrRecordingsList;

    // Specific scenario where "recording" becomes undefined: start live episode rec, start series rec, stop series rec and quickly delete live rec
    if (recording === undefined) {
      return;
    }

    dispatch(
      deleteExistingRecordings(
        recordItem,
        recording.map((r) => r.id),
      ),
    );
  };

const deleteExistingRecordings: (recordItem: RECORD_ITEM_TYPE, recordingIds: Array<string>, successMessage?: string) => InternalActionDefinitionType =
  (recordItem, recordingIds, successMessage) => (dispatch: Dispatch) => {
    const { finishedOperationEventName } = recordItem;
    const promises = recordingIds.map((id) => dispatch(deleteRecording(id)));

    Promise.all(promises)
      .then(() => {
        Messenger.emit(finishedOperationEventName);
        Messenger.emit(MessengerEvents.REFRESH_NPVR);
        Messenger.emit(MessengerEvents.REFRESH_RECORDINGS_SECTION);
        Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, <div>{successMessage || Localizer.localize('recording.single.delete.success')}</div>);
      })
      .catch((error: CustomNetworkError) => {
        const {
          message,
          networkError: { message: networkMsg },
        } = error;
        Messenger.emit(finishedOperationEventName);
        Messenger.emit(
          MessengerEvents.NOTIFY_ERROR,
          <>
            <div>{Localizer.localize('recording.single.delete.error')}</div>
            <div>
              <b>{networkMsg ?? message}</b>
            </div>
          </>,
        );
      });
  };

const deleteFutureRecordings: (recordItem: RECORD_ITEM_TYPE) => InternalActionDefinitionType = (recordItem) => (dispatch: Dispatch, getState: () => CombinedReducers) => {
  const {
    finishedOperationEventName,
    item: { selectedProgramId: programId },
  } = recordItem;
  const {
    npvr: { npvrRecordingsFuture, npvrScheduledRecordingsList },
  } = getState();
  const { [programId]: futureRecording } = npvrRecordingsFuture;

  if (futureRecording) {
    const scheduledRecordings = [];
    futureRecording.forEach((loc) => {
      const { id: scheduledEventId = '', matchingScheduledRecordings } = loc;

      if (matchingScheduledRecordings) {
        for (let i = 0; i < matchingScheduledRecordings.length; ++i) {
          const { [i]: sr } = matchingScheduledRecordings;
          const { id: scheduledRecordingId } = sr;

          if (isMatching(scheduledEventId, scheduledRecordingId, npvrScheduledRecordingsList)) {
            scheduledRecordings.push(scheduledRecordingId);
            break;
          }
        }
      }
    });

    const promises = scheduledRecordings.map((id) => dispatch(deleteScheduledRecording(id, true)));

    Promise.all(promises)
      .then(() => {
        Messenger.emit(finishedOperationEventName);
        Messenger.emit(MessengerEvents.REFRESH_NPVR);
        Messenger.emit(MessengerEvents.REFRESH_RECORDINGS_SECTION);
        Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, <div>{Localizer.localize('recording.single.delete.success_future')}</div>);
      })
      .catch((error: CustomNetworkError) => {
        const {
          message,
          networkError: { message: networkMsg },
        } = error;
        Messenger.emit(finishedOperationEventName);
        Messenger.emit(
          MessengerEvents.NOTIFY_ERROR,
          <>
            <div>{Localizer.localize('recording.single.delete.error_future')}</div>
            <div>
              <b>{networkMsg ?? message}</b>
            </div>
          </>,
        );
      });
  }
};

const stopSeries: (recordItem: RECORD_ITEM_TYPE) => InternalActionDefinitionType = (recordItem) => (dispatch: Dispatch) => {
  const data = {
    button1Title: Localizer.localize('common.actions.cancel'),
    button2Title: Localizer.localize('common.actions.delete'),
    icon: ModalIcon.Record,
    question: Localizer.localize('recording.series.stop.confirmation_question'),
  };

  Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, (result: ConfirmationModalResult) => dispatch(stopSeriesConfirmationClosedCallback(recordItem, result)));
  dispatch(showConfirmationModal(data));
};

const isLive: (item: NETGEM_API_V8_ITEM_LOCATION) => boolean = (item) => {
  const { scheduledEventDuration, scheduledEventStartDate } = item;

  if (!scheduledEventStartDate || !scheduledEventDuration) {
    return false;
  }

  const startTime = getIso8601DateInSeconds(scheduledEventStartDate);
  const endTime = startTime + getIso8601DurationInSeconds(scheduledEventDuration);

  return getBroadcastStatusFromTimes(startTime, endTime) === BroadcastStatus.Live;
};

const searchLiveRecording: (seriesScheduledRecording: NETGEM_API_V8_SCHEDULED_RECORDING, npvrRecordingsList: NETGEM_RECORDINGS_MAP) => ?Array<string> = (
  seriesScheduledRecording,
  npvrRecordingsList,
) => {
  const { records } = seriesScheduledRecording;

  for (const record of records) {
    const { id } = record;

    for (const recordingItem of Object.values(npvrRecordingsList)) {
      const typedRecordingItem = ((recordingItem: any): Array<NETGEM_API_V8_ITEM_LOCATION>);
      if (typedRecordingItem.some((item) => item.id === id && isLive(item))) {
        // $FlowFixMe: Ids can't be undefined here but flow couldn't see it
        return typedRecordingItem.map((item) => item.id);
      }
    }
  }

  return null;
};

const stopSeriesConfirmationClosedCallback: (recordItem: RECORD_ITEM_TYPE, result: ConfirmationModalResult) => InternalActionDefinitionType =
  (recordItem, result) => (dispatch: Dispatch, getState: () => CombinedReducers) => {
    const { finishedOperationEventName } = recordItem;

    if (result !== ConfirmationModalResult.Button2) {
      // Stop cancelled
      Messenger.emit(finishedOperationEventName);
      return;
    }

    const {
      item: { seriesId },
    } = recordItem;
    const {
      npvr: { npvrRecordingsList, npvrScheduledRecordingsList },
    } = getState();

    if (!seriesId) {
      Messenger.emit(finishedOperationEventName);
      return;
    }

    const { [seriesId]: seriesScheduledRecording } = npvrScheduledRecordingsList;

    if (!seriesScheduledRecording) {
      Messenger.emit(finishedOperationEventName);
      return;
    }

    // A recording is actually a list of recordings (although only one can be live, of course) and they all must be deleted
    const liveRecordingIds = searchLiveRecording(seriesScheduledRecording, npvrRecordingsList);

    const { id } = seriesScheduledRecording;
    const updateSettings = {
      assetId: id,
      toUtc: null,
    };

    dispatch(stopSeriesScheduledRecording(updateSettings))
      .then(() => {
        const successMessage = Localizer.localize('recording.series.stop.success');
        if (liveRecordingIds) {
          // Also delete the live recording associated to this scheduled recording
          dispatch(deleteExistingRecordings(recordItem, liveRecordingIds, successMessage));
          return;
        }

        Messenger.emit(finishedOperationEventName);
        Messenger.emit(MessengerEvents.REFRESH_NPVR);
        Messenger.emit(MessengerEvents.REFRESH_RECORDINGS_SECTION);
        Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, <div>{successMessage}</div>);
      })
      .catch((error: CustomNetworkError) => {
        const {
          message,
          networkError: { message: networkMsg },
        } = error;
        Messenger.emit(finishedOperationEventName);
        Messenger.emit(
          MessengerEvents.NOTIFY_ERROR,
          <>
            <div>{Localizer.localize('recording.series.stop.error')}</div>
            <div>
              <b>{networkMsg ?? message}</b>
            </div>
          </>,
        );
      });
  };

const handleCleanUpOnClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>, data: any) => void = (event, data) => {
  Messenger.emit(MessengerEvents.OPEN_NPVR_MANAGEMENT_SCREEN, data ? ((data: any): string) : undefined);
};

const showKeepFromReplayError: (outcomes: OUTCOMES_TYPE) => void = (outcomes) => {
  const { keepFromReplayOutcome } = outcomes;
  const message = Localizer.localize(
    keepFromReplayOutcome === RecordingOutcome.OutOfQuota ? 'recording.single.create.keep_from_replay_out_of_quota' : 'recording.single.create.keep_from_replay_error',
  );

  Messenger.emit(
    MessengerEvents.NOTIFY_ERROR,
    <>
      <div>{message}</div>
      <ButtonFX heightKind={HeightKind.Small} onClick={handleCleanUpOnClick} widthKind={WidthKind.Stretched}>
        {Localizer.localize('recording.cleanup')}
      </ButtonFX>
    </>,
  );

  Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div>{message}</div>);
};

const showSingleRecordingError: (outcomes: OUTCOMES_TYPE, scheduledRecordingId: ?string) => void = (outcomes, scheduledRecordingId) => {
  let message = Localizer.localize('common.messages.errors.retry');
  const futureDefaultMsg = Localizer.localize('recording.single.create.conflicts_future');

  const { liveOutcome, futureOutcome } = outcomes;
  const error = liveOutcome || futureOutcome;

  if (error === RecordingOutcome.ExceededConcurrency) {
    message = liveOutcome ? Localizer.localize('recording.single.create.exceeded_concurrency_live') : futureDefaultMsg;
  } else if (error === RecordingOutcome.OutOfQuota) {
    message = liveOutcome ? Localizer.localize('recording.single.create.out_of_quota_live') : futureDefaultMsg;
  }

  if (futureOutcome) {
    // Future single recording in danger: show a warning and suggest user to do some cleaning
    Messenger.emit(
      MessengerEvents.NOTIFY_WARNING,
      <>
        <div>{message}</div>
        <ButtonFX data={scheduledRecordingId} heightKind={HeightKind.Small} onClick={handleCleanUpOnClick} widthKind={WidthKind.Stretched}>
          {Localizer.localize('recording.cleanup')}
        </ButtonFX>
      </>,
    );
  } else {
    // Live single recording in error: show an error
    Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div>{message}</div>);
  }
};

const showSeriesRecordingError: (outcomes: OUTCOMES_TYPE, scheduledRecordingId: ?string) => void = (outcomes, scheduledRecordingId) => {
  const { liveOutcome, seriesOutcome } = outcomes;

  let liveErrorKey = 'recording.series.create.server_error_live';
  if (liveOutcome === RecordingOutcome.ExceededConcurrency) {
    liveErrorKey = 'recording.series.create.exceeded_concurrency_live';
  } else if (liveOutcome === RecordingOutcome.OutOfQuota) {
    liveErrorKey = 'recording.series.create.out_of_quota_live';
  }

  if (seriesOutcome === undefined || seriesOutcome === null || seriesOutcome === RecordingOutcome.ServerError) {
    // Error
    Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div>{Localizer.localize('common.messages.errors.retry')}</div>);
    return;
  }

  if (seriesOutcome === RecordingOutcome.ExceededConcurrency || seriesOutcome === RecordingOutcome.OutOfQuota) {
    // Too many scheduled recordings at the same time or not enough storage space
    Messenger.emit(
      MessengerEvents.NOTIFY_WARNING,
      <>
        <div>{Localizer.localize('recording.series.create.conflicts')}</div>
        <ButtonFX data={scheduledRecordingId} heightKind={HeightKind.Small} onClick={handleCleanUpOnClick} widthKind={WidthKind.Stretched}>
          {Localizer.localize('recording.cleanup')}
        </ButtonFX>
      </>,
    );
    return;
  }

  // Series scheduled recording is OK but the live program failed (RecordingOutcome.WillBeRecorded)
  Messenger.emit(
    MessengerEvents.NOTIFY_WARNING,
    <>
      <div>{Localizer.localize('recording.series.create.success')}</div>
      <div>{Localizer.localize(liveErrorKey)}</div>
    </>,
  );
};

export { startRecord, stopRecord };
