/* @flow */

// DMS

export const REDUX_MSG_REQUEST_GET_ASSOCIATED_DEVICES: 'NTG_RDX_METH_GET_ASSOCIATED_DEVICE_LIST_REQUEST' = 'NTG_RDX_METH_GET_ASSOCIATED_DEVICE_LIST_REQUEST';
export const REDUX_MSG_REQUEST_GET_DEVICE_CHANNELS: 'NTG_RDX_METH_GET_DEVICE_CHANNEL_LIST_REQUEST' = 'NTG_RDX_METH_GET_DEVICE_CHANNEL_LIST_REQUEST';
export const REDUX_MSG_REQUEST_GET_DEVICE_QUOTA_PVR: 'NTG_RDX_METH_GET_DEVICE_QUOTA_PVR_REQUEST' = 'NTG_RDX_METH_GET_DEVICE_QUOTA_PVR_REQUEST';
export const REDUX_MSG_REQUEST_GET_DEVICE_SETTINGS: 'NTG_RDX_METH_GET_DEVICE_SETTINGS_REQUEST' = 'NTG_RDX_METH_GET_DEVICE_SETTINGS_REQUEST';
export const REDUX_MSG_REQUEST_LOGIN: 'NTG_RDX_METH_SEND_LOGIN_REQUEST' = 'NTG_RDX_METH_SEND_LOGIN_REQUEST';
export const REDUX_MSG_REQUEST_LOGOUT: 'NTG_RDX_METH_SEND_LOGOUT_REQUEST' = 'NTG_RDX_METH_SEND_LOGOUT_REQUEST';

// ENTITLEMENT

export const REDUX_MSG_REQUEST_ENTITLEMENT_GET_URL: 'NTG_RDX_MSG_ENTITLEMENT_GET_URL' = 'NTG_RDX_MSG_ENTITLEMENT_GET_URL';
export const REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE_URL: 'NTG_RDX_MSG_ENTITLEMENT_RELEASE_URL' = 'NTG_RDX_MSG_ENTITLEMENT_RELEASE_URL';

export const REDUX_MSG_REQUEST_ENTITLEMENT_GET: 'NTG_RDX_METH_SEND_ENTITLEMENT_GET_REQUEST' = 'NTG_RDX_METH_SEND_ENTITLEMENT_GET_REQUEST';
export const REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE: 'NTG_RDX_METH_SEND_ENTITLEMENT_RELEASE_REQUEST' = 'NTG_RDX_METH_SEND_ENTITLEMENT_RELEASE_REQUEST';

// V8

export const REDUX_MSG_CNC_PROGRAM_GET_URL: 'NTG_RDX_MSG_CNC_PROGRAM_GET' = 'NTG_RDX_MSG_CNC_PROGRAM_GET';
export const REDUX_MSG_CNC_SERIES_GET_URL: 'NTG_RDX_MSG_CNC_SERIES_GET' = 'NTG_RDX_MSG_CNC_SERIES_GET';
export const REDUX_MSG_DATA_COLLECTION_COLD_POST_URL: 'NTG_RDX_MSG_DATA_COLLECTION_COLD_POST_URL' = 'NTG_RDX_MSG_DATA_COLLECTION_COLD_POST_URL';
export const REDUX_MSG_DATA_COLLECTION_HOT_POST_URL: 'NTG_RDX_MSG_DATA_COLLECTION_HOT_POST_URL' = 'NTG_RDX_MSG_DATA_COLLECTION_HOT_POST_URL';
export const REDUX_MSG_DEEPLINK_PROGRAM_GET_URL: 'NTG_RDX_MSG_DEEPLINK_PROGRAM_GET' = 'NTG_RDX_MSG_DEEPLINK_PROGRAM_GET';
export const REDUX_MSG_DEEPLINK_SERIES_GET_URL: 'NTG_RDX_MSG_DEEPLINK_SERIES_GET' = 'NTG_RDX_MSG_DEEPLINK_SERIES_GET';
export const REDUX_MSG_DEFAULT_HUB_URL: 'NTG_RDX_MSG_DEFAULT_HUB_URL' = 'NTG_RDX_MSG_DEFAULT_HUB_URL';
export const REDUX_MSG_DISTRIBUTOR_ADD: 'NTG_RDX_MSG_DISTRIBUTOR_ADD' = 'NTG_RDX_MSG_DISTRIBUTOR_ADD';
export const REDUX_MSG_GAMESTREAM_TOKEN_GET_URL: 'NTG_RDX_MSG_GAMESTREAM_TOKEN_GET' = 'NTG_RDX_MSG_GAMESTREAM_TOKEN_GET';
export const REDUX_MSG_HUB_URL: 'NTG_RDX_MSG_HUB_URL' = 'NTG_RDX_MSG_HUB_URL';
export const REDUX_MSG_LIST_ALIAS_MULTI_URL: 'NTG_RDX_MSG_LIST_ALIAS_MULTI_URL' = 'NTG_RDX_MSG_LIST_ALIAS_MULTI_URL';
export const REDUX_MSG_LIST_ALIAS_URL: 'NTG_RDX_MSG_LIST_ALIAS_URL' = 'NTG_RDX_MSG_LIST_ALIAS_URL';
export const REDUX_MSG_LOCATION_CATCHUP_FOR_ASSET_URL: 'NTG_RDX_MSG_LOCATION_CATCHUP_FOR_ASSET_URL' = 'NTG_RDX_MSG_LOCATION_CATCHUP_FOR_ASSET_URL';
export const REDUX_MSG_LOCATION_EPG_FOR_ASSET_URL: 'NTG_RDX_MSG_LOCATION_EPG_FOR_ASSET_URL' = 'NTG_RDX_MSG_LOCATION_EPG_FOR_ASSET_URL';
export const REDUX_MSG_LOCATION_EPG_URL: 'NTG_RDX_MSG_LOCATION_EPG_URL' = 'NTG_RDX_MSG_LOCATION_EPG_URL';
export const REDUX_MSG_LOCATION_VOD_FOR_ASSET_URL: 'NTG_RDX_MSG_LOCATION_VOD_FOR_ASSET_URL' = 'NTG_RDX_MSG_LOCATION_VOD_FOR_ASSET_URL';
export const REDUX_MSG_METADATA_CHANNELS_URL: 'NTG_RDX_MSG_METADATA_CHANNELS_URL' = 'NTG_RDX_MSG_METADATA_CHANNELS_URL';
export const REDUX_MSG_METADATA_IMAGE_WITH_VARIANT_URL: 'NTG_RDX_MSG_METADATA_IMAGE_WITH_VARIANT_URL' = 'NTG_RDX_MSG_METADATA_IMAGE_WITH_VARIANT_URL';
export const REDUX_MSG_METADATA_PROGRAM_URL: 'NTG_RDX_MSG_METADATA_PROGRAM_URL' = 'NTG_RDX_MSG_METADATA_PROGRAM_URL';
export const REDUX_MSG_METADATA_SCHEDULE_URL: 'NTG_RDX_MSG_METADATA_SCHEDULE_URL' = 'NTG_RDX_MSG_METADATA_SCHEDULE_URL';
export const REDUX_MSG_NPVR_RECORDINGS_DELETE_URL: 'NTG_RDX_MSG_RECORDINGS_DELETE_URL' = 'NTG_RDX_MSG_RECORDINGS_DELETE_URL';
export const REDUX_MSG_NPVR_RECORDINGS_FUTURE_URL: 'NTG_RDX_MSG_RECORDINGS_FUTURE_URL' = 'NTG_RDX_MSG_RECORDINGS_FUTURE_URL';
export const REDUX_MSG_NPVR_RECORDINGS_LIST_URL: 'NTG_RDX_MSG_RECORDINGS_LIST_URL' = 'NTG_RDX_MSG_RECORDINGS_LIST_URL';
export const REDUX_MSG_NPVR_RECORDINGS_METADATA_URL: 'NTG_RDX_MSG_RECORDINGS_METADATA_URL' = 'NTG_RDX_MSG_RECORDINGS_METADATA_URL';
export const REDUX_MSG_NPVR_RECORDINGS_QUOTA_URL: 'NTG_RDX_MSG_RECORDINGS_QUOTA_URL' = 'NTG_RDX_MSG_RECORDINGS_QUOTA_URL';
export const REDUX_MSG_NPVR_RECORDINGS_RETRY_URL: 'NTG_RDX_MSG_RECORDINGS_RETRY_URL' = 'NTG_RDX_MSG_RECORDINGS_RETRY_URL';
export const REDUX_MSG_NPVR_RECORDINGS_STOP_URL: 'NTG_RDX_MSG_RECORDINGS_STOP_URL' = 'NTG_RDX_MSG_RECORDINGS_STOP_URL';
export const REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_CREATE_URL: 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_CREATE_URL' = 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_CREATE_URL';
export const REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_DELETE_URL: 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_DELETE_URL' = 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_DELETE_URL';
export const REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_FULL_URL: 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_LIST_FULL_URL' = 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_LIST_FULL_URL';
export const REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_URL: 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_LIST_URL' = 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_LIST_URL';
export const REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_METADATA_URL: 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_METADATA_URL' = 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_METADATA_URL';
export const REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_UPDATE_URL: 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_UPDATE_URL' = 'NTG_RDX_MSG_SCHEDULED_RECORDINGS_UPDATE_URL';
export const REDUX_MSG_PERSONAL_DATA_DELETE_URL: 'NTG_RDX_MSG_PERSONAL_DATA_DELETE_URL' = 'NTG_RDX_MSG_PERSONAL_DATA_DELETE_URL';
export const REDUX_MSG_PERSONAL_DATA_GET_URL: 'NTG_RDX_MSG_PERSONAL_DATA_GET_URL' = 'NTG_RDX_MSG_PERSONAL_DATA_GET_URL';
export const REDUX_MSG_PERSONAL_DATA_LIST_DELETE_URL: 'NTG_RDX_MSG_PERSONAL_DATA_LIST_DELETE_URL' = 'NTG_RDX_MSG_PERSONAL_DATA_LIST_DELETE_URL';
export const REDUX_MSG_PERSONAL_DATA_LIST_GET_URL: 'NTG_RDX_MSG_PERSONAL_DATA_LIST_GET_URL' = 'NTG_RDX_MSG_PERSONAL_DATA_LIST_GET_URL';
export const REDUX_MSG_PERSONAL_DATA_LIST_POST_URL: 'NTG_RDX_MSG_PERSONAL_DATA_LIST_POST_URL' = 'NTG_RDX_MSG_PERSONAL_DATA_LIST_POST_URL';
export const REDUX_MSG_PERSONAL_DATA_POST_URL: 'NTG_RDX_MSG_PERSONAL_DATA_POST_URL' = 'NTG_RDX_MSG_PERSONAL_DATA_POST_URL';
export const REDUX_MSG_PURCHASE_INFO_PER_ASSET_URL: 'NTG_RDX_MSG_PURCHASE_PER_ASSET_URL' = 'NTG_RDX_MSG_PURCHASE_PER_ASSET_URL';
export const REDUX_MSG_RIGHT_COMMERCIAL_OFFERS_URL: 'NTG_RDX_MSG_RIGHT_COMMERCIAL_OFFERS_URL' = 'NTG_RDX_MSG_RIGHT_COMMERCIAL_OFFERS_URL';
export const REDUX_MSG_RIGHT_DEFAULT_RIGHTS_URL: 'NTG_RDX_MSG_RIGHT_DEFAULT_RIGHTS_URL' = 'NTG_RDX_MSG_RIGHT_DEFAULT_RIGHTS_URL';
export const REDUX_MSG_RIGHT_USER_RIGHTS_URL: 'NTG_RDX_MSG_RIGHT_USER_RIGHTS_URL' = 'NTG_RDX_MSG_RIGHT_USER_RIGHTS_URL';

export const REDUX_MSG_REQUEST_CUSTOM_STRATEGY: 'NTG_RDX_METH_SEND_V8_CUSTOM_STRATEGY_REQUEST' = 'NTG_RDX_METH_SEND_V8_CUSTOM_STRATEGY_REQUEST';
export const REDUX_MSG_REQUEST_DATA_COLLECTION: 'NTG_RDX_METH_SEND_V8_DATA_COLLECTION_REQUEST' = 'NTG_RDX_METH_SEND_V8_DATA_COLLECTION_REQUEST';
export const REDUX_MSG_REQUEST_DATA_COLLECTION_COLLECTOR_ID: 'NTG_RDX_METH_SEND_V8_DATA_COLLECTION_ID_REQUEST' = 'NTG_RDX_METH_SEND_V8_DATA_COLLECTION_ID_REQUEST';
export const REDUX_MSG_REQUEST_LIST_ALIAS_MULTI: 'NTG_RDX_METH_SEND_V8_LIST_ALIAS_MULTI_REQUEST' = 'NTG_RDX_METH_SEND_V8_LIST_ALIAS_MULTI_REQUEST';
export const REDUX_MSG_REQUEST_LIST_ALIAS_POST: 'NTG_RDX_METH_SEND_V8_LIST_ALIAS_POST_REQUEST' = 'NTG_RDX_METH_SEND_V8_LIST_ALIAS_POST_REQUEST';
export const REDUX_MSG_REQUEST_GENERIC: 'NTG_RDX_METH_SEND_V8_METADATA_CHANNELS_REQUEST' = 'NTG_RDX_METH_SEND_V8_METADATA_CHANNELS_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_RECORDINGS_DELETE: 'NTG_RDX_METH_SEND_V8_RECORDINGS_DELETE_REQUEST' = 'NTG_RDX_METH_SEND_V8_RECORDINGS_DELETE_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_RECORDINGS_FUTURE: 'NTG_RDX_METH_SEND_V8_RECORDINGS_FUTURE_REQUEST' = 'NTG_RDX_METH_SEND_V8_RECORDINGS_FUTURE_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_RECORDINGS_LIST: 'NTG_RDX_METH_SEND_V8_RECORDINGS_LIST_REQUEST' = 'NTG_RDX_METH_SEND_V8_RECORDINGS_LIST_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_RECORDINGS_METADATA: 'NTG_RDX_METH_SEND_V8_NPVR_RECORDINGS_METADATA_REQUEST' = 'NTG_RDX_METH_SEND_V8_NPVR_RECORDINGS_METADATA_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_RECORDINGS_QUOTA: 'NTG_RDX_METH_SEND_V8_NPVR_RECORDINGS_QUOTA_REQUEST' = 'NTG_RDX_METH_SEND_V8_NPVR_RECORDINGS_QUOTA_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_RECORDINGS_RETRY: 'NTG_RDX_METH_SEND_V8_NPVR_RECORDINGS_RETRY_REQUEST' = 'NTG_RDX_METH_SEND_V8_NPVR_RECORDINGS_RETRY_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_CREATE: 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_CREATE_REQUEST' = 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_CREATE_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_DELETE: 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_DELETE_REQUEST' = 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_DELETE_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_LIST: 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_LIST_REQUEST' = 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_LIST_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_LIST_FULL: 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_LIST_FULL_REQUEST' = 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_LIST_FULL_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_METADATA: 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_METADATA_REQUEST' = 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_METADATA_REQUEST';
export const REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_UPDATE: 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_UPDATE_REQUEST' = 'NTG_RDX_METH_SEND_V8_SCH_RECORDINGS_UPDATE_REQUEST';
export const REDUX_MSG_REQUEST_PERSONAL_DATA_DELETE: 'NTG_RDX_METH_SEND_PERSONAL_DATA_DELETE_REQUEST' = 'NTG_RDX_METH_SEND_PERSONAL_DATA_DELETE_REQUEST';
export const REDUX_MSG_REQUEST_PERSONAL_DATA_GET: 'NTG_RDX_METH_SEND_PERSONAL_DATA_GET_REQUEST' = 'NTG_RDX_METH_SEND_PERSONAL_DATA_GET_REQUEST';
export const REDUX_MSG_REQUEST_PERSONAL_DATA_POST: 'NTG_RDX_METH_SEND_PERSONAL_DATA_POST_REQUEST' = 'NTG_RDX_METH_SEND_PERSONAL_DATA_POST_REQUEST';
export const REDUX_MSG_REQUEST_RIGHT_COMMERCIAL_OFFER: 'NTG_RDX_METH_SEND_COMMERCIAL_OFFER_REQUEST' = 'NTG_RDX_METH_SEND_COMMERCIAL_OFFER_REQUEST';
export const REDUX_MSG_REQUEST_RIGHT_USER_RIGHTS: 'NTG_RDX_METH_SEND_USER_RIGHTS_REQUEST' = 'NTG_RDX_METH_SEND_USER_RIGHTS_REQUEST';
export const REDUX_MSG_REQUEST_SECTION_FEED: 'NTG_RDX_METH_SEND_V8_SECTION_FEED_REQUEST' = 'NTG_RDX_METH_SEND_V8_SECTION_FEED_REQUEST';

// VIDEOFUTUR

export const REDUX_MSG_REQUEST_BO_AUTHENTICATE: 'BO_RDX_METH_AUTHENTICATE' = 'BO_RDX_METH_AUTHENTICATE';
export const REDUX_MSG_REQUEST_BO_DISCOVERY: 'BO_RDX_METH_SEND_DISCOVERY_REQUEST' = 'BO_RDX_METH_SEND_DISCOVERY_REQUEST';
export const REDUX_MSG_REQUEST_BO_FAVORITE_ADD: 'BO_RDX_METH_SEND_ADD_FAVORITE_REQUEST' = 'BO_RDX_METH_SEND_ADD_FAVORITE_REQUEST';
export const REDUX_MSG_REQUEST_BO_FAVORITE_DELETE: 'BO_RDX_METH_SEND_DELETE_FAVORITE_REQUEST' = 'BO_RDX_METH_SEND_DELETE_FAVORITE_REQUEST';
export const REDUX_MSG_REQUEST_BO_FAVORITE_LIST: 'BO_RDX_METH_SEND_FAVORITE_LIST_REQUEST' = 'BO_RDX_METH_SEND_FAVORITE_LIST_REQUEST';
export const REDUX_MSG_REQUEST_BO_PERSONAL_DATA: 'BO_RDX_METH_SEND_PERSONAL_DATA_REQUEST' = 'BO_RDX_METH_SEND_PERSONAL_DATA_REQUEST';
export const REDUX_MSG_REQUEST_BO_PURCHASE: 'BO_RDX_METH_SEND_PURCHASE_REQUEST' = 'BO_RDX_METH_SEND_PURCHASE_REQUEST';
export const REDUX_MSG_REQUEST_BO_PURCHASE_LIST: 'BO_RDX_METH_SEND_PURCHASE_LIST_REQUEST' = 'BO_RDX_METH_SEND_PURCHASE_LIST_REQUEST';
export const REDUX_MSG_REQUEST_BO_PURCHASE_STATUS: 'BO_RDX_METH_SEND_PURCHASE_STATUS_REQUEST' = 'BO_RDX_METH_SEND_PURCHASE_STATUS_REQUEST';
export const REDUX_MSG_REQUEST_BO_STREAM_CREATE: 'BO_RDX_METH_SEND_CREATE_STREAM_REQUEST' = 'BO_RDX_METH_SEND_CREATE_STREAM_REQUEST';
export const REDUX_MSG_REQUEST_BO_STREAMS_GET: 'BO_RDX_METH_SEND_GET_STREAMS_REQUEST' = 'BO_RDX_METH_SEND_GET_STREAMS_REQUEST';
export const REDUX_MSG_REQUEST_BO_STREAM_START: 'BO_RDX_METH_SEND_STREAM_START_REQUEST' = 'BO_RDX_METH_SEND_STREAM_START_REQUEST';
export const REDUX_MSG_REQUEST_BO_STREAM_STOP: 'BO_RDX_METH_SEND_STREAM_STOP_REQUEST' = 'BO_RDX_METH_SEND_STREAM_STOP_REQUEST';
