/* @flow */

import { type AllSettledPromises, SettledPromiseFulfilled } from '../../../../helpers/jsHelpers/promise';
import {
  type NETGEM_API_V8_SCHEDULED_RECORDING,
  type NETGEM_API_V8_SCHEDULED_RECORDINGS_LIST_FULL_RESULT,
  type NETGEM_API_V8_SCHEDULED_RECORDINGS_LIST_RESULT,
  RecordingOutcome,
} from '../../../../libs/netgemLibrary/v8/types/Npvr';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import {
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_CREATE,
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_DELETE,
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_LIST,
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_LIST_FULL,
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_METADATA,
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_UPDATE,
} from '../../constants';
import type { SCHEDULED_RECORDING_CREATION_SETTINGS, SCHEDULED_RECORDING_UPDATE_SETTINGS } from '../../../../helpers/npvr/Types';
import { getScheduledRecordingsMap, getScheduledRecordingsMapV2 } from '../../../../libs/netgemLibrary/v8/helpers/Npvr';
import { sendV8RecordingsMetadataRequest, sendV8RecordingsRetryRequest } from './recordings';
import { updateScheduledRecordings, updateScheduledRecordingsFull } from '../../../npvr/actions';
import AccurateTimestamp from '../../../../helpers/dateTime/AccurateTimestamp';
import type { CombinedReducers } from '../../../reducers';
import { CustomNetworkError } from '../../../../libs/netgemLibrary/helpers/CustomNetworkError';
import type { Dispatch } from '../../../types/types';
import { HttpStatus } from '../../../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const getAllScheduledRecordingsV1: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      npvr: {
        eTagsCache: { npvrScheduledRecordingsList: initialETag },
        npvrScheduledRecordingsList: initialNpvrScheduledRecordingsList,
      },
    } = getState();

    // Get all scheduled recordings
    return dispatch(sendV8ScheduledRecordingListRequest(initialETag, signal))
      .then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { eTag, result } = data;
        const scheduledRecordings = (result.scheduledRecordings: NETGEM_API_V8_SCHEDULED_RECORDINGS_LIST_RESULT);

        // Get metadata for all returned scheduled recordings
        return dispatch(getMetadataForAllScheduledRecordings(scheduledRecordings, eTag, signal));
      })
      .catch((error: CustomNetworkError) => {
        if (error.getStatus() === HttpStatus.NotModified) {
          // Get metadata for all current scheduled recordings since the list has not changed
          const unchangedScheduledRecordings = Object.values(initialNpvrScheduledRecordingsList).map((sr) => {
            const { id } = ((sr: any): NETGEM_API_V8_SCHEDULED_RECORDING);
            return { id };
          });
          return dispatch(getMetadataForAllScheduledRecordings(unchangedScheduledRecordings, initialETag, signal));
        }

        // Error other than 304 (NOT MODIFIED)
        return Promise.reject(error);
      });
  };

const getAllScheduledRecordingsV2: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      npvr: {
        eTagsCache: { npvrScheduledRecordingsListFull: initialETag },
      },
    } = getState();

    // Get all scheduled recordings along with their metadata
    return dispatch(sendV8ScheduledRecordingListFullRequest(initialETag, signal))
      .then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { eTag, result } = data;
        const scheduledRecordings = getScheduledRecordingsMapV2((result.scheduledRecordings: NETGEM_API_V8_SCHEDULED_RECORDINGS_LIST_FULL_RESULT));
        dispatch(updateScheduledRecordingsFull(scheduledRecordings, eTag));

        return Promise.resolve();
      })
      .catch((error: CustomNetworkError) => {
        if (error.getStatus() === HttpStatus.NotModified) {
          // Nothing has changed (neither the scheduled recording list, nor their metadata
          return Promise.resolve();
        }

        // Error other than 304 (NOT MODIFIED)
        return Promise.reject(error);
      });
  };

const getAllScheduledRecordings: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      netgemApi: { npvrScheduledRecordingsListFullUrl },
    } = getState();

    if (npvrScheduledRecordingsListFullUrl) {
      // New endpoint: return list as well as all metadata
      return dispatch(getAllScheduledRecordingsV2(signal));
    }

    // Old endpoint: return list, then we need to get metadata for all scheduled recordings returned
    return dispatch(getAllScheduledRecordingsV1(signal));
  };

const getMetadataForAllScheduledRecordings: (
  scheduledRecordingList: NETGEM_API_V8_SCHEDULED_RECORDINGS_LIST_RESULT,
  npvrScheduledRecordingListETag: ?string,
  signal?: AbortSignal,
) => RequestResponseMethodDefinitionType =
  (scheduledRecordingList, npvrScheduledRecordingListETag, signal) =>
  (dispatch: Dispatch): Promise<any> => {
    const promises = scheduledRecordingList.map((sr) => dispatch(sendV8ScheduledRecordingMetadataRequest(sr.id, signal)));

    return Promise.allSettled(promises).then((results: AllSettledPromises) => {
      const filteredResults = results
        .filter((res) => res.status === SettledPromiseFulfilled)
        .map((res) => {
          const {
            // $FlowFixMe: value is never undefined since results have just been filtered
            value: { eTag, result },
          } = res;
          return {
            eTag,
            scheduledRecording: ((result: any): NETGEM_API_V8_SCHEDULED_RECORDING),
          };
        });
      const { eTags, scheduledRecordings } = getScheduledRecordingsMap(filteredResults);
      dispatch(
        updateScheduledRecordings(
          // Following are ETags for all scheduled recordings
          eTags,
          scheduledRecordings,
          // Following is ETag for the list of scheduled recordings
          npvrScheduledRecordingListETag,
        ),
      );
      return Promise.resolve();
    });
  };

const deleteScheduledRecording: (scheduledRecordingId: string, deleteChildren?: boolean, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (scheduledRecordingId, deleteChildren, signal) =>
  (dispatch: Dispatch): Promise<any> =>
    // Get ETag first
    dispatch(sendV8ScheduledRecordingMetadataRequest(scheduledRecordingId, signal)).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
      // Delete
      const { eTag } = response;
      if (eTag) {
        return dispatch(sendV8ScheduledRecordingDeleteRequest(scheduledRecordingId, deleteChildren === true, eTag, signal));
      }
      return Promise.resolve();
    });

const deleteScheduledRecordings: (scheduledRecordingIds: Array<string>, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (scheduledRecordingIds, signal) =>
  (dispatch: Dispatch): Promise<any> => {
    const promises = scheduledRecordingIds.map((scheduledRecordingId) => dispatch(deleteScheduledRecording(scheduledRecordingId, false, signal)));
    return Promise.all(promises);
  };

const stopSeriesScheduledRecording: (settings: SCHEDULED_RECORDING_UPDATE_SETTINGS, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (settings, signal) =>
  (dispatch: Dispatch): Promise<any> => {
    const { assetId, toUtc } = settings;

    // Get ETag first
    return dispatch(sendV8ScheduledRecordingMetadataRequest(assetId, signal)).then((matadataResponse: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { eTag } = matadataResponse;
      if (eTag) {
        // Stop series recording
        settings.fromUtc = null;
        settings.toUtc = toUtc || AccurateTimestamp.nowAsIsoString();

        return dispatch(sendV8ScheduledRecordingUpdateRequest(settings, eTag, signal));
      }
      return Promise.resolve();
    });
  };

const startSeriesScheduledRecording: (settings: SCHEDULED_RECORDING_UPDATE_SETTINGS, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (settings, signal) =>
  (dispatch: Dispatch): Promise<any> => {
    const { assetId } = settings;

    // Get ETag first
    return dispatch(sendV8ScheduledRecordingMetadataRequest(assetId, signal)).then((matadataResponse: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { eTag } = matadataResponse;
      if (eTag) {
        // Start series recording
        return dispatch(sendV8ScheduledRecordingUpdateRequest(settings, eTag, signal));
      }
      return Promise.resolve();
    });
  };

const deleteOrStopFutureScheduledRecordings: (singleScheduledRecordingIds: Array<string>, seriesScheduledRecordingIds: Array<string>, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (singleScheduledRecordingIds, seriesScheduledRecordingIds, signal) =>
  (dispatch: Dispatch): Promise<any> => {
    // Delete future single scheduled recordings
    const singlePromises = singleScheduledRecordingIds.map((id) => dispatch(deleteScheduledRecording(id, true, signal)));

    // Stop series scheduled recordings
    const now = AccurateTimestamp.nowAsIsoString();
    const seriesPromises = seriesScheduledRecordingIds.map((id) =>
      dispatch(
        stopSeriesScheduledRecording(
          {
            assetId: id,
            toUtc: now,
          },
          signal,
        ),
      ),
    );

    const promises = [...singlePromises, ...seriesPromises];

    return Promise.all(promises);
  };

const retryIfNeeded: (assetId: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (assetId, signal) =>
  (dispatch: Dispatch): Promise<any> =>
    dispatch(sendV8RecordingsMetadataRequest(assetId, signal)).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { eTag, result } = response;
      const {
        location: { recordOutcome },
      } = (result: NETGEM_API_V8_METADATA_SCHEDULE);
      if (recordOutcome === RecordingOutcome.Recorded || recordOutcome === RecordingOutcome.Rebroadcast || recordOutcome === RecordingOutcome.WillBeRecorded) {
        // Everything is fine: conflict should simply be ignored
        return Promise.resolve(assetId);
      } else if (eTag) {
        return dispatch(sendV8RecordingsRetryRequest(assetId, eTag, signal)).then(() => Promise.resolve(assetId));
      }

      // Conflict should be addressed but for some reason, no ETag was returned with the metadata
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_recording_etag', { assetId }));
    });

const getRecordId: (result: NETGEM_API_V8_SCHEDULED_RECORDING) => string | null = (result) => {
  const { records } = result;
  return records && records.length > 0 ? records[records.length - 1].id : null;
};

const sendV8ScheduledRecordingCreateRequest: (settings: SCHEDULED_RECORDING_CREATION_SETTINGS, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (settings, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrScheduledRecordingsCreateUrl },
      npvr: { endMargin, recFromBeginning, startMargin },
    } = state;

    if (!npvrScheduledRecordingsCreateUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrScheduledRecordingsCreateUrl' }));
    }

    const { authent, method } = npvrScheduledRecordingsCreateUrl;
    const { channelId, fromUtc, increasingEpisodes, recordsToKeep, scheduledRecordKind, target } = settings;

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_CREATE, {
      authent,
      channelId,
      endMargin,
      fromBeginning: recFromBeginning ?? false,
      fromUtc,
      increasingEpisodes,
      method,
      recordsToKeep,
      scheduledRecordKind,
      signal,
      startMargin,
      target,
      uri: generateApiUrl(npvrScheduledRecordingsCreateUrl, {}, state),
    })
      .then((response: NETGEM_API_V8_REQUEST_RESPONSE) => (response.result: NETGEM_API_V8_SCHEDULED_RECORDING))
      .catch((error: CustomNetworkError) => {
        const {
          networkError: { result, status },
        } = error;
        if (result) {
          const { result: innerResult } = result;
          if (status === HttpStatus.Conflict) {
            // Error 409 (CONFLICT)
            const id = getRecordId(innerResult);
            if (id) {
              return dispatch(retryIfNeeded(id, signal)).then(() => innerResult);
            }

            // No recording Id: conflict with an existing scheduled recording that is not associated with any recording (rejected below)
          }
        }

        // Error other than 409 (CONFLICT)
        return Promise.reject(error);
      });
  };

const sendV8ScheduledRecordingDeleteRequest: (assetId: string, deleteChildren: boolean, eTag: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (assetId, deleteChildren, eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrScheduledRecordingsDeleteUrl },
    } = state;

    if (!npvrScheduledRecordingsDeleteUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrScheduledRecordingsDeleteUrl' }));
    }

    const { authent, method } = npvrScheduledRecordingsDeleteUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_DELETE, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(
        npvrScheduledRecordingsDeleteUrl,
        {
          assetId,
          deleteChildren,
        },
        state,
      ),
    });
  };

const sendV8ScheduledRecordingListRequest: (eTag: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrScheduledRecordingsListUrl },
    } = state;

    if (!npvrScheduledRecordingsListUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrScheduledRecordingsListUrl' }));
    }

    const { authent, method } = npvrScheduledRecordingsListUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_LIST, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(npvrScheduledRecordingsListUrl, {}, state),
    });
  };

const sendV8ScheduledRecordingListFullRequest: (eTag: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrScheduledRecordingsListFullUrl },
    } = state;

    if (!npvrScheduledRecordingsListFullUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrScheduledRecordingsListFullUrl' }));
    }

    const { authent, method } = npvrScheduledRecordingsListFullUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_LIST_FULL, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(npvrScheduledRecordingsListFullUrl, {}, state),
    });
  };

const sendV8ScheduledRecordingMetadataRequest: (assetId: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (assetId, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrScheduledRecordingsMetadataUrl },
      npvr,
      npvr: { eTagsCache },
    } = state;

    if (!npvrScheduledRecordingsMetadataUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrScheduledRecordingsMetadataUrl' }));
    }

    const { authent, method } = npvrScheduledRecordingsMetadataUrl;
    const { [assetId]: eTag } = eTagsCache;

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_METADATA, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(npvrScheduledRecordingsMetadataUrl, { assetId }, state),
    }).catch((error: CustomNetworkError) => {
      const {
        networkError: { status },
      } = error;
      if (status === HttpStatus.NotModified) {
        // Get existing scheduled recording since it has not changed
        const { npvrScheduledRecordingsList } = npvr;
        const result = Array.from(Object.values(npvrScheduledRecordingsList)).find((sr) => ((sr: any): NETGEM_API_V8_SCHEDULED_RECORDING).id === assetId);
        if (result) {
          const response: NETGEM_API_V8_REQUEST_RESPONSE = {
            cacheMaxAge: null,
            eTag: eTag ?? null,
            result,
            status,
          };
          return Promise.resolve(response);
        }

        // No recording Id: conflict with an existing scheduled recording that is not associated with any recording (rejected below)
      }

      // Error other than 304 (NOT MODIFIED)
      return Promise.reject(error);
    });
  };

const sendV8ScheduledRecordingUpdateRequest: (settings: SCHEDULED_RECORDING_UPDATE_SETTINGS, eTag: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (settings, eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { npvrScheduledRecordingsUpdateUrl },
    } = state;

    if (!npvrScheduledRecordingsUpdateUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'npvrScheduledRecordingsUpdateUrl' }));
    }

    const { authent, method } = npvrScheduledRecordingsUpdateUrl;
    const { assetId, fromUtc, increasingEpisodes, recordsToKeep, toUtc } = settings;

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_UPDATE, {
      assetId,
      authent,
      eTag,
      fromUtc,
      increasingEpisodes,
      method,
      recordsToKeep,
      signal,
      toUtc,
      uri: generateApiUrl(npvrScheduledRecordingsUpdateUrl, {}, state),
    });
  };

export {
  deleteOrStopFutureScheduledRecordings,
  deleteScheduledRecording,
  deleteScheduledRecordings,
  getAllScheduledRecordings,
  getRecordId,
  sendV8ScheduledRecordingCreateRequest,
  startSeriesScheduledRecording,
  stopSeriesScheduledRecording,
};
